import React from "react";
import BaseComponent from "components/common/BaseComponent";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import { Link } from "react-router-dom";
import * as ApiConstants from "constants/ApiConstants";

class PolicyItem extends BaseComponent {
  // ポリシー取得
  getPolicyInfo = async params => {
    this.runProgress();
    const query = {
      policy_id: params[0]
    };
    const response = await this.executeApi(
      ApiConstants.GET_POLICY,
      null,
      query,
      null
    );
    this.stopProgress();
    const policy = response.success ? response.data : {};
    policy.id = params[0];
    this.props.selectPolicy(policy);
    this.props.setPolicyRoute(params[1]);
  };

  render() {
    const { path, id, name, selectedPolicy } = this.props;
    return (
      <div>
        <div
          className={
            name === selectedPolicy.policy_name
              ? "lv-instance active"
              : "lv-instance"
          }
          onClick={this.getPolicyInfo.bind(this, [id, path])}
        >
          <div className="lv-policy-item">
            <Link to={path} className="policy-link">
              <div className="row no-gutters">
                <div className="col p-0">
                  <span className="lv-no-policy text-wrap break-text">{name}</span>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedPolicy: state.selectedPolicy
  };
};

const mapDispatchToProbs = dispatch => {
  return {
    selectPolicy: policy => {
      dispatch(actions.selectPolicy(policy));
    },
    setPolicyRoute: route => {
      dispatch(actions.setPolicyRoute(route));
    },
    setPolicyUser: user => {
      dispatch(actions.setPolicyUser(user));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(PolicyItem);
