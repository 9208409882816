import { Component } from "react";
import BaseApi from "./BaseApi";
import * as LocalStorage from "constants/LocalStorage";
import * as CognitoUserUtils from "lib/CognitoUserUtils";
import * as LocalStorageUtils from "lib/LocalStorageUtils";
import * as MsgConstants from "constants/MessageConstants";
import * as StringFormat from "lib/StringFormat";
import toastr from "toastr";

/**
 * 親コンポーネント
 */
class BaseComponent extends Component {
  // constructor() {
  //     super(...arguments);
  // }

  /**
   * ダイアログ表示
   * @param {*} info
   */
  showDialog(info) {
    window.Alert.showAlert(info);
  }

  /**
   * プログレス開始
   */
  runProgress() {
    if (!this.refs.progress) {
      return;
    }
    this.refs.progress.run();
  }

  /**
   * プログレス停止
   */
  stopProgress() {
    if (!this.refs.progress) {
      return;
    }
    this.refs.progress.stop();
  }

  /**
   * ログインユーザ名取得
   */
  getUserName() {
    const info = localStorage.getItem(LocalStorage.LOGIN_USER);
    const userInfo = info ? JSON.parse(info) : {};
    return userInfo.name;
  }

  /**
   * ユーザ名取得
   */
  getUserNameId = userId => {
    const userInfo = LocalStorageUtils.getTargetUser(userId);
    return (
      LocalStorageUtils.getAttribute(userInfo, "family_name") +
      " " +
      LocalStorageUtils.getAttribute(userInfo, "name")
    );
  };

  /**
   * 差分スタイル
   * @param {*} d1
   * @param {*} d2
   */
  getDiffStyle(d1, d2) {
    return { color: d1 === d2 ? "" : "#FFB74D" };
  }

  /**
   * ダイアログ表示中
   */
  dialogShowing() {
    if (!document.getElementById("confirmModal")) {
      return false;
    }
    return document.getElementById("confirmModal").classList.contains("show");
  }

  /**
   * トークン更新
   */
  refreshToken() {
    CognitoUserUtils.refreshToken((err, data) => {
      if (!err)
        LocalStorageUtils.updateToken(
          data.AuthenticationResult.IdToken,
          data.AuthenticationResult.AccessToken
        );
    });
  }

  /**
   * ListView開閉に伴うdiffのstyle設定
   */
  setDiffStyle(isLvExpand) {
    return isLvExpand
      ? "text-nowrap col-6 syncscroll"
      : "text-nowrap col-6 syncscroll lv-closed";
  }

  /**
   * ListView開閉に伴うdetailのstyle設定
   */
  setDetailStyle(isLvExpand) {
    return isLvExpand ? "flex-nowrap ml-0" : "flex-nowrap ml-0 lv-closed";
  }

  /** テーブル情報更新の入力チェック
   * @param {*} dataHoldingPeriod
   * @param {*} dataHoldingUnit
   * @param {*} maxRecordNo
   * @param {*} createTime
   * @param {*} createUser_f
   * @param {*} createUser_l
   * @param {*} updateTime
   * @param {*} updateUser_f
   * @param {*} updateUser_l
   */
  tableEditValidator(
    dataHoldingPeriod,
    dataHoldingUnit,
    maxRecordNo,
    createTime,
    createUser_f,
    createUser_l,
    updateTime,
    updateUser_f,
    updateUser_l
  ) {
    const errorMessages = [];
    // データ保持期間
    if (!this.multipleInputCheck([dataHoldingPeriod, dataHoldingUnit])) {
      errorMessages.push(
        StringFormat.format(MsgConstants.MULTI_INPUT_CHECK_ERROR, [
          "データ保持期間",
          ["期間", "単位"].join("、")
        ])
      );
    }
    if (this.isNumber(dataHoldingPeriod)) {
      errorMessages.push(
        StringFormat.format(MsgConstants.NUMBER_INPUT_CHECK_ERROR, [
          "データ保持期間"
        ])
      );
    }
    // 最大レコード数
    if (this.isNumber(maxRecordNo)) {
      errorMessages.push(
        StringFormat.format(MsgConstants.NUMBER_INPUT_CHECK_ERROR, [
          "最大レコード数"
        ])
      );
    }
    // 作成
    if (!this.multipleInputCheck([createTime, createUser_f, createUser_l])) {
      errorMessages.push(
        StringFormat.format(MsgConstants.MULTI_INPUT_CHECK_ERROR, [
          "作成",
          ["作成日", "作成者名"].join("、")
        ])
      );
    }
    // 最終更新
    if (!this.multipleInputCheck([updateTime, updateUser_f, updateUser_l])) {
      errorMessages.push(
        StringFormat.format(MsgConstants.MULTI_INPUT_CHECK_ERROR, [
          "最終更新",
          ["更新日", "更新者名"].join("、")
        ])
      );
    }
    // 作成日 <= 更新日 <= 当日
    if (!this.dateCheck(createTime, updateTime)) {
      errorMessages.push(MsgConstants.DATE_CHECK_ERROR);
    }
    return errorMessages;
  }

  /**
   * 全角英数字を半角に変換する
   * @param {*} input
   */
  toHankaku(input) {
    if (!input) return input;
    return input.replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    });
  }

  /**
   * 複数項目入力チェック
   * @param {*} inputs
   */
  multipleInputCheck(inputs) {
    if (!inputs.filter(input => input).length) return true;
    return inputs.length === inputs.filter(d => d).length;
  }

  /**
   * 数値チェック
   * @param {*} input
   */
  isNumber(input) {
    return input && isNaN(input);
  }

  /**
   * 作成日 <= 更新日 <= 本日日付であるかをチェックする
   * @param {*} createDate
   * @param {*} updateDate
   */
  dateCheck(createDate, updateDate) {
    if (!createDate && !updateDate) return true;
    return (
      new Date(createDate).getTime() <= new Date(updateDate).getTime() &&
      new Date(updateDate).getTime() <= new Date().getTime()
    );
  }

  /**
   * API実行
   * @param {*} api
   * @param {*} path
   * @param {*} query
   * @param {*} data
   */
  async executeApi(api, path, query, data) {
    const response = await BaseApi(api, path, query, data);
    if (response.success) {
      this.refreshToken();
      return response;
    }
    if (response.message === null) {
      response.message = MsgConstants.ERR_UNEXPECTED;
    }
    const reflash = response.status === 401 || response.status === 403;
    this.showDialog({ message: response.message, reflash: reflash });
    return response;
  }

  /**
   * LocalStorageのテーブル情報、カラム情報、索引情報、制約情報をクリアする
   * @param {*} keys
   */
  removeItems(keys) {
    localStorage.removeItem(keys.table);
    localStorage.removeItem(keys.column);
    localStorage.removeItem(keys.index);
    localStorage.removeItem(keys.constraint);
  }

  async getUsers() {
    let users = [];
    let paginationToken = "";
    let reget = true;
    const get = () =>
      new Promise((resolve, reject) => {
        CognitoUserUtils.getUsers((err, data) => {
          if (err) {
            this.showDialog({
              message: StringFormat.format(MsgConstants.USER_EDIT_ERROR, [
                "取得"
              ])
            });
            users = [];
            paginationToken = "";
          } else {
            users = users.concat(data.Users);
            paginationToken = data.PaginationToken;
          }
          resolve();
        }, paginationToken);
      });
    while (reget) {
      await get();
      if (!paginationToken) reget = false;
    }
    return users;
  }

  dateConversion(str) {
    return (
      str.substring(0, 4) +
      "/" +
      str.substring(4, 6) +
      "/" +
      str.substring(6, 8) +
      " " +
      str.substring(8, 10) +
      ":" +
      str.substring(10, 12)
    );
  }

  showToast(message) {
    toastr.success(message, "", { timeOut: 2500 });
  }
}

export default BaseComponent;
