import React from "react";
import BaseComponent from "components/common/BaseComponent";
import * as actions from "actions/Actions";
import { connect } from "react-redux";
import * as LocalStorage from "constants/LocalStorage";
import { LISTVIEW_TYPE } from "constants/LocalStorage";
import * as ListViewTypes from "constants/ListViewTypes";

class SettingGeneral extends BaseComponent {
  componentWillMount() {
    this.setState({
      currentTheme:
        localStorage.getItem(LocalStorage.CURRENT_THEME) === "light"
          ? "light"
          : "dark"
    });
  }

  onDarkMode = () => {
    this.setState({
      currentTheme: "dark"
    });
    this.props.changeToDarkMode();
    localStorage.setItem(LocalStorage.CURRENT_THEME, "dark");
  };

  onLightMode = () => {
    this.setState({
      currentTheme: "light"
    });
    this.props.changeToLightMode();
    localStorage.setItem(LocalStorage.CURRENT_THEME, "light");
  };

  handleClearCache = () => {
    const login_user = localStorage.getItem(LocalStorage.LOGIN_USER);
    const login_state = localStorage.getItem(LocalStorage.LOGIN_STATE);

    localStorage.clear();

    localStorage.setItem(LocalStorage.LOGIN_USER, login_user);
    localStorage.setItem(LocalStorage.LOGIN_STATE, login_state);
    localStorage.setItem(
      LocalStorage.CURRENT_THEME,
      this.state.currentTheme
    );
    localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.SETTING);
  };
  render() {
    return (
      <div className="container-fluid m-0 p-0 w-100">
        <div id="detail" className="detail-setting m-0 flex-nowrap">
          <div className="d-flex mb-3">
            <span className="align-self-center adm-detail-main-title">
              全般
            </span>
          </div>
          <div className="pl-3 mb-3">
            <div>
              <div className="row no-gutters mb-3">
                <div className="col-2 d-flex align-items-center">
                  <span className="detail-setting-label">テーマ</span>
                </div>
                <div className="col">
                  <div id="mode" className="btn-group" role="group">
                    <button
                      type="button"
                      className={
                        this.state.currentTheme === "dark"
                          ? "btn theme-btn-gray rounded-1 w-50 active"
                          : "btn theme-btn-gray rounded-1 w-50"
                      }
                      onClick={this.onDarkMode}
                    >
                      ダーク
                    </button>
                    <button
                      type="button"
                      className={
                        this.state.currentTheme === "light"
                          ? "btn theme-btn-gray rounded-1 w-50 active"
                          : "btn theme-btn-gray rounded-1 w-50"
                      }
                      onClick={this.onLightMode}
                    >
                      ライト
                    </button>
                  </div>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-2 d-flex align-items-center ">
                  <span className="detail-setting-label">キャッシュ</span>
                </div>
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-action rounded"
                    onClick={this.handleClearCache}
                  >
                    クリアする
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SettingGeneral.propTypes = {};

const mapDispatchToProbs = dispatch => {
  return {
    changeToLightMode: () => {
      dispatch(actions.toLightMode());
    },
    changeToDarkMode: () => {
      dispatch(actions.toDarkMode());
    }
  };
};

export default connect(
  null,
  mapDispatchToProbs
)(SettingGeneral);
