
// USER_TYPE
export const REFERENCE_USER_CODE = "0";
export const UPDATE_USER_CODE = "1";
export const ADMIN_USER_CODE = "2";
export const DISABLE_USER_CODE = "3";
export const USER_TYPE = [
    {
      CODE: REFERENCE_USER_CODE,
      LABEL: "参照ユーザ"
    },
    {
      CODE: UPDATE_USER_CODE,
      LABEL: "更新ユーザ"
    },
    {
      CODE: ADMIN_USER_CODE,
      LABEL: "管理者"
    },
    {
      CODE: DISABLE_USER_CODE,
      LABEL: "停止中"
    },
];

// custom attribute
export const USER_STATUS = "custom:status";
export const USER_STATUS_RM = "custom:status_rm";
export const LOGIN_DATE = "custom:login_date";
export const LOGIN_DATE_RM = "custom:login_date_rm";

// Cognito API Exception
export const USER_NAME_EXISTS_EXCEPTION = "UsernameExistsException";
export const USER_NOT_FOUND_EXCEPTION = "UserNotFoundException";
export const PASSWORD_RESET_REQUIRED_EXCEPTION = "PasswordResetRequiredException";
export const INVALID_PASSWORD_EXCEPTION = "InvalidPasswordException";
export const NOT_AUTHORIZED_EXCEPTION = "NotAuthorizedException";

// Cognito API Error Message
export const TOKEN_EXPIRED = "Access Token has expired";
