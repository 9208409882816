import React from "react";
import BaseComponent from "components/common/BaseComponent";
import ActivityBar from "components/activity-bar/ActivityBar";
import TopBar from "components/top-bar/TopBar";
import ListView from "components/listview/ListView";
import AlertMessage from "components/common/AlertMessage";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import * as Routes from "routes";
import { hist as history } from "App";
import * as actions from "actions/Actions";
import * as LocalStorage from "constants/LocalStorage";
import * as RoutePath from "constants/RoutePath";
import NoMatch from "components/common/NoMatch";
import SelectModal from "components/common/SelectModal";

class HomePage extends BaseComponent {
  setContentStyle() {
    let classStyle = "";
    if (this.props.lvExpandState === true) {
      classStyle = "detail-content";
    } else {
      classStyle = "detail-content lv-closed";
    }
    return classStyle;
  }

  componentWillReceiveProps(nextProps) {
    this.setTheme();
  }

  componentWillMount() {
    document.addEventListener("keydown", this.handleKeyDown.bind(this));
    this.setTheme();
    if (
      window.location.pathname === RoutePath.ROOT_PATH ||
      window.location.pathname === RoutePath.LOGIN_PATH
    ) {
      history.push(RoutePath.POLICY_PATH);
    }
  }

  setTheme() {
    var el = document.body;
    if (localStorage.getItem(LocalStorage.CURRENT_THEME) === "light") {
      el.classList.add("light");
    } else {
      el.classList.remove("light");
    }
  }

  mapDetailRoutes = detailRoutes => {
    const routes = [];
    detailRoutes.forEach(route => {
      routes.push(
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.main}
        />
      );
    });
    routes.push(<Route key={"NoMatch"} component={NoMatch} />);
    return <Switch>{routes}</Switch>;
  };

  handleKeyDown(e) {
    if (e.keyCode === 27) {
      // Esc
      let searchInput = document.querySelector("[class~=lv-filter]");
      if (searchInput && searchInput.id !== "code-cate-input") {
        searchInput.value = "";
        searchInput.blur();
      }
      return;
    }

    if (
      this.dialogShowing() ||
      document.activeElement.tagName === "INPUT" ||
      document.activeElement.tagName === "TEXTAREA"
    ) {
      return;
    }

    if (!e.shiftKey) {
      if (e.keyCode === 191) {
        // "/"
        let searchInput = document.querySelector("[class~=lv-filter]");
        if (searchInput) {
          searchInput.focus();
          e.preventDefault();
        }
      }
    }
  }

  render() {
    return (
        <div>
          <div className="wrapper">
            {/* top-bar */}
            <TopBar />
            <div className="container-fluid">
              {/* activity-bar */}
              <ActivityBar />
              <div>
                <div className="content">
                  {/* list-view */}
                  {this.props.lvExpandState === true ? <ListView /> : null}
                  {/* detail-content */}
                  <div className={this.setContentStyle()}>
                    {this.mapDetailRoutes(Routes.detail_routes)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* alert Message */}
          <AlertMessage />
          <SelectModal />
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    lvExpandState: state.lvExpandState,
    isPwd: state.setPwdState,
    currentTheme: state.currentTheme,
    dbRoute: state.dbRoute,
    adminRoute: state.adminRoute,
    settingRoute: state.settingRoute,
    historyRoute: state.historyRoute,
    searchRoute: state.searchRoute,
    helpRoute: state.helpRoute,
    topicRoute: state.topicRoute,
    codeRoute: state.codeRoute,
    groupRoute: state.groupRoute
  };
};

const mapDispatchToProbs = (dispatch, props) => {
  return {
    showPolicy: () => {
      dispatch(actions.showPolicy());
    },
    showGroup: () => {
      dispatch(actions.showGroup());
    },
    showUser: () => {
      dispatch(actions.showUser());
    },
    showSetting: () => {
      dispatch(actions.showSetting());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(HomePage);
