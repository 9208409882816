import React from "react";
import BaseComponent from "components/common/BaseComponent";
import CustomCheckbox from "components/common/CustomCheckbox";
import { connect } from "react-redux";
import * as actions from "actions/Actions";

class CodeItem extends BaseComponent {
    handleSelect = e => {
        const { name } = e.target;
        const { codeRes, company } = this.props;
        const codeFiltered = codeRes.filter(code => code.Resource === "orn:onigiri:db:code/" + company.company_id);
        let readCheck = false;
        let updateCheck = false;
        let createCheck = false;
        let deleteCheck = false;
        if (codeFiltered.length) {
            const actions = codeFiltered[0].Action;
            readCheck = actions.includes("read");
            updateCheck = actions.includes("update");
            createCheck = actions.includes("create");
            deleteCheck = actions.includes("delete");
        }
        const resource = "orn:onigiri:db:code/" + this.props.company.company_id;
        const setCodeRes = (check, codeRes, resource, action) => {
            if (check) {
                // ON -> OFF
                // 削除
                const saveCodeRes = [];
                codeRes.forEach(code => {
                    if (code.Resource !== resource) saveCodeRes.push(code);
                });
                this.props.setCodeRes(saveCodeRes);
            } else {
                // OFF -> ON
                const filtered = codeRes.filter(code => code.Resource === resource);
                if (filtered.length) {
                    // 上書き
                    const saveCodeRes = codeRes.map(code => {
                        if (code.Resource === resource) code.Action = action;
                        return code;
                    });
                    this.props.setCodeRes(saveCodeRes);
                } else {
                    // 追加
                    codeRes.push({
                        Action: action,
                        Resource: resource,
                    })
                }
                this.props.setCodeRes(codeRes);
            }
        }
        switch (name) {
            case "read":
                setCodeRes(readCheck, codeRes, resource, ["read"]);
                break;
            case "update":
                setCodeRes(updateCheck, codeRes, resource, ["read", "update"]);
                break;
            case "create":
                setCodeRes(createCheck, codeRes, resource, ["read", "update", "create"]);
                break;
            case "delete":
                setCodeRes(deleteCheck, codeRes, resource, ["read", "update", "create", "delete"]);
                break;
            default:
                break;
        }
        this.setState({}); // renderさせるため（stateが使えない状況での苦肉の策）
    }

    render() {
        const { codeRes, company } = this.props;
        const codeFiltered = codeRes.filter(code => code.Resource === "orn:onigiri:db:code/" + company.company_id);
        let readCheck = false;
        let updateCheck = false;
        let createCheck = false;
        let deleteCheck = false;
        let readDisable = false;
        let updateDisable = false;
        let createDisable = false;
        if (codeFiltered.length) {
            const actions = codeFiltered[0].Action;
            readCheck = actions.includes("read");
            updateCheck = actions.includes("update");
            createCheck = actions.includes("create");
            deleteCheck = actions.includes("delete");
            readDisable = actions.includes("update") || actions.includes("create") || actions.includes("delete");
            updateDisable = actions.includes("create") || actions.includes("delete");
            createDisable = actions.includes("delete");
        }

        return (
            <div className="row mdl-grid mdl-list-policy-dbcode">
                <div className="database-link">
                    <div className="row no-gutters">
                        <span>{this.props.company.company_name}</span>
                    </div>
                </div>
                {/* 参照 */}
                <div className="pl-1">
                    <CustomCheckbox
                        name="read"
                        checked={readCheck}
                        disabled={readDisable}
                        onChange={this.handleSelect}
                    />
                </div>
                {/* 更新 */}
                <div className="pl-1">
                    <CustomCheckbox
                        name="update"
                        checked={updateCheck}
                        disabled={updateDisable}
                        onChange={this.handleSelect}
                    />
                </div>
                {/* 作成 */}
                <div className="pl-1">
                    <CustomCheckbox
                        name="create"
                        checked={createCheck}
                        disabled={createDisable}
                        onChange={this.handleSelect}
                    />
                </div>
                {/* 削除 */}
                <div className="pl-1">
                    <CustomCheckbox
                        name="delete"
                        checked={deleteCheck}
                        onChange={this.handleSelect}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        codeRes: state.dbCodeRes,
    };
};

const mapDispatchToProbs = dispatch => {
    return {
        setCodeRes: code => {
            dispatch(actions.setCodeRes(code));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProbs)(CodeItem);