import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    minWidth: 120,
    margin: "0px"
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  select: {
    borderRadius: "3px",
    fontSize: "12px",
    color: "#000000",
    background: "#fafafa",
    paddingLeft: "7px"
  },
  icon: {
    fill: "#8f8f8f"
  },
  menuItem: {
    fontSize: "12px",
    lineHeight: undefined
  }
});

class DropDownService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
      data: []
    };
  }

  handleChange = event => {
    this.setState({ selected: event.target.value });
    this.props.onChange(event.target.value);
  };

  render() {
    const { classes, data, selected } = this.props;
    return (
      <form className={classes.root}>
        <FormControl
          className={classes.formControl}
          disabled={data.length === 0}
        >
          <Select
            value={selected}
            onChange={this.handleChange}
            className={classes.select}
            inputProps={{
              classes: {
                icon: classes.icon
              }
            }}
            IconComponent={props => (
              <ArrowDropUp className={`material-icons ${props.className}`} />
            )}
          >
            {data.map((item, index) => (
              <MenuItem
                key={index}
                className={classes.menuItem}
                code={item.code}
                value={item.name}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>
    );
  }
}

export default withStyles(styles)(DropDownService);
