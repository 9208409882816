import * as ActionTypes from "constants/ActionTypes";
import * as ListViewTypes from "constants/ListViewTypes";

var initState = "policy";

var listViewType = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_POLICY:
      state = ListViewTypes.POLICY;
      break;
    case ActionTypes.SHOW_GROUP:
      state = ListViewTypes.GROUP;
      break;
    case ActionTypes.SHOW_USER:
      state = ListViewTypes.USER;
      break;
    case ActionTypes.SHOW_SETTING:
      state = ListViewTypes.SETTING;
      break;
    default:
      break;
  }
  return state;
};

export default listViewType;
