import * as types from "constants/ActionTypes";
import { LISTVIEW_TYPE } from "constants/LocalStorage";
import * as ListViewTypes from "constants/ListViewTypes";

// activity bar
export const showPolicy = () => {
  localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.POLICY);
  return {
    type: types.SHOW_POLICY
  };
};
export const showGroup = () => {
  localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.GROUP);
  return {
    type: types.SHOW_GROUP
  };
};
export const showUser = () => {
  localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.USER);
  return {
    type: types.SHOW_USER
  };
};
export const showSetting = () => {
  localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.SETTING);
  return {
    type: types.SHOW_SETTING
  };
};
export const setPolicyRoute = route => {
  return {
    type: types.POLICY_ROUTE,
    route: route
  };
};
export const setGroupRoute = route => {
  return {
    type: types.GROUP_ROUTE,
    route: route
  };
};
export const setUserRoute = route => {
  return {
    type: types.USER_ROUTE,
    route: route
  };
};
export const setSettingRoute = route => {
  return {
    type: types.SETTING_ROUTE,
    route: route
  };
};

//top bar
export const toogleListView = () => {
  return {
    type: types.TOOGLE_LISTVIEW
  };
};

export const viewUser = () => {
  return {
    type: types.VIEW_USER
  };
};

//login
export const login = () => {
  return {
    type: types.LOGIN
  };
};

export const showExpireMsg = isShow => {
  return {
    type: types.SHOW_LOGIN_EXPIRE,
    isShowExp: isShow
  };
};

//setting
export const toLightMode = () => {
  return {
    type: types.LIGHT_MODE
  };
};

export const toDarkMode = () => {
  return {
    type: types.DARK_MODE
  };
};

//user
export const selectUser = user => {
  return {
    type: types.SELECT_USER,
    user: user
  };
};

export const setUserList = list => {
  return {
    type: types.SET_USER_LIST,
    list: list
  };
};

export const setUserGroup = group => {
  return {
    type: types.SET_USER_GROUP,
    group: group
  };
};

export const setUserPolicy = policy => {
  return {
    type: types.SET_USER_POLICY,
    policy: policy
  };
};

// group
export const setGroupList = list => {
  return {
    type: types.SET_GROUP_LIST,
    list: list
  };
};

export const selectGroup = group => {
  return {
    type: types.SELECT_GROUP,
    group: group
  };
};

export const editGroup = action => {
  return {
    type: types.EDIT_GROUP,
    editAction: action
  };
};

export const setGroupUser = users => {
  return {
    type: types.SET_GROUP_USER,
    users: users
  };
};

export const setGroupPolicy = policy => {
  return {
    type: types.SET_GROUP_POLICY,
    policy: policy
  };
};

// modal
export const openModal = (modal, data) => {
  return {
    type: types.OPEN_MODAL,
    modal: modal,
    initData: data
  };
};

export const closeModal = () => {
  return {
    type: types.CLOSE_MODAL
  };
};

// policy
export const setPolicyList = list => {
  return {
    type: types.SET_POLICY_LIST,
    list: list
  };
};

export const selectPolicy = policy => {
  return {
    type: types.SELECT_POLICY,
    policy: policy
  };
};

export const setPolicyUser = users => {
  return {
    type: types.SET_POLICY_USER,
    users: users
  };
};

// onigiri db
export const setDbJson = json => {
  return {
    type: types.SET_DB_JSON,
    json: json
  };
};

export const isJsonError = isError => {
  return {
    type: types.IS_JSON_ERROR,
    isError: isError
  };
};


export const setCodeNodes = nodes => {
  return {
    type: types.SET_DB_CODE,
    nodes: nodes
  };
};
export const selectCodeNode = node => {
  return {
    type: types.SELECT_DB_CODE,
    selectedNode: node
  };
};
export const storeCodeStruture = nodes => {
  return {
    type: types.SET_CODE_NODES,
    codeNodes: nodes
  };
};
export const isCreateCode = isCreate => {
  return {
    type: types.CAN_CREATE_CODE,
    isCreate: isCreate
  };
};
export const setCodeOpenNodes = nodes => {
  return {
    type: types.SET_CODE_OPEN_NODES,
    nodes: nodes
  };
};

export const setDbNodes = nodes => {
  return {
    type: types.SET_DB_META,
    nodes: nodes
  };
};
export const setDbOpenNodes = nodes => {
  return {
    type: types.SET_DB_OPEN_NODES,
    nodes: nodes
  };
};
export const selectDbNode = selectedNode => {
  return {
    type: types.SELECT_DB_NODE,
    selectedNode: selectedNode
  };
};

export const setDbGroupList = list => {
  return {
    type: types.SET_DBGROUP_LIST,
    list: list
  };
};

export const storeDbStructure = nodes => {
  return {
    type: types.STORE_DB_STRUCTURE,
    nodes: nodes
  };
};

export const setCompanyRes = company => {
  return {
    type: types.SET_COMPANY_RES,
    company: company
  };
};

export const setTopicRes = topic => {
  return {
    type: types.SET_TOPIC_RES,
    topic: topic
  };
};

export const setGroupRes = group => {
  return {
    type: types.SET_GROUP_RES,
    group: group
  };
};

export const setCodeRes = code => {
  return {
    type: types.SET_CODE_RES,
    code: code
  }
}

export const setTagRes = tag => {
  return {
    type: types.SET_TAG_RES,
    tag: tag
  }
}

export const setSystemRes = system => {
  return {
    type: types.SET_SYSTEM_RES,
    system: system
  }
}