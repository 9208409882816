import React from "react";
import FaceIcon from "images/ic_face.svg";
import BaseComponent from "components/common/BaseComponent";
import ButtonIcon from "components/common/ButtonIcon";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import * as useractions from "actions/LoginActions";
import ReactModal from "react-modal";
import { getLoginUserName } from "lib/LocalStorageUtils";
import { hist as history } from "App";
import * as MessageConstants from "constants/MessageConstants";
import { CHANGE_PASSWORD_PATH } from "constants/RoutePath";
import * as LocalStorage from "constants/LocalStorage";
import logo from "images/logo.svg";
import * as RoutePath from "constants/RoutePath";

ReactModal.setAppElement("#root");

class TopBar extends BaseComponent {
  constructor() {
    super();
    this.state = {
      showModal: false
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.logout = this.logout.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  changePassword() {
    history.push(CHANGE_PASSWORD_PATH);
    this.handleCloseModal();
  }

  logout() {
    const currentTheme = localStorage.getItem(LocalStorage.CURRENT_THEME);
    this.showDialog({
      message: MessageConstants.LOGOUT_CONFIRM,
      confirm: true,
      positive: () => {
        localStorage.clear();
        this.props.setPolicyRoute(RoutePath.POLICY_PATH);
        this.props.setGroupRoute(RoutePath.GROUP_PATH);
        this.props.setUserRoute(RoutePath.USER_PATH);
        this.props.setSettingRoute(RoutePath.SETTING_PATH);
        this.props.selectGroup({});
        this.props.selectUser({});
        this.props.selectPolicy({});
        this.props.logout();
        localStorage.setItem(LocalStorage.CURRENT_THEME, currentTheme);
      }
    });
  }

  render() {
    return (
      <div className="row header">
        <div className="col">
          <img className="logo-img" src={logo} alt="" />
        </div>
        <div className="col float-right p-0">
          <nav className="navbar navbar-expand p-0">
            <div className="navbar-collapse collapse w-100">
              <ul className="nav navbar-nav ml-auto">
                <li className="nav-item pt-2 pr-2">
                  <ButtonIcon
                    icon={FaceIcon}
                    isActive={this.props.userState}
                    onClick={this.handleOpenModal}
                  />
                  <ReactModal
                    isOpen={this.state.showModal}
                    onRequestClose={this.handleCloseModal}
                    className="user-menu-modal"
                    overlayClassName="user-menu-overlay"
                  >
                    <div
                      onClick={this.handleCloseModal}
                      className="modal-close"
                    >
                      ×
                    </div>
                    <div className="mb-3 user-menu-username">
                      {getLoginUserName()} さん
                    </div>
                    <div className="mb-2 pointer" onClick={this.changePassword}>
                      パスワード変更
                    </div>
                    <div className="pointer" onClick={this.logout}>
                      ログアウト
                    </div>
                  </ReactModal>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

TopBar.propTypes = {};

const mapDispatchToProbs = dispatch => {
  return {
    viewUser: () => {
      dispatch(actions.viewUser());
    },
    logout: () => {
      dispatch(useractions.logout());
    },
    setPolicyRoute: route => {
      dispatch(actions.setPolicyRoute(route));
    },
    setGroupRoute: route => {
      dispatch(actions.setGroupRoute(route));
    },
    setUserRoute: route => {
      dispatch(actions.setUserRoute(route));
    },
    setSettingRoute: route => {
      dispatch(actions.setSettingRoute(route));
    },
    selectGroup: group => {
      dispatch(actions.selectGroup(group));
    },
    selectUser: user => {
      dispatch(actions.selectUser(user));
    },
    selectPolicy: policy => {
      dispatch(actions.selectPolicy(policy));
    }
  };
};

const mapStateToProps = state => {
  return {
    lvExpandState: state.lvExpandState,
    userState: state.userState
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(TopBar);
