import * as types from "constants/ActionTypes";

const initState = {
  id: null,
  group_list: [],
  policy_list: []
};

const userSelection = (state = initState, action) => {
  if (action.type === types.SELECT_USER) {
    state = action.user;
  }
  return state;
};

export default userSelection;
