import React, { Component } from "react";
import { connect } from "react-redux";
import CustomCheckbox from "components/common/CustomCheckbox";
import * as actions from "actions/Actions";
import { DB_RESOURCE_TYPE } from "constants/CommonConstants";
import _ from "lodash";

class Company extends Component {
  onSelectCreate = e => {
    const { checked } = e.target;
    const permision = _.cloneDeep(this.props.companyRes);
    permision.isCreate = checked;
    this.props.setCompanyRes(permision);
    this.props.setJsonData(DB_RESOURCE_TYPE.COMPANY, false, permision);
  };

  onSelectDelete = e => {
    const { checked } = e.target;
    const permision = _.cloneDeep(this.props.companyRes);
    permision.isDelete = checked;
    this.props.setCompanyRes(permision);
    this.props.setJsonData(DB_RESOURCE_TYPE.COMPANY, false, permision);
  };

  render() {
    const { companyRes } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <span className="pr-2">
            <CustomCheckbox
              checked={companyRes.isCreate}
              onChange={this.onSelectCreate}
            />
          </span>
          作成
        </div>
        <div className="row">
          <span className="pr-2">
            <CustomCheckbox
              checked={companyRes.isDelete}
              onChange={this.onSelectDelete}
            />
          </span>
          削除
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    companyRes: state.dbCommonRes.company
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCompanyRes: company => {
      dispatch(actions.setCompanyRes(company));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
