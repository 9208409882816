import { LoginConstants } from "constants/LoginConstants";
import { LoginService } from "services/LoginService";
import { hist } from "App";
import * as RoutePath from "constants/RoutePath";

export const logout = () => {
  var isLogout = LoginService.logout();
  if (isLogout === true) {
    return dispatch => {
      dispatch(logout_success());
      hist.push(RoutePath.LOGIN_PATH);
    };
  } else {
    return dispatch => {
      dispatch(logout_failure());
    };
  }
};

function logout_success() {
  return { type: LoginConstants.LOGOUT_SUCCESS };
}
function logout_failure() {
  return { type: LoginConstants.LOGOUT_FAILURE };
}