import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import * as serviceWorker from "serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "css/style.css";
import "css/listview.css";
import "css/toastr.css";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import appReducer from "reducers/index";
import { Provider } from "react-redux";
import Amplify from "aws-amplify";
import config from "./config";
import dbConfig from "./dbconfig";
import * as LocalStorage from "constants/LocalStorage";

export const store = createStore(appReducer, applyMiddleware(thunk));

Amplify.configure({
  // Auth: {
  //   mandatorySignIn: true,
  //   region: config.cognito.REGION,
  //   userPoolId: config.cognito.USER_POOL_ID,
  //   identityPoolId: config.cognito.IDENTITY_POOL_ID,
  //   userPoolWebClientId: config.cognito.APP_CLIENT_ID
  // },
  // Storage: {
  //   region: config.s3.REGION,
  //   bucket: config.s3.BUCKET,
  //   identityPoolId: config.cognito.IDENTITY_POOL_ID
  // },
  API: {
    endpoints: [
      {
        name: "onigiri-am-web",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem(LocalStorage.LOGIN_USER)
              ? JSON.parse(localStorage.getItem(LocalStorage.LOGIN_USER)).token
                  .idToken
              : null
          };
        }
      },
      {
        name: "kdb-cm-web",
        endpoint: dbConfig.apiGateway.URL,
        region: dbConfig.apiGateway.REGION,
        custom_header: async () => {
          return {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem(LocalStorage.LOGIN_USER)
              ? JSON.parse(localStorage.getItem(LocalStorage.LOGIN_USER)).token
                  .idToken
              : null
          };
        }
      }
    ]
  }
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export default store;
