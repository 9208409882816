import React from "react";
import BaseComponent from "components/common/BaseComponent";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import DeleteIcon from "images/ic_delete.svg";
import { GROUP_DETAIL_PATH, POLICY_DETAIL_PATH } from "constants/RoutePath";
import { LISTVIEW_TYPE } from "constants/LocalStorage";
import * as ListViewTypes from "constants/ListViewTypes";
import { hist } from "App";

// Group List
class UserEditGroup extends BaseComponent {
  componentDidUpdate() {
    // on edit cancel
    if (this.props.groupEdit === "cancelEdit") {
      this.props.editGroup("");
      this.props.setUserGroup(this.props.initList);
    }
  }

  goToPolicy = policyId => {
    const route = `${POLICY_DETAIL_PATH}/${policyId}/info`;
    this.props.setPolicyRoute(decodeURI(route));
    this.props.showPolicy();
    this.props.editGroup("goToPolicy");
    hist.push(decodeURI(route));
    localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.POLICY);
  };

  getGroupPolicy = policyList => {
    return policyList.map((policy, index) => (
      <span
        className="pointer"
        onClick={this.goToPolicy.bind(this, policy.policy_id)}
      >
        {index === policyList.length - 1
          ? policy.policy_name
          : policy.policy_name + ", "}
      </span>
    ));
  };

  removeGroupFromUser = id => {
    const { groupList } = this.props;
    const removeList = groupList.filter(group => group.group_id !== id);
    this.props.setUserGroup(removeList);
  };

  goToGroup = groupName => {
    const route = `${GROUP_DETAIL_PATH}/${groupName}/info`;
    this.props.setGroupRoute(decodeURI(route));
    this.props.showGroup();
    hist.push(decodeURI(route));
    localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.GROUP);
  };

  render() {
    const { groupList } = this.props;
    return (
      <div className="mdl-list-outer-group-user">
        {groupList.map((group, index) => (
          <div className="row no-gutters" key={index}>
            <div className="col-2 d-flex justify-content-end align-items-center">
              <img
                src={DeleteIcon}
                className="icon-size-15"
                alt="Delete"
                onClick={this.removeGroupFromUser.bind(this, group.group_id)}
              />
            </div>
            <div className="col">
              <div className="mdl-grid mdl-list-user">
                <span
                  className="key px-2 text-wrap text-primary break-text pointer"
                  onClick={this.goToGroup.bind(this, group.group_name)}
                >
                  {group.group_name}
                </span>
                {group.policy_list.length ? (
                  <span className="value text-wrap text-primary break-text">
                    {this.getGroupPolicy(group.policy_list)}
                  </span>
                ) : (
                  <span className="value">なし</span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedUser: state.selectedUser,
    groupEdit: state.groupEdit,
    groupList: state.userEditGroup
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUserGroup: group => {
      dispatch(actions.setUserGroup(group));
    },
    editGroup: action => {
      dispatch(actions.editGroup(action));
    },
    setGroupRoute: route => {
      dispatch(actions.setGroupRoute(route));
    },
    selectGroup: group => {
      dispatch(actions.selectGroup(group));
    },
    showGroup: () => {
      dispatch(actions.showGroup());
    },
    setPolicyRoute: route => {
      dispatch(actions.setPolicyRoute(route));
    },
    showPolicy: () => {
      dispatch(actions.showPolicy());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditGroup);
