import * as types from "constants/ActionTypes";

const initState = {};

const dbMetaNodes = (state = initState, action) => {
  if (action.type === types.STORE_DB_STRUCTURE) {
    state = action.nodes;
  }
  return state;
};

export default dbMetaNodes;
