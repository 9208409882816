import React from "react";
import BaseComponent from "components/common/BaseComponent";
import styled from "styled-components";
import PolicyItem from "components/listview/policy/PolicyItem";
import { POLICY_DETAIL_PATH, POLICY_ADD_PATH } from "constants/RoutePath";
import ProgressBar from "components/common/ProgressBar";
import { connect } from "react-redux";
import * as ApiConstants from "constants/ApiConstants";
import * as actions from "actions/Actions";
import * as LocalStore from "constants/LocalStorage";

const TreeStyle = styled.div`
  position: inherit;
  height: calc(100vh - 150px);
  width: 296px;
  overflow: hidden;
  &:hover {
    overflow: overlay;
  }
`;

class PolicyList extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterKey: localStorage.getItem(LocalStore.POLICY_FILTER_KEY)
    };
  }

  componentDidMount = async () => {
    this.mounted = true;
    const policyListLocal = localStorage.getItem(LocalStore.LIST_POLICY);
    if (this.mounted) {
      const policyListNew = await this.getPolicyList();
      if (policyListLocal !== JSON.stringify(policyListNew)) {
        localStorage.setItem(
          LocalStore.LIST_POLICY,
          JSON.stringify(policyListNew.policy_list)
        );
        if (policyListNew.policy_list)
          this.props.setPolicyList(policyListNew.policy_list);
      } else {
        this.props.setPolicyList(JSON.parse(policyListLocal));
      }
    }
  };

  componentDidUpdate = async () => {
    // on add, delete policy
    if (
      this.props.groupEdit === "addPolicy" ||
      this.props.groupEdit === "deletePolicy"
    ) {
      this.props.editGroup("");
      if (this.mounted) {
        const policyList = await this.getPolicyList();
        this.props.setPolicyList(policyList.policy_list);
        localStorage.setItem(
          LocalStore.LIST_POLICY,
          JSON.stringify(policyList.policy_list)
        );
      }
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  // ポリシー取得
  getPolicyList = async () => {
    this.runProgress();
    const response = await this.executeApi(ApiConstants.GET_POLICIES);
    this.stopProgress();
    return response.success ? response.data : {};
  };

  onClickAdd = () => {
    this.props.history.push(POLICY_ADD_PATH);
    this.props.setPolicyRoute(POLICY_ADD_PATH);
    this.props.selectPolicy({});
    this.props.setPolicyUser([]);
  };

  onClickScrollTo = top => {
    const myDiv = document.getElementById("scroll-tree");
    myDiv.scrollTop = top ? 0 : myDiv.scrollHeight;
  };

  handleFilter = e => {
    const value = e.target.value;
    this.setState({
      filterKey: value
    });
    localStorage.setItem(LocalStore.POLICY_FILTER_KEY, value);
  };

  clearFilter = e => {
    // ESC key
    if (e.keyCode === 27) {
      this.setState({
        filterKey: ""
      });
      localStorage.setItem(LocalStore.POLICY_FILTER_KEY, "");
    }
  };

  render() {
    const { history, policyList } = this.props;
    const { filterKey } = this.state;
    let filterList = policyList;
    if (filterKey)
      filterList = filterList.filter(policy =>
        policy.policy_name.toUpperCase().includes(filterKey.toUpperCase())
      );
    return (
      <div>
        <div
          id="change-to-add-tab"
          className={
            history.location.pathname === POLICY_ADD_PATH
              ? "p-2 definition-add active"
              : "p-2 definition-add"
          }
          data-target="#definition-add"
          onClick={this.onClickAdd}
        >
          <div className="icon-size-24 lv-ic-add mr-2" />
          <span className="definition-add-txt">ポリシーの作成</span>
        </div>
        <ProgressBar ref="progress" />
        <div id="lv-filter-wrapper">
          <input
            id="filterWord"
            type="text"
            className="w-100 form-control lv-filter"
            placeholder="ポリシー名で絞り込み (Escでクリア)"
            onChange={this.handleFilter}
            onKeyDown={this.clearFilter}
            defaultValue={filterKey}
          />
        </div>
        <div id="lv-summary" className="row no-gutters mt-2 px-1">
          <div className="col-8">
            <span className="lv-table-number">
              {filterList.length} ポリシーを表示中
            </span>
          </div>
          <div className="col float-right">
            <ul className="list-inline float-right">
              <li id="scrollTop" className="list-inline-item">
                <div
                  className="icon-size-20 lv-scroll-top"
                  onClick={() => this.onClickScrollTo(true)}
                ></div>
              </li>
              <li id="scrollBottom" className="list-inline-item">
                <div
                  className="icon-size-20 lv-scroll-bottom"
                  onClick={() => this.onClickScrollTo(false)}
                ></div>
              </li>
            </ul>
          </div>
        </div>
        <div
          id="tree"
          className="row no-gutters list-view scrollbar scrollbar-primary"
        >
          <div id="search-result" className="tree force-overflow">
            <TreeStyle id="scroll-tree">
              {filterList.map((policy, index) => (
                <PolicyItem
                  key={index}
                  id={policy.policy_id}
                  name={policy.policy_name}
                  member={policy.affiliation_count}
                  path={`${POLICY_DETAIL_PATH}/${policy.policy_id}/info`}
                />
              ))}
            </TreeStyle>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    policyList: state.policyList,
    groupEdit: state.groupEdit
  };
};
const mapDispatchToProbs = dispatch => {
  return {
    setPolicyList: policyList => {
      dispatch(actions.setPolicyList(policyList));
    },
    selectPolicy: policy => {
      dispatch(actions.selectPolicy(policy));
    },
    setPolicyRoute: route => {
      dispatch(actions.setPolicyRoute(route));
    },
    editGroup: action => {
      dispatch(actions.editGroup(action));
    },
    setPolicyUser: user => {
      dispatch(actions.setPolicyUser(user));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(PolicyList);
