import BaseComponent from "components/common/BaseComponent";
import React from "react";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import * as ApiConstants from "constants/ApiConstants";
import { getLoginUserId } from "lib/LocalStorageUtils";
import * as MsgConstants from "constants/MessageConstants";
import * as StringFormat from "lib/StringFormat";
import { POLICY_DETAIL_PATH } from "constants/RoutePath";
import { MODAL_TYPE, SERVICE_LIST } from "constants/CommonConstants";
import DropDownService from "components/common/DropDownService";
import DBPolicy from "components/content/policy/DBPolicy";
import AMPolicy from "components/content/policy/AMPolicy";
import PolicyEditUser from "components/content/policy/PolicyEditUser";
import { getMetaRes } from "components/content/policy/oniDbRes/metaData/MetaAM";
import { parseDbNodes } from "components/content/policy/oniDbRes/metaData/ParseData";
import { parseCodeTreeNodes } from "components/content/policy/oniDbRes/dbCode/ParseCodeList";
import {
  DB_STRUCTURE,
  CODE_LIST,
  DB_GROUP,
  GROUP_LIST,
  LIST_USERS
} from "constants/LocalStorage";
import _ from "lodash";

const services = Object.values(SERVICE_LIST);

class PolicyAdd extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      policyName: "",
      policyDesc: "",
      selectedService: services[0]
    };
  }

  componentDidMount = async () => {
    let { metaNodes, codeNodes } = this.props;
    this.mounted = true;
    // store original meta structure
    if (
      this.mounted &&
      (!localStorage.getItem(DB_STRUCTURE) || _.isEmpty(metaNodes))
    ) {
      const dbStructure = await this.getMetaStructure();
      localStorage.setItem(DB_STRUCTURE, JSON.stringify(dbStructure));
      metaNodes = parseDbNodes(dbStructure);
      this.props.storeDbStructure(metaNodes);
    }

    // store original code structure
    if (
      this.mounted &&
      (!localStorage.getItem(CODE_LIST) || _.isEmpty(codeNodes))
    ) {
      const codeList = await this.getDbCodeList();
      localStorage.setItem(CODE_LIST, JSON.stringify(codeList));
      codeNodes = parseCodeTreeNodes(codeList);
      this.props.storeCodeStructure(codeNodes);
    }

    // store original group structure
    if (this.mounted) {
      let groupList = JSON.parse(localStorage.getItem(DB_GROUP));
      if (!groupList) {
        groupList = await this.getDbGroupList();
        localStorage.setItem(DB_GROUP, JSON.stringify(groupList));
      }
      this.props.setDbGroupList(groupList);
    }

    // reset resource
    this.props.selectPolicy({});
    this.props.setDbJson({ Statement: [] });
    this.props.setDbNodes(_.cloneDeep(metaNodes));
    this.props.isCreateCode(false);
    this.props.setCodeNodes(_.cloneDeep(codeNodes));
    this.props.setGroupRes({ isManage: false });
    this.props.setCodeRes([]);
    this.props.setTopicRes({ isDeleteTopic: false, isDeleteCmt: false });
    this.props.setCompanyRes({ isDelete: false, isCreate: false });
    this.props.setSystemRes({ isLogin: false });
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  // ポリシー取得
  getPolicyInfo = async policyId => {
    this.runProgress();
    const query = {
      policy_id: policyId
    };
    const response = await this.executeApi(
      ApiConstants.GET_POLICY,
      null,
      query,
      null
    );
    this.stopProgress();
    const policy = response.success ? response.data : {};
    policy.id = policyId;
    this.props.selectPolicy(policy);
  };

  getMetaStructure = async () => {
    const response = await this.executeApi(ApiConstants.GET_DB_STRUCTURE);
    return response.success ? response.data : {};
  };

  getDbCodeList = async () => {
    let codeList = [];
    const response = await this.executeApi(ApiConstants.GET_CODE);
    if (response.success) {
      codeList = response.data.code_definition_info;
    }
    return codeList;
  };

  getDbGroupList = async () => {
    const response = await this.executeApi(ApiConstants.GET_DB_GROUP);
    return response.success ? response.data.group_list : [];
  };

  getPolicyList = async () => {
    const response = await this.executeApi(ApiConstants.GET_POLICIES);
    return response.success ? response.data : {};
  };

  onNameChange = e => {
    const { value } = e.target;
    this.setState({
      policyName: value
    });
  };

  onDescChange = e => {
    const { value } = e.target;
    this.setState({
      policyDesc: value
    });
  };

  openModal = params => {
    this.props.openModal(params[0], params[1]);
  };

  addPolicy = () => {
    const { policyName, policyDesc } = this.state;
    let attachUsers = [];
    const deleteUsers = [];
    const {
      policyEditUser,
      metaData,
      codeRes,
      groupRes,
      topicRes,
      companyRes,
      tagRes,
      dbJson,
      systemRes
    } = this.props;

    // Check name empty
    if (!policyName.trim()) {
      this.showDialog({ message: MsgConstants.POLICY_NAME_NOT_DEFINE });
      return;
    }

    // Check name valid
    if (!StringFormat.isValidName(policyName.trim())) {
      this.showDialog({ message: MsgConstants.POLICY_NAME_INVALID });
      return;
    }

    // check JSON error
    if (dbJson.isError) {
      this.showDialog({ message: MsgConstants.JSON_HAS_ERROR });
      return;
    }

    // get attach list
    policyEditUser.forEach(user => {
      let userObj = {
        type: user.type,
        id: user.id
      };
      // if type is group
      if (user.type) {
        const groupList = JSON.parse(localStorage.getItem(GROUP_LIST));
        if (groupList.filter(e => e.group_id === Number(user.id)).length) {
          attachUsers.push(userObj);
        } else {
          deleteUsers.push(user.name);
        }
      }
      // if type is user
      else {
        const users = JSON.parse(localStorage.getItem(LIST_USERS));
        if (users.filter(e => e.id === user.id).length) {
          attachUsers.push(userObj);
        } else {
          deleteUsers.push(user.name);
        }
      }
    });

    // check user/group valid
    if (deleteUsers.length) {
      this.showDialog({
        message: StringFormat.format(MsgConstants.POLICY_ATTACH_NOT_EXIST, [deleteUsers.join(",")])
      });
      return;
    }

    /*** Get overview ****/
    let statements = [];
    // get meta resoure access permission
    statements = getMetaRes(metaData.dbNodes);
    // get code resoure access permission
    if (codeRes) {
      statements = statements.concat(codeRes);
    }
    // get group resoure access permission
    if (groupRes && groupRes.isManage) {
      statements.push({
        Action: ["read", "update", "create", "delete"],
        Resource: "orn:onigiri:db:group"
      });
    }
    // get topic resoure access permission
    if (topicRes && (topicRes.isDeleteTopic || topicRes.isDeleteCmt)) {
      const topicAction = [];
      if (topicRes.isDeleteTopic) topicAction.push("deleteTopic");
      if (topicRes.isDeleteCmt) topicAction.push("deleteCmt");
      const topicAccess = {
        Action: topicAction,
        Resource: "orn:onigiri:db:topic"
      };
      statements.push(topicAccess);
    }
    // get company resoure access permission
    if (companyRes && (companyRes.isCreate || companyRes.isDelete)) {
      const companyAction = [];
      if (companyRes.isDelete) companyAction.push("delete");
      if (companyRes.isCreate) companyAction.push("create");
      const companyAccess = {
        Action: companyAction,
        Resource: "orn:onigiri:db:company"
      };
      statements.push(companyAccess);
    }
    // get tag resoure access permission
    if (tagRes && (tagRes.isCreate || tagRes.isDelete)) {
      const tagAction = [];
      if (tagRes.isDelete) tagAction.push("delete");
      if (tagRes.isCreate) tagAction.push("create");
      const tagAccess = {
        Action: tagAction,
        Resource: "orn:onigiri:db:tag"
      };
      statements.push(tagAccess);
    }
    // get ONIGIRI-AM system permission
    if (systemRes && systemRes.isLogin) {
      statements.push({
        Action: ["allow"],
        Resource: "orn:onigiri:am:login"
      });
    }

    // 権限が１つも設定されていない場合は入力エラーとする
    if (!statements.length) {
      this.showDialog({
        message: MsgConstants.POLICY_STATEMENT_ERROR
      });
      return;
    }

    const overViewObject = {
      Statement: statements
    };

    // perform create policy
    const requestParam = {
      valid_flag: true,
      policy_name: policyName.trim(),
      description: policyDesc.trim(),
      overview: JSON.stringify(overViewObject),
      attach_list: attachUsers,
      create_user: getLoginUserId()
    };

    this.showDialog({
      message: StringFormat.format(MsgConstants.ADD_CONFIRM, [policyName]),
      confirm: true,
      positive: async () => {
        const response = await this.executeApi(
          ApiConstants.POST_POLICY,
          null,
          null,
          requestParam
        );
        if (response.success) {
          this.showToast(
            StringFormat.format(MsgConstants.ADD_SUCCESS, ["ポリシー"])
          );
          // update policy list
          const policyList = await this.getPolicyList();
          const newPolicy = policyList.policy_list.filter(
            policy => policy.policy_name === policyName
          )[0];
          const path = `${POLICY_DETAIL_PATH}/${newPolicy.policy_id}/info`;
          await this.getPolicyInfo(newPolicy.policy_id);
          this.props.setPolicyRoute(path);
          this.props.editGroup("addPolicy");
          this.props.history.push(path);
        }
      }
    });
  };

  onSelectService = service => {
    this.setState({
      selectedService: service
    });
  };

  render() {
    const { selectedService } = this.state;
    return (
      <div className="container-fluid m-0 p-0 w-100">
        <div
          id="detail-margin"
          className={this.setDetailStyle(this.props.isLvExpand)}
        >
          <div className="tab-pane" id="definition-add">
            <div className="m-0 flex-nowrap">
              <div className="d-flex mb-3 pr-3">
                <div className="d-flex">
                  <span className="align-self-center adm-detail-main-title">
                    ポリシーの作成
                  </span>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-2 custom-border-bottom">
                  <span className="key">ポリシー名</span>
                </div>
                <div className="col custom-border-bottom">
                  <input
                    type=""
                    className="custom-input"
                    ref="policyNameRef"
                    onChange={this.onNameChange}
                  />
                </div>
              </div>
              <div>
                <div className="row no-gutters">
                  <div className="col-2 custom-border-bottom">
                    <span className="key">説明</span>
                  </div>
                  <div className="col mt-2">
                    <textarea
                      className="custom-input"
                      rows={3}
                      name="comment"
                      onChange={this.onDescChange}
                      ref="policyDescRef"
                      style={{
                        overflow: "auto",
                        paddingBottom: "3px",
                        resize: "none"
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="pb-2">
                <div className="row no-gutters">
                  <div className="col-2 detail-policy-sub-title key">
                    サービス
                  </div>
                  <div className="col mt-2">
                    <DropDownService
                      data={services}
                      onChange={this.onSelectService}
                      selected={selectedService}
                    />
                  </div>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col custom-border-bottom"></div>
              </div>
              {selectedService === SERVICE_LIST.ONIGIRIAM.name && (
                <div>
                  <AMPolicy />
                  <div className="row no-gutters">
                    <div className="col custom-border-bottom"></div>
                  </div>
                </div>
              )}
              {selectedService === SERVICE_LIST.ONIGIRIDB.name && (
                <div>
                  <DBPolicy mode="addPolicy" />
                  <div className="row no-gutters">
                    <div className="col custom-border-bottom"></div>
                  </div>
                </div>
              )}
              <div className="pb-2">
                <div className="row no-gutters">
                  <div className="key col-2 detail-policy-sub-title">
                    ポリシーのアタッチ
                  </div>
                  <div className="col">
                    <div
                      className="btn btn-positive rounded-1 mt-2"
                      onClick={this.openModal.bind(this, [
                        MODAL_TYPE.USER_GROUP,
                        []
                      ])}
                    >
                      アタッチ
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-2"></div>
                  <div className="col">
                    <div className="mdl-list-user-header">
                      <div className="mdl-grid mdl-list-user">
                        <span className="value pl-2">名前</span>
                        <span className="value">タイプ</span>
                      </div>
                    </div>
                  </div>
                </div>
                <PolicyEditUser mode="add" />
              </div>
              <div className="py-3">
                <input
                  type="button"
                  className="btn btn-positive rounded-1"
                  value="作成"
                  onClick={this.addPolicy}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLvExpand: state.lvExpandState,
    policyList: state.policyList,
    userList: state.userList,
    selectedPolicy: state.selectedPolicy,
    policyEditUser: state.policyEditUser,
    metaData: state.dbMetaData,
    metaNodes: state.dbMetaNodes,
    codeData: state.dbCodeData,
    codeNodes: state.dbCodeNodes,
    groupRes: state.dbCommonRes.group,
    topicRes: state.dbCommonRes.topic,
    companyRes: state.dbCommonRes.company,
    tagRes: state.dbCommonRes.tag,
    dbJson: state.dbJson,
    codeRes: state.dbCodeRes,
    systemRes: state.systemRes,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectPolicy: policy => {
      dispatch(actions.selectPolicy(policy));
    },
    setPolicyRoute: route => {
      dispatch(actions.setPolicyRoute(route));
    },
    editGroup: action => {
      dispatch(actions.editGroup(action));
    },
    openModal: (modal, data) => {
      dispatch(actions.openModal(modal, data));
    },
    storeDbStructure: nodes => {
      dispatch(actions.storeDbStructure(nodes));
    },
    storeCodeStructure: nodes => {
      dispatch(actions.storeCodeStruture(nodes));
    },
    setDbNodes: nodes => {
      dispatch(actions.setDbNodes(nodes));
    },
    setCodeNodes: nodes => {
      dispatch(actions.setCodeNodes(nodes));
    },
    setDbGroupList: list => {
      dispatch(actions.setDbGroupList(list));
    },
    setTopicRes: topic => {
      dispatch(actions.setTopicRes(topic));
    },
    setCompanyRes: company => {
      dispatch(actions.setCompanyRes(company));
    },
    setTagRes: tag => {
      dispatch(actions.setTagRes(tag));
    },
    setDbJson: json => {
      dispatch(actions.setDbJson(json));
    },
    isCreateCode: isCreate => {
      dispatch(actions.isCreateCode(isCreate));
    },
    setGroupRes: group => {
      dispatch(actions.setGroupRes(group));
    },
    setCodeRes: code => {
      dispatch(actions.setCodeRes(code));
    },
    setSystemRes: system => {
      dispatch(actions.setSystemRes(system));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PolicyAdd);
