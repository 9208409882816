import React from "react";
import BaseComponent from "components/common/BaseComponent";

let interval = null;
class ProgressBar extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      showing: false,
      barWidth: 0
    };
  }

  run() {
    if (this.state.showing) {
      return;
    }

    this.setState({
      showing: true,
      barWidth: this.state.barWidth
    });

    interval = setInterval(() => {
      this.setState({
        showing: true,
        barWidth: this.state.barWidth
      });
    }, 100);
  }

  stop() {
    clearInterval(interval);
    this.setState({
      showing: false,
      barWidth: 0
    });
  }

  render() {
    if (this.state.showing) {
      this.state.barWidth += 2;
    }
    return (
      <div className="progress lv-progress" style={{ visibility: this.state.showing ? 'visible' : 'hidden' }}>
        <div
          className="progress-bar lv-progress-color"
          style={{ width: `${this.state.barWidth}%` }}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
    );
  }
}

export default ProgressBar;