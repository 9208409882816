const local = {
    apiGateway: {
      URL: "http://localhost:3000"
    },
    cognito: {
      USER_POOL_ID: "ap-northeast-1_Oy38jdZIH",
      IDENTITY_POOL_ID: "ap-northeast-1:baf89863-e120-4dd4-95ee-dc82ba0aee78",
      CLIENT_ID: "1vcgja7tdlu5b8rnjcssq9isa8",
      REGION: "ap-northeast-1",
      IDENTITY_SERVICE_PROVIDER_API_VERSION: "2016-04-18"
    }
  };
  
  const dev = {
    apiGateway: {
      URL: "https://onigiridbapi.devel.gs"
    },
    cognito: {
      USER_POOL_ID: "ap-northeast-1_Oy38jdZIH",
      IDENTITY_POOL_ID: "ap-northeast-1:baf89863-e120-4dd4-95ee-dc82ba0aee78",
      CLIENT_ID: "1vcgja7tdlu5b8rnjcssq9isa8",
      REGION: "ap-northeast-1",
      IDENTITY_SERVICE_PROVIDER_API_VERSION: "2016-04-18"
    }
  };

  const stg = {
    apiGateway: {
      URL: "https://onigiridbapi.stg.gs"
    },
    cognito: {
      USER_POOL_ID: "ap-northeast-1_Oy38jdZIH",
      IDENTITY_POOL_ID: "ap-northeast-1:baf89863-e120-4dd4-95ee-dc82ba0aee78",
      CLIENT_ID: "1vcgja7tdlu5b8rnjcssq9isa8",
      REGION: "ap-northeast-1",
      IDENTITY_SERVICE_PROVIDER_API_VERSION: "2016-04-18"
    }
  };
  
  // kc
  const prod = {
    apiGateway: {
      URL: "https://api.db.onigiri.devcabu.jp"
    },
    cognito: {
      USER_POOL_ID: "ap-northeast-1_m2jC1w0se",
      IDENTITY_POOL_ID: "ap-northeast-1:7d48a52a-f59c-4218-8a54-4b3eb5d0aabe",
      CLIENT_ID: "1t1rvpden51ggpj10mepbq0mgr",
      REGION: "ap-northeast-1",
      IDENTITY_SERVICE_PROVIDER_API_VERSION: "2016-04-18"
    }
  };
  
  
  // Default to dev if not set
  const config = eval(process.env.REACT_APP_STAGE);
  
  export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
  };
  