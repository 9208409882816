import * as types from "constants/ActionTypes";

const initState = [];

const dbCodeRes = (state = initState, action) => {
    if (action.type === types.SET_CODE_RES) {
        state = action.code;
      }
      return state;
};

export default dbCodeRes;
