import React from "react";
import BaseComponent from "components/common/BaseComponent";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import * as ListViewTypes from "constants/ListViewTypes";
import { LISTVIEW_TYPE } from "constants/LocalStorage";
import ArrowLeftIcon from "images/ic_arrow_left.svg";
import ArrowRightIcon from "images/ic_arrow_right.svg";
import PolicyIcon from "images/ic_policy.svg";
import GroupIcon from "images/ic_supervisor_account.svg";
import UserIcon from "images/ic_user.svg";
import SettingIcon from "images/ic_settings.svg";
import { hist } from "App";

class ActivityBar extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      policyIcon: PolicyIcon,
      groupIcon: GroupIcon,
      userIcon: UserIcon,
      settingIcon: SettingIcon
    };
  }

  componentDidMount() {
    const me = this;
    window.addEventListener("popstate", function(e) {
      me.handleBackForward(e);
    });
    window.addEventListener("load", function(e) {
      me.handleBackForward(e);
    });
  }

  handleBackForward = e => {
    let path = window.location.pathname;
    const splitedPath = path.split("/");
    const localType = localStorage.getItem(LISTVIEW_TYPE);
    let listviewType = "";
    switch (splitedPath[1]) {
      case "policy":
        listviewType = ListViewTypes.POLICY;
        this.props.setPolicyRoute(decodeURI(path));
        this.props.showPolicy();
        break;
      case "group":
        listviewType = ListViewTypes.GROUP;
        this.props.setGroupRoute(decodeURI(path));
        this.props.showGroup();
        break;
      case "user":
        listviewType = ListViewTypes.USER;
        this.props.setUserRoute(decodeURI(path));
        this.props.showUser();
        break;
      case "setting":
        listviewType = ListViewTypes.SETTING;
        this.props.setSettingRoute(path);
        this.props.showSetting();
        break;
      default:
        break;
    }
    if (listviewType !== localType) {
      localStorage.setItem(LISTVIEW_TYPE, listviewType);
    }
  };

  clickNav = (type, show) => {
    const { policyRoute, groupRoute, userRoute, settingRoute } = this.props;

    if (localStorage.getItem(LISTVIEW_TYPE) === type) {
      this.props.toogleListView();
    } else {
      show();
      if (!this.props.lvExpandState) this.props.toogleListView();
    }
    switch (type) {
      case ListViewTypes.POLICY:
        hist.push(policyRoute);
        break;
      case ListViewTypes.GROUP:
        hist.push(groupRoute);
        break;
      case ListViewTypes.USER:
        hist.push(userRoute);
        break;
      case ListViewTypes.SETTING:
        hist.push(settingRoute);
        break;
      default:
        break;
    }
  };

  isSelect(type) {
    return localStorage.getItem(LISTVIEW_TYPE) === type;
  }

  handleMouseOver(type, key) {
    if (this.isSelect(type)) {
      this.setState({
        [key]: this.props.lvExpandState ? ArrowLeftIcon : ArrowRightIcon
      });
    }
  }

  handleMouseLeave(key, icon) {
    this.setState({ [key]: icon });
  }

  handleMouseUp(type, key) {
    if (this.isSelect(type)) {
      this.setState({
        [key]: this.props.lvExpandState ? ArrowRightIcon : ArrowLeftIcon
      });
    } else {
      this.setState({ [key]: ArrowLeftIcon });
    }
  }

  getNavStyle = type => {
    const path = window.location.pathname;
    const splitedPath = path.split("/");
    return splitedPath[1] === type ? "nav-link active" : "nav-link";
  };

  render() {
    return (
      <div className="activity-bar">
        <ul className="nav navbar-nav align-items-center">
          <li
            className="nav-item pb-2"
            onClick={() =>
              this.clickNav(ListViewTypes.POLICY, this.props.showPolicy)
            }
          >
            <div
              className={this.getNavStyle("policy")}
              onMouseOver={() =>
                this.handleMouseOver(ListViewTypes.POLICY, "policyIcon")
              }
              onMouseLeave={() =>
                this.handleMouseLeave("policyIcon", PolicyIcon)
              }
              onMouseUp={() =>
                this.handleMouseUp(ListViewTypes.POLICY, "policyIcon")
              }
            >
              <img
                className="icon-size-25"
                src={this.state.policyIcon}
                alt=""
              />
            </div>
          </li>
          <li
            className="nav-item py-2"
            onClick={() =>
              this.clickNav(ListViewTypes.GROUP, this.props.showGroup)
            }
          >
            <div
              className={this.getNavStyle("group")}
              onMouseOver={() =>
                this.handleMouseOver(ListViewTypes.GROUP, "groupIcon")
              }
              onMouseLeave={() => this.handleMouseLeave("groupIcon", GroupIcon)}
              onMouseUp={() =>
                this.handleMouseUp(ListViewTypes.GROUP, "groupIcon")
              }
            >
              <img className="icon-size-25" src={this.state.groupIcon} alt="" />
            </div>
          </li>
          <li
            className="nav-item py-2"
            onClick={() =>
              this.clickNav(ListViewTypes.USER, this.props.showUser)
            }
          >
            <div
              className={this.getNavStyle("user")}
              onMouseOver={() =>
                this.handleMouseOver(ListViewTypes.USER, "userIcon")
              }
              onMouseLeave={() => this.handleMouseLeave("userIcon", UserIcon)}
              onMouseUp={() =>
                this.handleMouseUp(ListViewTypes.USER, "userIcon")
              }
            >
              <img className="icon-size-25" src={this.state.userIcon} alt="" />
            </div>
          </li>
          <li
            className="nav-item py-2"
            onClick={() =>
              this.clickNav(ListViewTypes.SETTING, this.props.showSetting)
            }
          >
            <div
              className={this.getNavStyle("setting")}
              onMouseOver={() =>
                this.handleMouseOver(ListViewTypes.SETTING, "settingIcon")
              }
              onMouseLeave={() =>
                this.handleMouseLeave("settingIcon", SettingIcon)
              }
              onMouseUp={() =>
                this.handleMouseUp(ListViewTypes.SETTING, "settingIcon")
              }
            >
              <img
                className="icon-size-25"
                src={this.state.settingIcon}
                alt=""
              />
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    policyRoute: state.policyRoute,
    groupRoute: state.groupRoute,
    userRoute: state.userRoute,
    settingRoute: state.settingRoute,
    lvExpandState: state.lvExpandState,
    listViewType: state.listView
  };
};

const mapDispatchToProbs = dispatch => {
  return {
    showPolicy: () => {
      dispatch(actions.showPolicy());
    },
    showGroup: () => {
      dispatch(actions.showGroup());
    },
    showUser: () => {
      dispatch(actions.showUser());
    },
    showSetting: () => {
      dispatch(actions.showSetting());
    },
    toogleListView: () => {
      dispatch(actions.toogleListView());
    },
    setPolicyRoute: route => {
      dispatch(actions.setPolicyRoute(route));
    },
    setGroupRoute: route => {
      dispatch(actions.setGroupRoute(route));
    },
    setUserRoute: route => {
      dispatch(actions.setUserRoute(route));
    },
    setSettingRoute: route => {
      dispatch(actions.setSettingRoute(route));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(ActivityBar);
