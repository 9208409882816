import React from "react";
import BaseComponent from "components/common/BaseComponent";
import styled from "styled-components";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import values from "lodash/values";
import { CODE_LIST, DB_CODE_SEARCH_KEY } from "constants/LocalStorage";
import { parseCodeTreeNodes } from "components/content/policy/oniDbRes/dbCode/ParseCodeList";
import { DB_RESOURCE_TYPE } from "constants/CommonConstants";
import _ from "lodash";
import CodeItem from "./CodeItem";
import * as LocalStorage from "constants/LocalStorage";
import * as ApiConstants from "constants/ApiConstants";

const TreeStyle = styled.div`
  position: inherit;
  max-height: 800px;
  width: 90%;
  overflow: hidden;
  margin-left: 3px;
  &:hover {
    overflow: overlay;
  }
`;

class CodeList extends BaseComponent {
  constructor(props) {
    super(props);
    const searchNameKey = localStorage.getItem(DB_CODE_SEARCH_KEY);
    this.state = {
      searchNameKey: searchNameKey === null ? "" : searchNameKey
    };
  }

  componentWillMount = async () => {
    // 会社取得
    const response = await this.executeApi(ApiConstants.GET_COMPANY);
    if (!response.success) {
      return;
    }
    const res = JSON.stringify(response.data.company_info);
    const local = JSON.parse(localStorage.getItem(LocalStorage.COMPANY_LIST));
    if (local !== res) {
      localStorage.setItem(LocalStorage.COMPANY_LIST, res);
      this.setState({
        selectedNode: {}
      });
    }
  };

  onSearchNameEnter = e => {
    // ENTER key
    if (e.keyCode === 13) {
      this.openNodes();
    }
    // ESC key
    if (e.keyCode === 27) {
      const { codeNodes, codeData } = this.props;
      const nonSearchNodes = _.cloneDeep(codeNodes);
      const codeOpenNodes = codeData.openNodes;
      e.target.value = "";
      this.setState({
        searchNameKey: ""
      });
      localStorage.setItem(DB_CODE_SEARCH_KEY, "");
      codeOpenNodes.forEach(node => (nonSearchNodes[node].isOpen = true));
      this.props.setCodeNodes(nonSearchNodes);
    }
  };

  onSearchNameChange = e => {
    const value = e.target.value;
    this.setState({
      searchNameKey: value
    });
    localStorage.setItem(DB_CODE_SEARCH_KEY, value);
    if (value.length) {
      const filterArray = this.filterCode(value);
      const filterNodes = parseCodeTreeNodes(filterArray);
      this.props.setCodeNodes(filterNodes);
    }
  };

  onCodeSelect = e => {
    const { checked } = e.target;
    this.props.isCreateCode(checked);
    this.props.setJsonData(DB_RESOURCE_TYPE.CODE, checked);
  };

  // filter by keyword
  filterCode(keyword) {
    const codeList = JSON.parse(localStorage.getItem(CODE_LIST));
    return codeList.filter(code =>
      code.code_definition_name.toUpperCase().includes(keyword.toUpperCase())
    );
  }

  // open node
  openNodes = () => {
    const { codeData } = this.props;
    const { codeNodes } = codeData;
    values(codeNodes).forEach(node => {
      node.isOpen = true;
    });
    this.props.setCodeNodes(codeNodes);
  };

  render() {
    const { searchNameKey, isOpenSearch } = this.state;
    const { codeNodes, codeData, setJsonData } = this.props;
    const companyList = JSON.parse(localStorage.getItem(LocalStorage.COMPANY_LIST));
    if (!companyList) return null;
    return (
      <React.Fragment>
        <div className="row mdl-grid mdl-list-policy-dbcode">
          <div id="lv-filter-wrapper">
            {/* <input
              type="text"
              className="w-100 form-control lv-filter"
              placeholder="会社名で絞り込み (Escでクリア)"
              onKeyDown={this.onSearchNameEnter}
              onChange={this.onSearchNameChange}
              id="code-name-input"
              value={searchNameKey}
            /> */}
          </div>
          <div id="lv-filter-wrapper">参照</div>
          <div id="lv-filter-wrapper">更新</div>
          <div id="lv-filter-wrapper">作成</div>
          <div id="lv-filter-wrapper">削除</div>
        </div>
        <TreeStyle>
          {companyList.map((company, index) => (
            <CodeItem
              key={index}
              company={company}
            />
          ))}
          {/* <CodeTree
            nodes={codeNodes}
            searchNameKey={searchNameKey}
            isOpenSearch={isOpenSearch}
            searchKey={searchNameKey}
            setJsonData={setJsonData}
          /> */}
        </TreeStyle>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    codeData: state.dbCodeData,
    codeNodes: state.dbCodeNodes
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCodeNodes: nodes => {
      dispatch(actions.setCodeNodes(nodes));
    },
    isCreateCode: isCreate => {
      dispatch(actions.isCreateCode(isCreate));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CodeList);
