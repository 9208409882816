import * as types from "constants/ActionTypes";

const initState = {
  isOpenModal: false,
  modalType: "user",
  initData: []
};

const selectModal = (state = initState, action) => {
  if (action.type === types.OPEN_MODAL) {
    return {
      isOpenModal: true,
      modalType: action.modal,
      initData: action.initData
    };
  }
  if (action.type === types.CLOSE_MODAL) {
    return {
      ...state,
      isOpenModal: false
    };
  }
  return state;
};

export default selectModal;
