// Convert with the specified format
module.exports.toString = (date, format) => {
    const zeroFill = num => ("0" + num).slice(-2);
    format = format.replace("yyyy", date.getFullYear());
    format = format.replace("MM", zeroFill(date.getMonth() + 1));
    format = format.replace("dd", zeroFill(date.getDate()));
    format = format.replace("hh", zeroFill(date.getHours()));
    format = format.replace("mm", zeroFill(date.getMinutes()));
    format = format.replace("ss", zeroFill(date.getSeconds()));
    return format;
}

// Convert with the specified format: yyyyMMddhhmmss
module.exports.toStringYYYYMMDDHHMMSS = value => {
    return this.toString(new Date(value), "yyyyMMddhhmmss");
};
