import React from "react";
import BaseComponent from "components/common/BaseComponent";
import PropTypes from "prop-types";

class NavigationIcon extends BaseComponent {
  render() {
    return (
      <div>
        <div className="icon-size-25" onClick={this.props.onClick}>
          {this.props.isActive === false ? (
            <img src={this.props.icon} className="inactive-button" alt="" />
          ) : (
            <img src={this.props.icon} className="active-button" alt="" />
          )}
        </div>
      </div>
    );
  }
}

NavigationIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired
};

NavigationIcon.defaultProps = {
  isActive: false
};

export default NavigationIcon;
