import * as types from "constants/ActionTypes";

const initState = {
  isError: false,
  jsonObj: {
    Statement: []
  }
};

const dbJson = (state = initState, action) => {
  switch (action.type) {
    case types.SET_DB_JSON:
      return {
        ...state,
        jsonObj: action.json
      };
    case types.IS_JSON_ERROR:
      return {
        ...state,
        isError: action.isError
      };
    default:
      return state;
  }
};

export default dbJson;
