import React from "react";
import BaseComponent from "components/common/BaseComponent";

class CustomCheckbox extends BaseComponent {
  render() {
    return (
      <div className="pretty p-default">
        <input
          type="checkbox"
          name={this.props.name}
          checked={this.props.checked}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
        />
        <div className="state">
          <label>{this.props.label}</label>
        </div>
      </div>
    );
  }
}

CustomCheckbox.propTypes = {};

export default CustomCheckbox;
