import React from "react";
import BaseComponent from "components/common/BaseComponent";
import Tree from "components/content/policy/oniDbRes/metaData/Tree";
import styled from "styled-components";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import { parseDbNodes } from "components/content/policy/oniDbRes/metaData/ParseData";
import { DB_STRUCTURE, DB_META_SEARCH_KEY } from "constants/LocalStorage";
import values from "lodash/values";
import * as ApiConstants from "constants/ApiConstants";
import _ from "lodash";

const TreeStyle = styled.div`
  position: inherit;
  max-height: 800px;
  width: 90%;
  overflow: hidden;
  margin-left: 3px;
  &:hover {
    overflow: auto;
  }
`;

class MetaList extends BaseComponent {
  constructor(props) {
    super(props);
    const filterKeyword = localStorage.getItem(DB_META_SEARCH_KEY);
    this.state = {
      filterKeyword: filterKeyword === null ? "" : filterKeyword
    };
  }

  getStructure = async () => {
    const response = await this.executeApi(ApiConstants.GET_DB_STRUCTURE);
    return response.success ? response.data : [];
  };

  handleKeyDown = e => {
    // When the esc key is pressed
    if (e.keyCode === 27) {
      const { metaNodes, metaData } = this.props;
      const nonSearchNodes = _.cloneDeep(metaNodes);
      const dbOpenNodes = metaData.openNodes;
      e.target.value = "";
      this.setState({
        filterKeyword: ""
      });
      localStorage.setItem(DB_META_SEARCH_KEY, "");
      dbOpenNodes.forEach(node => (nonSearchNodes[node].isOpen = true));
      this.props.setDbNodes(nonSearchNodes);
    }
    // When the ENTER key is pressed
    if (e.keyCode === 13) {
      this.openNodes();
    }
  };

  handleFilter = e => {
    const keyword = e.target.value;
    this.setState({
      filterKeyword: keyword
    });
    localStorage.setItem(DB_META_SEARCH_KEY, keyword);
    const filterData = {
      instance_list: this.filterTable(keyword)
    };
    this.props.setDbNodes(parseDbNodes(filterData));
  };

  // filter by keyword
  filterTable(keyword) {
    var filtered = [];
    const arrInst = JSON.parse(localStorage.getItem(DB_STRUCTURE))
      .instance_list;

    if (!Array.isArray(arrInst)) return filtered;
    arrInst.forEach(function(instance) {
      var dbList = [];
      instance.database_list.forEach(function(db) {
        var schList = [];
        db.schema_list.forEach(function(schema) {
          var tblList = [];
          schema.table_list.forEach(function(table) {
            if (
              table.table_name.toLowerCase().includes(keyword.toLowerCase())
            ) {
              tblList.push(table);
            }
          });
          if (tblList.length) {
            schema.table_list = tblList;
            schList.push(schema);
          }
        });
        if (schList.length) {
          db.schema_list = schList;
          dbList.push(db);
        }
      });
      if (dbList.length) {
        instance.database_list = dbList;
        filtered.push(instance);
      }
    });
    return filtered;
  }

  // open node
  openNodes = () => {
    const { metaData } = this.props;
    const { dbNodes } = metaData;
    values(dbNodes).forEach(node => {
      node.isOpen = true;
    });
    this.props.setDbNodes(dbNodes);
  };

  render() {
    const { filterKeyword } = this.state;
    return (
      <div>
        <div className="row mdl-grid mdl-list-policy-meta">
          <div id="lv-filter-wrapper">
            <input
              ref="filterKey"
              type="text"
              className="w-100 form-control lv-filter"
              placeholder="テーブル名で絞り込み (Escでクリア)"
              onChange={this.handleFilter}
              onKeyDown={this.handleKeyDown}
              value={filterKeyword}
            />
          </div>
          <div id="lv-filter-wrapper">参照</div>
          <div id="lv-filter-wrapper">更新</div>
        </div>
        <TreeStyle>
          <Tree
            searchKey={filterKeyword}
            setJsonData={this.props.setJsonData}
          />
        </TreeStyle>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    metaData: state.dbMetaData,
    metaNodes: state.dbMetaNodes
  };
};

const mapDispatchToProbs = dispatch => {
  return {
    setDbNodes: nodes => {
      dispatch(actions.setDbNodes(nodes));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(MetaList);
