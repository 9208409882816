import React from "react";
import BaseComponent from "components/common/BaseComponent";
import PropTypes from "prop-types";

class EditAction extends BaseComponent {
  static propTypes = {
    prop: PropTypes
  };
  render() {
    return (
      <div id="actionMenu" className="ml-auto d-flex flex-nowrap">
        <button
          type="button"
          disabled={this.props.isDisable}
          className="btn btn-cancel rounded-1 mr-2"
          onClick={this.props.onCancelHandler}
          style={{ display: this.props.hidden ? "none" : "" }}
        >
           編集前に戻す
        </button>
        <button
          type="button"
          disabled={this.props.isDisable}
          className="btn btn-save btn-positive rounded-1"
          onClick={this.props.onSaveHandler}
        >
          保存
        </button>
      </div>
    );
  }
}

EditAction.propTypes = {
  onSaveHandler: PropTypes.func.isRequired,
  onCancelHandler: PropTypes.func.isRequired
};

export default EditAction;
