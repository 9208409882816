import BaseComponent from "components/common/BaseComponent";
import React from "react";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import * as ApiConstants from "constants/ApiConstants";
import { getLoginUserName } from "lib/LocalStorageUtils";
import * as MsgConstants from "constants/MessageConstants";
import * as StringFormat from "lib/StringFormat";
import * as LocalStore from "constants/LocalStorage";
import { GROUP_DETAIL_PATH } from "constants/RoutePath";
import GroupEditUser from "components/content/group/GroupEditUser";
import GroupEditPolicy from "components/content/group/GroupEditPolicy";
import { MODAL_TYPE } from "constants/CommonConstants";
import { LIST_USERS } from "constants/LocalStorage";

class GroupAdd extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      groupName: ""
    };
  }

  componentDidMount = async () => {
    this.mounted = true;
    // get group list
    const groupListLocal = localStorage.getItem(LocalStore.GROUP_LIST);
    const groupListNew = await this.getGroupList();
    if (groupListLocal !== JSON.stringify(groupListNew)) {
      localStorage.setItem(
        LocalStore.GROUP_LIST,
        JSON.stringify(groupListNew.group_list)
      );
      this.props.setGroupList(groupListNew.group_list);
    } else {
      this.props.setGroupList(JSON.parse(groupListLocal));
    }
    await this.getUserList();
    this.props.selectGroup({});
    this.props.setGroupPolicy([]);
    this.props.setGroupUser([]);
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  getUserList = async () => {
    if (this.mounted) {
      let { userList } = this.props;
      const userListLocal = JSON.parse(localStorage.getItem(LIST_USERS));
      if (userListLocal) {
        const userIds = userListLocal.map(user => user.id);
        const userInfos = await this.getUserInfos(userIds);
        userListLocal.forEach(user => {
          const userInfo = userInfos.user_list.filter(info => info.user_id === user.id);
          if (userInfo.length) {
            user.policy_list = userInfo[0].policy_list;
            user.group_list = userInfo[0].group_list;
          }
        });
        userList = userListLocal;
      }
      this.props.setUserList(userList);
      return userList;
    }
  };

  getUserInfos = async userIds => {
    const requestParam = {
      user_ids: userIds.join(",")
    };
    const response = await this.executeApi(
      ApiConstants.GET_USER,
      null,
      null,
      requestParam
    );
    return response.success ? response.data : {};
  };

  // get group list
  getGroupList = async () => {
    this.runProgress();
    const response = await this.executeApi(ApiConstants.GET_GROUPS);
    this.stopProgress();
    return response.success ? response.data : [];
  };

  onNameChange = e => {
    const { value } = e.target;
    this.setState({
      groupName: value
    });
  };

  openModal = params => {
    this.props.openModal(params[0], params[1]);
  };

  addGroup = () => {
    const { groupName } = this.state;
    const { groupEditUser, groupEditPolicy } = this.props;
    const editUsers = [];
    const editPolices = [];
    const delPolicy = [];

    groupEditUser.forEach(user => {
      editUsers.push(user.id);
    });
    groupEditPolicy.forEach(policy => {
      const policyList = JSON.parse(localStorage.getItem(LocalStore.LIST_POLICY));
      if (policyList.filter(e => e.policy_id === policy.policy_id).length) {
        editPolices.push(policy.policy_id);
      } else delPolicy.push(policy.policy_name);
    });

    // Check name empty
    if (!groupName.trim()) {
      this.showDialog({ message: MsgConstants.GROUP_NAME_NOT_DEFINE });
      return;
    }

    // Check name valid
    if (!StringFormat.isValidName(groupName.trim())) {
      this.showDialog({ message: MsgConstants.GROUP_NAME_INVALID });
      return;
    }

    // check valid policy
    if (delPolicy.length) {
      this.showDialog({
        message: StringFormat.format(MsgConstants.POLICY_NOT_EXIST, [delPolicy])
      });
      return;
    }
    
    // perform add code
    const requestParam = {
      group_name: groupName.trim(),
      valid_flag: true,
      user_list: editUsers,
      policy_list: editPolices,
      update_user: getLoginUserName()
    };

    this.showDialog({
      message: StringFormat.format(MsgConstants.ADD_CONFIRM, [groupName]),
      confirm: true,
      positive: async () => {
        const response = await this.executeApi(
          ApiConstants.POST_GROUP,
          null,
          null,
          requestParam
        );
        if (response.success) {
          this.showToast(
            StringFormat.format(MsgConstants.ADD_SUCCESS, ["グループ"])
          );
          // update group list
          const path = `${GROUP_DETAIL_PATH}/${groupName}/info`;
          this.props.history.push(path);
          this.props.setGroupRoute(path);
          this.props.editGroup("addGroup");
        }
      }
    });
  };

  render() {
    const { userList, policyList } = this.state;
    return (
      <div className="container-fluid m-0 p-0 w-100">
        <div
          id="detail-margin"
          className={this.setDetailStyle(this.props.isLvExpand)}
        >
          <div className="tab-pane" id="definition-add">
            <div className="m-0 flex-nowrap">
              <div className="d-flex mb-3 pr-3">
                <div className="d-flex">
                  <span className="align-self-center adm-detail-main-title">
                    グループの作成
                  </span>
                </div>
              </div>
              <div className="custom-border-bottom">
                <div className="row no-gutters">
                  <div className="col-2 d-flex ">
                    <span className="key detail-group-sub-title">グループ名</span>
                  </div>
                  <div className="col pl-2">
                    <input
                      type=""
                      className="custom-input"
                      ref="groupNameRef"
                      onChange={this.onNameChange}
                    />
                  </div>
                </div>
              </div>
              <div className="pb-3">
                <div className="row no-gutters">
                  <div className="key col-2 detail-group-sub-title">
                    所属するユーザー
                  </div>
                  <div className="col">
                    <div className="mdl-list-user-header">
                      <div
                        className="btn btn-positive rounded-1 ml-2 mt-1"
                        onClick={this.openModal.bind(this, [
                          MODAL_TYPE.USER,
                          []
                        ])}
                      >
                        ユーザーを選択
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-2" />
                  <div className="col">
                    <div className="mdl-list-user-header">
                      <div className="mdl-grid mdl-list-user">
                        <span className="value pl-2">ユーザー名</span>
                        <span className="value">アタッチ済みのポリシー</span>
                      </div>
                    </div>
                  </div>
                </div>
                <GroupEditUser userList={userList}></GroupEditUser>
              </div>
              <div className="row no-gutters">
                <div className="col custom-border-bottom"></div>
              </div>
              <div className="pb-3">
                <div className="row no-gutters">
                  <div className="key col-2 detail-group-sub-title">
                    ポリシーのアタッチ
                  </div>
                  <div className="col">
                    <div
                      className="btn btn-positive rounded-1 ml-2 mt-1"
                      onClick={this.openModal.bind(this, [
                        MODAL_TYPE.POLICY_GROUP,
                        []
                      ])}
                    >
                      ポリシーを選択
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-2"></div>
                  <div className="col">
                    <div className="mdl-list-user-header">
                      <div className="mdl-grid mdl-list-user">
                        <span className="value pl-2">ポリシー名</span>
                        <span className="value">説明</span>
                      </div>
                    </div>
                  </div>
                </div>
                <GroupEditPolicy policyList={policyList}></GroupEditPolicy>
              </div>
              <div className="pb-3">
                <input
                  type="button"
                  className="btn btn-positive rounded-1"
                  value="作成"
                  onClick={this.addGroup}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLvExpand: state.lvExpandState,
    groupList: state.groupList,
    userList: state.userList,
    selectedGroup: state.selectedGroup,
    groupEditUser: state.groupEditUsers,
    groupEditPolicy: state.groupEditPolicy
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setGroupList: groupList => {
      dispatch(actions.setGroupList(groupList));
    },
    selectGroup: group => {
      dispatch(actions.selectGroup(group));
    },
    setGroupRoute: route => {
      dispatch(actions.setGroupRoute(route));
    },
    editGroup: action => {
      dispatch(actions.editGroup(action));
    },
    openModal: (modal, data) => {
      dispatch(actions.openModal(modal, data));
    },
    setUserList: list => {
      dispatch(actions.setUserList(list));
    },
    setGroupUser: users => {
      dispatch(actions.setGroupUser(users));
    },
    setGroupPolicy: policy => {
      dispatch(actions.setGroupPolicy(policy));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdd);
