import React from "react";
import BaseComponent from "components/common/BaseComponent";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import DeleteIcon from "images/ic_delete.svg";
import { USER_DETAIL_PATH, POLICY_DETAIL_PATH } from "constants/RoutePath";
import { LISTVIEW_TYPE } from "constants/LocalStorage";
import * as ListViewTypes from "constants/ListViewTypes";
import { hist } from "App";

// User List
class GroupEditUser extends BaseComponent {
  componentDidUpdate() {
    // on edit cancel
    if (this.props.groupEdit === "cancelEdit") {
      this.props.editGroup("");
      this.props.setGroupUser(this.props.initList);
    }
  }

  goToPolicy = policyId => {
    const route = `${POLICY_DETAIL_PATH}/${policyId}/info`;
    this.props.setPolicyRoute(decodeURI(route));
    this.props.showPolicy();
    this.props.editGroup("goToPolicy");
    hist.push(decodeURI(route));
    localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.POLICY);
  };

  getUserPolicy = policyList => {
    return policyList.map((policy, index) => (
      <span
        className="pointer"
        onClick={this.goToPolicy.bind(this, policy.policy_id)}
      >
        {index === policyList.length - 1
          ? policy.policy_name
          : policy.policy_name + ", "}
      </span>
    ));
  };

  removeUserFromGroup = id => {
    const { userList } = this.props;
    const removeList = userList.filter(user => user.id !== id);
    this.props.setGroupUser(removeList);
  };

  goToUser = UserId => {
    const route = `${USER_DETAIL_PATH}/${UserId}/info`;
    this.props.setUserRoute(decodeURI(route));
    this.props.showUser();
    hist.push(decodeURI(route));
    localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.USER);
  };

  render() {
    const { userList } = this.props;
    // sort by name
    userList.sort(function(a, b) {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return (
      <div className="mdl-list-outer-group-user">
        {userList.map((user, index) => (
          <div className="row no-gutters" key={index}>
            <div className="col-2 d-flex justify-content-end align-items-center">
              <img
                src={DeleteIcon}
                className="icon-size-15"
                alt="Delete"
                onClick={this.removeUserFromGroup.bind(this, user.id)}
              />
            </div>
            <div className="col">
              <div className="mdl-grid mdl-list-user">
                <span
                  className="key pl-2 text-wrap text-primary pointer"
                  onClick={this.goToUser.bind(this, user.id)}
                >
                  {user.name}
                </span>
                {user.policy_list.length ? (
                  <span className="value text-primary">
                    {this.getUserPolicy(user.policy_list)}
                  </span>
                ) : (
                  <span className="value">なし</span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedGroup: state.selectedGroup,
    groupEdit: state.groupEdit,
    userList: state.groupEditUsers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setGroupUser: users => {
      dispatch(actions.setGroupUser(users));
    },
    editGroup: action => {
      dispatch(actions.editGroup(action));
    },
    setUserRoute: route => {
      dispatch(actions.setUserRoute(route));
    },
    showUser: () => {
      dispatch(actions.showUser());
    },
    setPolicyRoute: route => {
      dispatch(actions.setPolicyRoute(route));
    },
    showPolicy: () => {
      dispatch(actions.showPolicy());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupEditUser);
