import React from "react";
import BaseComponent from "components/common/BaseComponent";
import { connect } from "react-redux";
import EditAction from "components/common/EditAction";
import * as actions from "actions/Actions";
import * as ApiConstants from "constants/ApiConstants";
import { MODAL_TYPE } from "constants/CommonConstants";
import { hist } from "App";
import {
  POLICY_PATH,
  POLICY_ADD_PATH,
  GROUP_DETAIL_PATH,
  USER_DETAIL_PATH,
  POLICY_DETAIL_PATH
} from "constants/RoutePath";
import * as MsgConstants from "constants/MessageConstants";
import {
  LISTVIEW_TYPE,
  DB_STRUCTURE,
  CODE_LIST,
  DB_GROUP,
  DB_META_SEARCH_KEY,
  DB_CODE_SEARCH_KEY,
  DB_GROUP_SEARCH_KEY,
  LIST_USERS,
  GROUP_LIST
} from "constants/LocalStorage";
import * as StringFormat from "lib/StringFormat";
import * as ListViewTypes from "constants/ListViewTypes";
import DBPolicy from "components/content/policy/DBPolicy";
import AMPolicy from "components/content/policy/AMPolicy";
import PolicyEditUser from "components/content/policy/PolicyEditUser";
import { getMetaRes } from "components/content/policy/oniDbRes/metaData/MetaAM";
import { getCodeRes } from "components/content/policy/oniDbRes/dbCode/CodeAM";
import { parseDbNodes } from "components/content/policy/oniDbRes/metaData/ParseData";
import { parseCodeTreeNodes } from "components/content/policy/oniDbRes/dbCode/ParseCodeList";
import { getMetaChildList } from "components/content/policy/oniDbRes/metaData/MetaAM";
import { getUserId, conversion, getUserName } from "lib/LocalStorageUtils";
import _ from "lodash";
import DropDownService from "components/common/DropDownService";
import { SERVICE_LIST } from "constants/CommonConstants";

const INFO_MODE = "info";
const EDIT_MODE = "edit";

class PolicyDetail extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      mode: INFO_MODE,
      isDetach: false,
      initUserList: [],
      policyNameEdit: "",
      policyDescEdit: "",
      initStatement: {},
      initJson: {}
    };
    const me = this;
    window.addEventListener("popstate", function(e) {
      me.onBackForward(e);
    });
    window.addEventListener("load", function(e) {
      me.onBackForward(e);
    });
  }

  async componentDidUpdate(previousProps) {
    // on policy change
    if (this.props.selectedPolicy.id !== previousProps.selectedPolicy.id) {
      if (previousProps.selectedPolicy.id) {
        this.setState({
          mode: INFO_MODE,
          selectedService: "",
        });
      }
      await this.getDbData();
      this.setPolicyResource();
    }
    if (this.props.groupEdit === "editPolicyUser") {
      this.props.editGroup("");
      let isDetach = false;
      this.props.policyEditUser.forEach(user => {
        if (user.isChecked) isDetach = true;
      });
      this.setState({
        isDetach
      });
    }
  }

  componentDidMount = async () => {
    this.mounted = true;
    await this.getDbData();
    await this.getUserList();
    // on first time load by click policy
    if (this.props.selectedPolicy.id) {
      this.setPolicyResource();
    }
    // go to policy by click link from other tab
    if (this.props.groupEdit === "goToPolicy") {
      this.props.editGroup("");
      const policyId = this.getSelectedPolicy();
      if (policyId !== "undefined") {
        await this.getPolicyInfo(policyId);
      }
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  onBackForward = async () => {
    if (window.location.pathname !== POLICY_ADD_PATH) {
      const policyId = this.getSelectedPolicy();
      await this.getDbData();
      if (policyId !== "undefined") {
        await this.getPolicyInfo(policyId);
      }
    } else {
      this.props.selectPolicy({});
    }
  };

  setPolicyResource = async () => {
    if (this.mounted) {
      await this.getUserList();
      let initUserList = [];
      const useStatus = this.props.selectedPolicy.use_status;
      if (useStatus) {
        useStatus.forEach(user => {
          if (!user.type) {
            // type user
            const userName = this.getUserNameId(user.id);
            user.name = userName;
          }
          user.isChecked = false;
        });
        useStatus.sort(function(a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        initUserList = _.cloneDeep(useStatus);
        this.props.setPolicyUser(useStatus);
      }

      this.setState({
        initResource: this.props.selectedPolicy.overview,
        initUserList: initUserList,
        policyNameEdit: this.props.selectedPolicy.policy_name,
        policyDescEdit: this.props.selectedPolicy.description
      });
      this.initResource();
    }
  };

  getDbData = async () => {
    let { metaNodes, codeNodes, groupData } = this.props;
    // store original meta structure
    if (
      this.mounted &&
      (!localStorage.getItem(DB_STRUCTURE) || _.isEmpty(metaNodes))
    ) {
      const dbStructure = await this.getMetaStructure();
      localStorage.setItem(DB_STRUCTURE, JSON.stringify(dbStructure));
      metaNodes = parseDbNodes(dbStructure);
      this.props.storeDbStructure(metaNodes);
    }

    // store original code structure
    if (
      this.mounted &&
      (!localStorage.getItem(CODE_LIST) || _.isEmpty(codeNodes))
    ) {
      const codeList = await this.getDbCodeList();
      localStorage.setItem(CODE_LIST, JSON.stringify(codeList));
      codeNodes = parseCodeTreeNodes(codeList);
      this.props.storeCodeStructure(codeNodes);
    }

    // store original group structure
    if (
      this.mounted &&
      (!localStorage.getItem(DB_GROUP) || !groupData.groupList.length)
    ) {
      const groupList = await this.getDbGroupList();
      localStorage.setItem(DB_GROUP, JSON.stringify(groupList));
      this.props.setDbGroupList(groupList);
    }
  };

  getSelectedPolicy = () => {
    if (this.mounted) {
      const splitPath = window.location.pathname.split("/");
      if (splitPath[4] === EDIT_MODE) {
        this.setState({
          mode: EDIT_MODE
        });
      } else
        this.setState({
          mode: INFO_MODE
        });
      this.props.setPolicyRoute(window.location.pathname);
      return decodeURI(splitPath[3]);
    }
  };

  getPolicyInfo = async policyId => {
    if (this.mounted) {
      this.runProgress();
      const query = {
        policy_id: policyId
      };
      const response = await this.executeApi(
        ApiConstants.GET_POLICY,
        null,
        query,
        null
      );
      const policy = response.success ? response.data : {};
      if (policy.policy_name) {
        policy.id = policyId;
        this.props.selectPolicy(policy);
        this.setPolicyResource();
      }
      this.stopProgress();
    }
  };

  getUserList = async () => {
    if (!localStorage.getItem(LIST_USERS)) {
      await this.getUsers().then(users => {
        const converted = conversion(users);
        localStorage.setItem(LIST_USERS, JSON.stringify(converted));
      });
    }
  };

  getPolicyList = async () => {
    const response = await this.executeApi(ApiConstants.GET_POLICIES);
    return response.success ? response.data : [];
  };

  getMetaStructure = async () => {
    const response = await this.executeApi(ApiConstants.GET_DB_STRUCTURE);
    return response.success ? response.data : [];
  };

  getDbCodeList = async () => {
    let codeList = [];
    const response = await this.executeApi(ApiConstants.GET_CODE);
    if (response.success) {
      codeList = response.data.code_definition_info;
    }
    return codeList;
  };

  getDbGroupList = async () => {
    const response = await this.executeApi(ApiConstants.GET_DB_GROUP);
    return response.success ? response.data.group_list : [];
  };

  isResEqual = (res1, res2) => {
    let isEqual = false;
    let resArray1 = res1.Statement;
    let resArray2 = res2.Statement;
    if (resArray1.length === resArray2.length) {
      if (resArray1.length === 0) isEqual = true;
      else {
        resArray1.sort((a, b) => a.Resource.localeCompare(b.Resource));
        resArray2.sort((a, b) => a.Resource.localeCompare(b.Resource));
        resArray1.forEach(res => res.Action.sort((a, b) => a.localeCompare(b)));
        resArray2.forEach(res => res.Action.sort((a, b) => a.localeCompare(b)));
        if (JSON.stringify(resArray1) === JSON.stringify(resArray2))
          isEqual = true;
      }
    }
    return isEqual;
  };

  isAttachEqual = (attach1, attach2) => {
    let isEqual = false;
    if (attach1.length === attach2.length) {
      if (attach1.length === 0) isEqual = true;
      else {
        isEqual = true;
        attach1.sort((a, b) => a.id.localeCompare(b.id));
        attach2.sort((a, b) => a.id.localeCompare(b.id));
        attach1.forEach((item, index) => {
          if (item.id !== attach2[index].id) isEqual = false;
        });
      }
    }
    return isEqual;
  };

  onClickInfo = policyId => {
    const mode = this.state.mode;
    if (mode !== INFO_MODE) {
      this.setState({
        mode: INFO_MODE
      });
      const route = `${POLICY_DETAIL_PATH}/${policyId}/info`;
      hist.push(decodeURI(route));
      this.props.setPolicyRoute(decodeURI(route));
    }
  };

  onClickEdit = () => {
    const mode = this.state.mode;
    if (mode !== EDIT_MODE) {
      this.setState({
        mode: EDIT_MODE
      });
      const route = window.location.pathname.replace(INFO_MODE, EDIT_MODE);
      this.props.setPolicyRoute(decodeURI(route));
      hist.push(decodeURI(route));
    }
  };

  openModal = params => {
    this.props.openModal(params[0], params[1]);
  };

  onCancel = () => {
    const { selectedPolicy } = this.props;
    if (this.refs.policyNameRef)
      this.refs.policyNameRef.value = selectedPolicy.policy_name;
    if (this.refs.policyDescRef)
      this.refs.policyDescRef.value = selectedPolicy.description;
    this.props.editGroup("cancelEdit");
    this.initResource(true);
  };

  onSave = () => {
    const { policyNameEdit, policyDescEdit, initUserList } = this.state;
    const {
      selectedPolicy,
      policyEditUser,
      metaData,
      codeRes,
      groupRes,
      topicRes,
      companyRes,
      tagRes,
      dbJson,
      systemRes
    } = this.props;

    let statements = [];
    let attachList = [];
    const deleteList = [];
    const origName = selectedPolicy.policy_name;
    const origDesc = selectedPolicy.description;
    const origOverview = JSON.parse(selectedPolicy.overview);

    // get meta resoure access permission
    statements = getMetaRes(metaData.dbNodes);
    // get code resoure access permission
    if (codeRes) {
      statements = statements.concat(codeRes);
    }
    // get group resoure access permission
    if (groupRes && groupRes.isManage) {
      statements.push({
        Action: ["read", "update", "create", "delete"],
        Resource: "orn:onigiri:db:group"
      });
    }
    // get topic resoure access permission
    if (topicRes && (topicRes.isDeleteTopic || topicRes.isDeleteCmt)) {
      const topicAction = [];
      if (topicRes.isDeleteTopic) topicAction.push("deleteTopic");
      if (topicRes.isDeleteCmt) topicAction.push("deleteCmt");
      const topicAccess = {
        Action: topicAction,
        Resource: "orn:onigiri:db:topic"
      };
      statements.push(topicAccess);
    }
    // get company resoure access permission
    if (companyRes && (companyRes.isCreate || companyRes.isDelete)) {
      const companyAction = [];
      if (companyRes.isDelete) companyAction.push("delete");
      if (companyRes.isCreate) companyAction.push("create");
      const companyAccess = {
        Action: companyAction,
        Resource: "orn:onigiri:db:company"
      };
      statements.push(companyAccess);
    }
    // get tag resoure access permission
    if (tagRes && (tagRes.isCreate || tagRes.isDelete)) {
      const tagAction = [];
      if (tagRes.isDelete) tagAction.push("delete");
      if (tagRes.isCreate) tagAction.push("create");
      const tagAccess = {
        Action: tagAction,
        Resource: "orn:onigiri:db:tag"
      };
      statements.push(tagAccess);
    }
    // get ONIGIRI-AM system permission
    if (systemRes && systemRes.isLogin) {
      statements.push({
        Action: ["allow"],
        Resource: "orn:onigiri:am:login"
      });
    }

    // 権限が１つも設定されていない場合は入力エラーとする
    if (!statements.length) {
      this.showDialog({
        message: MsgConstants.POLICY_STATEMENT_ERROR
      });
      return;
    }

    const overViewObject = {
      Statement: statements
    };

    // get attach list
    policyEditUser.forEach(user => {
      let userObj = {
        type: user.type,
        id: user.id
      };
      // if type is group
      if (user.type) {
        userObj.name = user.name;
        const groupList = JSON.parse(localStorage.getItem(GROUP_LIST));
        if (groupList.filter(e => e.group_id === Number(user.id)).length) {
          attachList.push(userObj);
        } else {
          deleteList.push(user.name);
        }
      }
      // if type is user
      else {
        const users = JSON.parse(localStorage.getItem(LIST_USERS));
        if (users.filter(e => e.id === user.id).length) {
          attachList.push(userObj);
        } else {
          deleteList.push(user.name);
        }
      }
    });

    // check user/group valid
    if (deleteList.length) {
      this.showDialog({
        message: StringFormat.format(MsgConstants.POLICY_ATTACH_NOT_EXIST, [deleteList.join(",")])
      });
      return;
    }

    // check name empty
    if (!policyNameEdit.trim()) {
      this.showDialog({ message: MsgConstants.POLICY_NAME_NOT_DEFINE });
      return;
    }

    // Check name valid
    if (!StringFormat.isValidName(policyNameEdit.trim())) {
      this.showDialog({ message: MsgConstants.POLICY_NAME_INVALID });
      return;
    }

    // check JSON error
    if (dbJson.isError) {
      this.showDialog({ message: MsgConstants.JSON_HAS_ERROR });
      return;
    }

    // check input no change
    if (
      policyNameEdit.trim() === origName &&
      policyDescEdit.trim() === origDesc &&
      this.isAttachEqual(initUserList, attachList) &&
      this.isResEqual(origOverview, overViewObject)
    ) {
      this.showDialog({ message: MsgConstants.INPUT_NO_CHANGE });
      return;
    }
    const requestParam = {
      valid_flag: true,
      policy_id: selectedPolicy.id,
      policy_name: policyNameEdit.trim(),
      description: policyDescEdit.trim(),
      overview: JSON.stringify(overViewObject),
      attach_list: attachList,
      update_time: selectedPolicy.update_time
    };

    this.showDialog({
      message: StringFormat.format(MsgConstants.UPDATE_CONFIRM, [
        selectedPolicy.policy_name
      ]),
      confirm: true,
      positive: async () => {
        const response = await this.executeApi(
          ApiConstants.POST_POLICY,
          null,
          null,
          requestParam
        );
        if (response.success) {
          this.showToast(
            StringFormat.format(MsgConstants.EDIT_SUCCESS, ["ポリシー"])
          );
          // init select service
          this.setState({
            selectedService: "",
          });
          // update　policy list, selection policy and go to info mode
          const updateList = await this.getPolicyList();
          this.props.setPolicyList(updateList.policy_list);
          await this.getPolicyInfo(selectedPolicy.id);
          this.onClickInfo(selectedPolicy.id);
        }
      }
    });
  };

  onDelete = policy => {
    const requestParam = {
      policy_id: policy.id,
      valid_flag: false
    };

    this.showDialog({
      message: StringFormat.format(MsgConstants.DELETE_CONFIRM, [
        policy.policy_name
      ]),
      confirm: true,
      positive: async () => {
        const response = await this.executeApi(
          ApiConstants.POST_POLICY,
          null,
          null,
          requestParam
        );
        if (response.success) {
          this.showToast(MsgConstants.POLICY_DELETE_SUCCESS);
          //update policy list
          this.props.selectPolicy({});
          hist.push(POLICY_PATH);
          this.props.setPolicyRoute(POLICY_PATH);
          this.props.editGroup("deletePolicy");
        }
      }
    });
  };

  onNameChange = e => {
    const { value } = e.target;
    this.setState({
      policyNameEdit: value
    });
  };

  onDescChange = e => {
    const { value } = e.target;
    this.setState({
      policyDescEdit: value
    });
  };

  goToUser = userName => {
    const userId = getUserId(userName);
    const route = `${USER_DETAIL_PATH}/${userId}/info`;
    this.props.setUserRoute(decodeURI(route));
    this.props.showUser();
    hist.push(decodeURI(route));
    localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.USER);
  };

  goToUserGroup = user => {
    // go to user
    if (user.type === 0) {
      const route = `${USER_DETAIL_PATH}/${user.id}/info`;
      this.props.setUserRoute(decodeURI(route));
      this.props.showUser();
      hist.push(decodeURI(route));
      localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.USER);
    }
    // go to group
    if (user.type === 1) {
      const route = `${GROUP_DETAIL_PATH}/${user.name}/info`;
      this.props.setGroupRoute(decodeURI(route));
      this.props.showGroup();
      hist.push(decodeURI(route));
      localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.GROUP);
    }
  };

  getAccessLevel = actions => {
    let accessLevel = "";
    actions.forEach(action => {
      switch (action) {
        case "read":
          accessLevel = accessLevel + "参照, ";
          break;
        case "update":
          accessLevel = accessLevel + "更新, ";
          break;
        case "create":
          accessLevel = accessLevel + "作成, ";
          break;
        case "delete":
          accessLevel = accessLevel + "削除, ";
          break;
        case "deleteTopic":
          accessLevel = accessLevel
            ? accessLevel + " トピック, "
            : "削除: トピック, ";
          break;
        case "deleteCmt":
          accessLevel = accessLevel
            ? accessLevel + " コメント, "
            : "削除: コメント, ";
          break;
        case "allow":
          accessLevel = accessLevel + "許可, ";
          break;
        default:
          break;
      }
    });
    return accessLevel ? accessLevel.substring(0, accessLevel.length - 2) : "";
  };

  onDetach = () => {
    const { policyEditUser } = this.props;
    const detachList = policyEditUser.filter(user => !user.isChecked);
    this.props.setPolicyUser(detachList);
    this.setState({
      isDetach: false
    });
  };

  initResource = (isCancel = false) => {
    const { selectedPolicy, metaData, codeData } = this.props;
    let accessRes = [];
    let statements = [];
    if (selectedPolicy.id)
      accessRes = JSON.parse(selectedPolicy.overview).Statement;

    const metaRes = accessRes.filter(res => res.Resource.includes("db:meta"));
    const codeRes = accessRes.filter(res => res.Resource.includes("db:code"));
    const groupRes = accessRes.filter(res => res.Resource.includes("db:group"));
    const topicRes = accessRes.filter(res =>
      res.Resource.includes("db:topic")
    )[0];
    const companyRes = accessRes.filter(res =>
      res.Resource.includes("db:company")
    )[0];
    const tagRes = accessRes.filter(res =>
      res.Resource.includes("db:tag")
    )[0];
    const systemRes = accessRes.filter(res => res.Resource.includes("am:login"));

    const initMeta = this.getMetaResource(metaRes);
    const initCode = this.getCodeResource(codeRes);
    const initGroup = this.getGroupResource(groupRes);
    const initTopic = this.getTopicResource(topicRes);
    const initCompany = this.getCompanyResource(companyRes);
    const initTag = this.getTagResource(tagRes);
    const initSystem = this.getSystemResource(systemRes);

    // // get meta resoure access permission
    // statements = getMetaRes(initMeta);
    // // get code resoure access permission
    // statements = statements.concat(
    //   getCodeRes(initCode.codeNodes, initCode.isCreate)
    // );
    // // get group resoure access permission
    // if (groupRes && groupRes.isManage) {
    //   statements.push({
    //     Action: ["read", "update", "create", "delete"],
    //     Resource: "orn:onigiri:db:group"
    //   });
    // }
    // // get topic resoure access permission
    // if (initTopic.isDeleteTopic || initTopic.isDeleteCmt) {
    //   const topicAction = [];
    //   if (initTopic.isDeleteTopic) topicAction.push("deleteTopic");
    //   if (initTopic.isDeleteCmt) topicAction.push("deleteCmt");
    //   const topicAccess = {
    //     Action: topicAction,
    //     Resource: "orn:onigiri:db:topic"
    //   };
    //   statements.push(topicAccess);
    // }
    // // get company resoure access permission
    // if (initCompany.isCreate || initCompany.isDelete) {
    //   const companyAction = [];
    //   if (initCompany.isDelete) companyAction.push("delete");
    //   if (initCompany.isCreate) companyAction.push("create");
    //   const companyAccess = {
    //     Action: companyAction,
    //     Resource: "orn:onigiri:db:company"
    //   };
    //   statements.push(companyAccess);
    // }
    // // get tag resoure access permission
    // if (initTag.isCreate || initTag.isDelete) {
    //   const tagAction = [];
    //   if (initTag.isDelete) tagAction.push("delete");
    //   if (initTag.isCreate) tagAction.push("create");
    //   const tagAccess = {
    //     Action: tagAction,
    //     Resource: "orn:onigiri:db:tag"
    //   };
    //   statements.push(tagAccess);
    // }
    const initJson = {
      Statement: statements
    };

    // store init state
    const initStatement = {
      metaState: metaRes,
      codeState: codeRes,
      groupState: groupRes,
      topicState: topicRes,
      companyState: companyRes,
      tagState: tagRes
    };
    this.setState({
      initStatement: initStatement,
      initJson: initJson
    });

    // set init data
    let dbNodes = _.cloneDeep(initMeta);
    let codeNodes = _.cloneDeep(initCode.codeNodes);
    if (isCancel) {
      const dbOpenNodes = metaData.openNodes;
      const codeOpenNodes = codeData.openNodes;
      dbOpenNodes.forEach(node => (dbNodes[node].isOpen = true));
      codeOpenNodes.forEach(node => (codeNodes[node].isOpen = true));
    }
    this.props.setDbJson(initJson);
    this.props.setDbNodes(dbNodes);
    this.props.isCreateCode(_.cloneDeep(initCode.isCreate));
    this.props.setCodeNodes(_.cloneDeep(codeNodes));
    this.props.setGroupRes(_.cloneDeep(initGroup));
    this.props.setCodeRes(_.cloneDeep(initCode));
    this.props.setTopicRes(_.cloneDeep(initTopic));
    this.props.setCompanyRes(_.cloneDeep(initCompany));
    this.props.setTagRes(_.cloneDeep(initTag));
    this.props.setSystemRes(_.cloneDeep(initSystem));

    // set init keyword
    if (!isCancel) {
      localStorage.setItem(DB_META_SEARCH_KEY, "");
      localStorage.setItem(DB_CODE_SEARCH_KEY, "");
      localStorage.setItem(DB_GROUP_SEARCH_KEY, "");
    }
  };

  getMetaResource = accessRes => {
    const { metaNodes } = this.props;
    let dbNodes = _.cloneDeep(metaNodes);
    accessRes.forEach(res => {
      let resNodes = [];
      let path = res.Resource.replace("\\", "|").slice(20);
      if (path.includes("*")) path = path.slice(0, -2);
      if (res.Resource.includes("*")) {
        resNodes = getMetaChildList(dbNodes[path], dbNodes);
      }
      if (dbNodes[path]) resNodes.push(dbNodes[path]);
      resNodes.forEach(node => {
        if (res.Action.includes("read")) {
          dbNodes[node.path].isRead = true;
        }
        if (res.Action.includes("update")) {
          dbNodes[node.path].isWrite = true;
        }
      });
    });
    return dbNodes;
  };

  getCodeResource = accessRes => {
    return accessRes;
  };

  getGroupResource = accessRes => {
    let isManage = false;
    accessRes.forEach(res => {
      if (res.Action.includes("read") && res.Action.includes("update") && res.Action.includes("create") && res.Action.includes("delete")) {
        isManage = true;
      }
    });
    return {
      isManage: isManage
    }
  };

  getTopicResource = accessRes => {
    const permision = { isDeleteTopic: false, isDeleteCmt: false };
    if (accessRes) {
      if (accessRes.Action.includes("deleteTopic")) {
        permision.isDeleteTopic = true;
      }
      if (accessRes.Action.includes("deleteCmt")) {
        permision.isDeleteCmt = true;
      }
    }
    return permision;
  };

  getCompanyResource = accessRes => {
    const permision = {
      isCreate: false,
      isDelete: false
    };
    if (accessRes) {
      if (accessRes.Action.includes("create")) {
        permision.isCreate = true;
      }
      if (accessRes.Action.includes("delete")) {
        permision.isDelete = true;
      }
    }
    return permision;
  };

  getTagResource = accessRes => {
    const permision = {
      isCreate: false,
      isDelete: false
    };
    if (accessRes) {
      if (accessRes.Action.includes("create")) {
        permision.isCreate = true;
      }
      if (accessRes.Action.includes("delete")) {
        permision.isDelete = true;
      }
    }
    return permision;
  };

  getSystemResource = accessRes => {
    let isLogin = false;
    accessRes.forEach(res => {
      if (res.Action.includes("allow")) {
        isLogin = true;
      }
    });
    return {
      isLogin: isLogin
    }
  }

  onSelectService = service => {
    this.setState({
      selectedService: service
    });
  };

  getServiceNameFromResource = resource => {
    const service = resource.split(":")[2];
    return service ? service.toUpperCase() : "";
  }

  render() {
    const { mode, isDetach, initJson, initStatement, selectedService } = this.state;
    const { selectedPolicy, policyEditUser } = this.props;
    let accessRes = [];
    if (selectedPolicy.id)
      accessRes = JSON.parse(selectedPolicy.overview).Statement;
    return (
      <div className="container-fluid m-0 p-0 w-100">
        <div
          id="headMenu"
          className="row position-static flex-nowrap m-0 p-2 text-white "
        >
          <div id="tabMenu" className="mr-2">
            <ul className="nav d-flex flex-nowrap">
              <li
                className="nav-item detail-header"
                onClick={this.onClickInfo.bind(this, selectedPolicy.id)}
              >
                <a
                  className={
                    mode === INFO_MODE ? "nav-link active" : "nav-link"
                  }
                  href="#definition-dsp"
                  data-target="#definition-dsp"
                  data-toggle="tab"
                  id="sample-btn-dsp"
                >
                  通常
                </a>
              </li>
              <li className="nav-item detail-header" onClick={this.onClickEdit}>
                <a
                  className={
                    mode === EDIT_MODE ? "nav-link active" : "nav-link"
                  }
                  href="#definition-edt"
                  data-target="#definition-edt"
                  data-toggle="tab"
                  id="sample-btn-edt"
                >
                  編集
                </a>
              </li>
            </ul>
          </div>
          {mode === EDIT_MODE && (
            <div className="ml-auto">
              <button
                type="button"
                className="btn btn-warning rounded-1"
                onClick={this.onDelete.bind(this, selectedPolicy)}
              >
                ポリシーの削除
              </button>
            </div>
          )}
        </div>
        <div
          id="detail-margin"
          className={this.setDetailStyle(this.props.isLvExpand)}
        >
          <div className="d-flex mb-3 pr-3">
            <div className="d-flex">
              <span className="align-self-center adm-detail-main-title">
                {mode === EDIT_MODE ? "ポリシー編集" : "ポリシー情報"}
              </span>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-2 custom-border-bottom">
              <span className="key">ポリシー名</span>
            </div>
            <div className="pl-2 col custom-border-bottom">
              {mode === EDIT_MODE ? (
                <input
                  type=""
                  className="custom-input"
                  ref="policyNameRef"
                  defaultValue={selectedPolicy.policy_name}
                  onChange={this.onNameChange}
                />
              ) : (
                <span className="value text-wrap break-text">
                  {selectedPolicy.policy_name}
                </span>
              )}
            </div>
          </div>
            <div className="row no-gutters">
              <div className="col-2 custom-border-bottom">
                <span className="key">説明</span>
              </div>
              {mode === INFO_MODE && (
                <div className="col pl-2 custom-border-bottom">
                  <span className="policy-description">
                    {selectedPolicy.description}
                  </span>
                </div>
              )}
              {mode === EDIT_MODE && (
                <div className="pl-2 col custom-border-bottom mt-2">
                  <textarea
                    className="custom-input"
                    rows={3}
                    name="comment"
                    defaultValue={selectedPolicy.description}
                    onChange={this.onDescChange}
                    ref="policyDescRef"
                    style={{
                      overflow: "auto",
                      paddingBottom: "3px",
                      resize: "none"
                    }}
                  />
                </div>
              )}
          </div>
          {mode === INFO_MODE && (
            <div className="pb-3">
              <div className="row no-gutters">
                <div className="key col-2 detail-group-sub-title">概要</div>
                <div className="col">
                  <div className="row mdl-grid mdl-list-policy-res custom-border-bottom">
                    <span className="value pl-2">サービス</span>
                    <span className="value pl-2">アクセスレベル</span>
                    <span className="value pl-2">リソース</span>
                  </div>
                  <div className="mdl-list-outer-policy-res">
                    {accessRes.map((res, index) => (
                      <div
                        key={index}
                        className="row mdl-grid mdl-list-policy-res custom-border-bottom"
                      >
                        <span className="key font-weight-normal pl-2">{this.getServiceNameFromResource(res.Resource)}</span>
                        <span className="key font-weight-normal pl-2">
                          {this.getAccessLevel(res.Action)}
                        </span>
                        <span className="key font-weight-normal pl-2">{res.Resource}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {mode === INFO_MODE && (
            <div className="row no-gutters">
              <div className="col custom-border-bottom"></div>
            </div>
          )}
          <div className="pb-3">
            {mode === INFO_MODE && (
              <div className="row no-gutters">
                <div className="key col-2 detail-group-sub-title">使用状況</div>
                <div className="col">
                  <div className="mdl-list-user-header">
                    <div className="mdl-grid mdl-list-user">
                      <span className="value pl-2">名前</span>
                      <span className="value">タイプ</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {mode === EDIT_MODE && (
              <div>
                <div className="row no-gutters">
                  <div className="key col-2 detail-group-sub-title">使用状況</div>
                  <div className="col">
                    <div className="mdl-list-user-header">
                      <div className="mdl-grid mdl-list-user-edit ml-2 mt-2">
                        <div
                          className="btn btn-positive rounded-1"
                          onClick={this.openModal.bind(this, [
                            MODAL_TYPE.USER_GROUP,
                            policyEditUser
                          ])}
                        >
                          アタッチ
                        </div>
                        <div />
                        {!isDetach ? (
                          <div className="btn btn-detach disabled no-hover rounded-1">
                            デタッチ
                          </div>
                        ) : (
                          <div
                            className="btn btn-detach rounded-1"
                            onClick={this.onDetach}
                          >
                            デタッチ
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-2" />
                  <div className="col">
                    <div className="mdl-list-user-header">
                      <div className="mdl-grid mdl-list-user">
                        <span className="value pl-2">名前</span>
                        <span className="value">タイプ</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {mode === INFO_MODE ? (
              <div className="mdl-list-outer-group-user">
                {policyEditUser.map((user, index) => (
                  <div className="row no-gutters" key={index}>
                    <div className="col-2" />
                    <div className="col">
                      <div className="mdl-grid mdl-list-user">
                        <span
                          className="key text-primary text-wrap pointer pl-2"
                          onClick={this.goToUserGroup.bind(this, user)}
                        >
                          {user.name}
                        </span>
                        <span className="value">
                          {user.type ? "グループ" : "ユーザー"}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <PolicyEditUser mode="edit" />
            )}
          </div>
          <div className="row no-gutters">
            <div className="col custom-border-bottom"></div>
          </div>
          {mode === INFO_MODE && (
            <div className="pb-3">
              <div className="row no-gutters">
                <div className="key col-2 detail-group-sub-title">作成者</div>
                <div
                  className="col pl-2 text-primary pointer"
                  onClick={this.goToUser.bind(this, selectedPolicy.create_user)}
                >
                  {getUserName(selectedPolicy.create_user)}
                </div>
              </div>
            </div>
          )}
          {mode === EDIT_MODE && (
            <div>
              <div className="pb-3">
                <div className="row no-gutters">
                  <div className="col-2 detail-policy-sub-title key">
                    サービス
                  </div>
                  <div className="col mt-2">
                    <DropDownService
                      data={Object.values(SERVICE_LIST)}
                      onChange={this.onSelectService}
                      selected={selectedService}
                    />
                  </div>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col custom-border-bottom"></div>
              </div>
              {selectedService === SERVICE_LIST.ONIGIRIAM.name && (
                <AMPolicy />
              )}
              {selectedService === SERVICE_LIST.ONIGIRIDB.name && (
                <DBPolicy
                  mode="editPolicy"
                  initJson={initJson}
                  initStatement={initStatement}
                />
              )}
            </div>
          )}
           <div
            className="py-3"
            style={{
              display: mode === EDIT_MODE ? "block" : "none"
            }}
          >
            <EditAction
              onCancelHandler={this.onCancel}
              onSaveHandler={this.onSave}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLvExpand: state.lvExpandState,
    selectedPolicy: state.selectedPolicy,
    policyEditUser: state.policyEditUser,
    metaData: state.dbMetaData,
    codeData: state.dbCodeData,
    groupData: state.dbGroupList,
    metaNodes: state.dbMetaNodes,
    codeNodes: state.dbCodeNodes,
    codeRes: state.dbCodeRes,
    groupRes: state.dbCommonRes.group,
    topicRes: state.dbCommonRes.topic,
    companyRes: state.dbCommonRes.company,
    tagRes: state.dbCommonRes.tag,
    groupEdit: state.groupEdit,
    dbJson: state.dbJson,
    systemRes: state.systemRes,
  };
};

const mapDispatchToProbs = dispatch => {
  return {
    setPolicyRoute: route => {
      dispatch(actions.setPolicyRoute(route));
    },
    selectPolicy: policy => {
      dispatch(actions.selectPolicy(policy));
    },
    setPolicyUser: user => {
      dispatch(actions.setPolicyUser(user));
    },
    setPolicyList: policyList => {
      dispatch(actions.setPolicyList(policyList));
    },
    openModal: (modal, data) => {
      dispatch(actions.openModal(modal, data));
    },
    editGroup: action => {
      dispatch(actions.editGroup(action));
    },
    setGroupRoute: route => {
      dispatch(actions.setGroupRoute(route));
    },
    setUserRoute: route => {
      dispatch(actions.setUserRoute(route));
    },
    storeDbStructure: nodes => {
      dispatch(actions.storeDbStructure(nodes));
    },
    storeCodeStructure: nodes => {
      dispatch(actions.storeCodeStruture(nodes));
    },
    setDbGroupList: list => {
      dispatch(actions.setDbGroupList(list));
    },
    showGroup: () => {
      dispatch(actions.showGroup());
    },
    showUser: () => {
      dispatch(actions.showUser());
    },
    setDbNodes: nodes => {
      dispatch(actions.setDbNodes(nodes));
    },
    setCodeNodes: nodes => {
      dispatch(actions.setCodeNodes(nodes));
    },
    isCreateCode: isCreate => {
      dispatch(actions.isCreateCode(isCreate));
    },
    setGroupRes: group => {
      dispatch(actions.setGroupRes(group));
    },
    setTopicRes: topic => {
      dispatch(actions.setTopicRes(topic));
    },
    setCompanyRes: company => {
      dispatch(actions.setCompanyRes(company));
    },
    setTagRes: tag => {
      dispatch(actions.setTagRes(tag));
    },
    setDbJson: json => {
      dispatch(actions.setDbJson(json));
    },
    setCodeRes: code => {
      dispatch(actions.setCodeRes(code));
    },
    setSystemRes: system => {
      dispatch(actions.setSystemRes(system));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(PolicyDetail);
