import React from "react";
import BaseComponent from "components/common/BaseComponent";
import * as actions from "actions/Actions";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import * as RoutePath from "constants/RoutePath";

class SettingList extends BaseComponent {
  onClickAppHandler = () => {
    let route =
      RoutePath.SETTING_PATH + "/" + RoutePath.SETTING_TYPE.SETTING_GENERAL;
    this.props.setRoute(route);
  };

  render() {
    return (
      <div id="lv-setting" className="scrollbar scrollbar-primary">
        <div className="force-overflow">
          <NavLink
            to={
              RoutePath.SETTING_PATH +
              "/" +
              RoutePath.SETTING_TYPE.SETTING_GENERAL
            }
            className="database-link row lv-row-item no-gutters p-2 align-items-center"
            onClick={this.onClickAppHandler}
          >
            <div className="icon-size-24 mr-2 lv-setting-general" />
            <span>全般</span>
          </NavLink>
        </div>
      </div>
    );
  }
}

SettingList.propTypes = {};

const mapDispatchToProbs = dispatch => {
  return {
    setRoute: route => {
      dispatch(actions.setSettingRoute(route));
    }
  };
};

export default connect(
  null,
  mapDispatchToProbs
)(SettingList);
