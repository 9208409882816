import React from "react";
import BaseComponent from "components/common/BaseComponent";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import DeleteIcon from "images/ic_delete.svg";
import { POLICY_DETAIL_PATH } from "constants/RoutePath";
import { LISTVIEW_TYPE } from "constants/LocalStorage";
import * as ListViewTypes from "constants/ListViewTypes";
import { hist } from "App";

// Policy List
class UserEditPolicy extends BaseComponent {
  componentDidUpdate() {
    // on edit cancel
    if (this.props.groupEdit === "cancelEdit") {
      this.props.editGroup("");
      this.props.setUserPolicy(this.props.initList);
    }
  }

  removePolicyFromUser = id => {
    const { policyList } = this.props;
    const removeList = policyList.filter(policy => policy.policy_id !== id);
    this.props.setUserPolicy(removeList);
  };

  goToPolicy = policyId => {
    const route = `${POLICY_DETAIL_PATH}/${policyId}/info`;
    this.props.setPolicyRoute(decodeURI(route));
    this.props.showPolicy();
    this.props.editGroup("goToPolicy");
    hist.push(decodeURI(route));
    localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.POLICY);
  };

  render() {
    const { policyList } = this.props;
    return (
      <div className="mdl-list-outer-group-user">
        {policyList.map((policy, index) => (
          <div className="row no-gutters" key={index}>
            <div className="col-2 d-flex justify-content-end align-items-center">
              <img
                src={DeleteIcon}
                className="icon-size-15"
                alt="Delete"
                onClick={this.removePolicyFromUser.bind(this, policy.policy_id)}
              />
            </div>
            <div className="col">
              <div className="mdl-grid mdl-list-user">
                <span
                  className="key px-2 text-wrap text-primary break-text pointer"
                  onClick={this.goToPolicy.bind(this, policy.policy_id)}
                >
                  {policy.policy_name}
                </span>
                <span className="value text-wrap text-primary break-text">{policy.description}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    groupEdit: state.groupEdit,
    policyList: state.userEditPolicy
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUserPolicy: policy => {
      dispatch(actions.setUserPolicy(policy));
    },
    editGroup: action => {
      dispatch(actions.editGroup(action));
    },
    setPolicyRoute: route => {
      dispatch(actions.setPolicyRoute(route));
    },
    showPolicy: () => {
      dispatch(actions.showPolicy());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditPolicy);
