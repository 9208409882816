export const ROOT_PATH = "/";
export const LOGIN_PATH = "/login";
export const POLICY_PATH = "/policy";
export const POLICY_DETAIL_PATH = "/policy/detail";
export const POLICY_ADD_PATH = "/policy/add";
export const GROUP_PATH = "/group";
export const GROUP_DETAIL_PATH = "/group/detail";
export const GROUP_ADD_PATH = "/group/add";
export const USER_PATH = "/user";
export const USER_DETAIL_PATH = "/user/detail";
export const USER_ADD_PATH = "/user/add";
export const SETTING_PATH = "/setting";
export const CHANGE_PASSWORD_PATH = "/password";
export const DEFAUT_PATH = "/policy";
export const CODE_DETAIL_PATH = "/code/detail";
export const CODE_PATH = "/code";
export const CODE_ADD_PATH = "/code/add";

export const USER_CATEGORY = {
  ACCOUNT_INUSE: "available",
  ACCOUNT_STOP: "stop",
  ACCOUNT_UNAUTHORIZED: "unauthorized"
};

export const USER_TYPE = {
  USER_INFO: "info",
  USER_EDIT: "edit"
};

export const SETTING_TYPE = {
  SETTING_GENERAL: "general",
  SETTING_ADMIN: "admin"
};
