import React, { Component } from "react";
import { connect } from "react-redux";
import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import "components/content/policy/json/light-theme";
import { DB_GROUP } from "constants/LocalStorage";
import { getMetaChildList } from "components/content/policy/oniDbRes/metaData/MetaAM";
import { getCodeChildList } from "components/content/policy/oniDbRes/dbCode/CodeAM";
import _ from "lodash";
import * as actions from "actions/Actions";

class JsonEditor extends Component {
  componentDidMount() {
    const options = {
      theme: "components/content/policy/json/light-theme",
      mode: "code",
      mainMenuBar: false,
      statusBar: false,
      onChangeText: this.onChangeText
    };
    const { dbJson } = this.props;
    this.jsoneditor = new JSONEditor(this.container, options, dbJson.jsonObj);
  }

  componentWillUnmount() {
    if (this.jsoneditor) {
      this.jsoneditor.destroy();
    }
  }

  componentDidUpdate = previousProps => {
    // cancel edit
    if (this.props.groupEdit === "cancelEdit") {
      const { initData } = this.props;
      const initJson = _.cloneDeep(initData);
      this.props.editGroup("");
      this.jsoneditor.update(initJson);
      this.props.setDbJson(initJson);
    }
  };

  onChangeText = () => {
    const jsonString = this.jsoneditor.getText();
    let jsonObject;
    try {
      jsonObject = JSON.parse(jsonString);
    } catch (e) {
      this.props.isJsonError(true);
    }

    if (jsonObject) {
      this.props.isJsonError(false);
      const policyRes = jsonObject.Statement;
      const metaRes = policyRes.filter(res => res.Resource.includes("db:meta"));
      const codeRes = policyRes.filter(res => res.Resource.includes("db:code"));
      const groupRes = policyRes.filter(res =>
        res.Resource.includes("db:group")
      );
      const topicRes = policyRes.filter(res =>
        res.Resource.includes("db:topic")
      )[0];
      const companyRes = policyRes.filter(res =>
        res.Resource.includes("db:company")
      )[0];
      const tagRes = policyRes.filter(res =>
        res.Resource.includes("db:tag")
      )[0];
      this.setMetaResource(metaRes);
      this.setCodeResource(codeRes);
      this.setGroupResource(groupRes);
      this.setTopicResource(topicRes);
      this.setCompanyResource(companyRes);
      this.setTagResource(tagRes);
      this.props.setDbJson(jsonObject);
    }
  };

  setMetaResource = accessRes => {
    const { metaNodes } = this.props;
    let dbNodes = _.cloneDeep(metaNodes);
    accessRes.forEach(res => {
      let resNodes = [];
      let path = res.Resource.replace("\\", "|").slice(20);
      if (path.includes("*")) path = path.slice(0, -2);
      if (res.Resource.includes("*")) {
        resNodes = getMetaChildList(dbNodes[path], dbNodes);
      }
      resNodes.push(dbNodes[path]);
      resNodes.forEach(node => {
        if (res.Action.includes("read")) {
          dbNodes[node.path].isRead = true;
        }
        if (res.Action.includes("update")) {
          dbNodes[node.path].isWrite = true;
        }
      });
    });
    this.props.setDbNodes(dbNodes);
  };

  setCodeResource = accessRes => {
    const { codeNodes } = this.props;
    let oriCodeNodes = _.cloneDeep(codeNodes);
    let isCreate = false;
    accessRes.forEach(res => {
      let resNodes = [];
      let path = res.Resource.slice(20);
      if (path.includes("*")) path = path.slice(0, -2);
      if (res.Resource.includes("*")) {
        resNodes = getCodeChildList(oriCodeNodes[path], oriCodeNodes);
      }
      resNodes.push(oriCodeNodes[path]);
      resNodes.forEach(node => {
        if (res.Action.includes("read")) {
          oriCodeNodes[node.path].isRead = true;
        }
        if (res.Action.includes("update")) {
          oriCodeNodes[node.path].isWrite = true;
        }
        if (res.Action.includes("delete")) {
          oriCodeNodes[node.path].isDelete = true;
        }
        if (res.Action.includes("create")) {
          isCreate = true;
        }
      });
    });
    this.props.isCreateCode(isCreate);
    this.props.setCodeNodes(oriCodeNodes);
  };

  setGroupResource = accessRes => {
    let groupList = JSON.parse(localStorage.getItem(DB_GROUP));
    let isCreate = false;
    accessRes.forEach(res => {
      const resSplit = res.Resource.split("/");
      const resName = resSplit[resSplit.length - 1];
      if (res.Action.includes("read")) {
        const index = groupList.findIndex(e => e.group_name === resName);
        groupList[index].isRead = true;
      }
      if (res.Action.includes("update")) {
        const index = groupList.findIndex(e => e.group_name === resName);
        groupList[index].isWrite = true;
      }
      if (res.Action.includes("delete")) {
        const index = groupList.findIndex(e => e.group_name === resName);
        groupList[index].isDelete = true;
      }
      if (res.Action.includes("create")) {
        isCreate = true;
      }
    });
    this.props.setDbGroupList(groupList);
  };

  setTopicResource = accessRes => {
    const permision = { isDeleteTopic: false, isDeleteCmt: false };
    if (accessRes) {
      if (accessRes.Action.includes("deleteTopic")) {
        permision.isDeleteTopic = true;
      }
      if (accessRes.Action.includes("deleteCmt")) {
        permision.isDeleteCmt = true;
      }
    }
    this.props.setTopicRes(permision);
  };

  setCompanyResource = accessRes => {
    const permision = {
      isCreate: false,
      isDelete: false
    };
    if (accessRes) {
      if (accessRes.Action.includes("create")) {
        permision.isCreate = true;
      }
      if (accessRes.Action.includes("delete")) {
        permision.isDelete = true;
      }
    }
    this.props.setCompanyRes(permision);
  };

  setTagResource = accessRes => {
    const permision = {
      isCreate: false,
      isDelete: false
    };
    if (accessRes) {
      if (accessRes.Action.includes("create")) {
        permision.isCreate = true;
      }
      if (accessRes.Action.includes("delete")) {
        permision.isDelete = true;
      }
    }
    this.props.setTagRes(permision);
  };

  render() {
    return <div id="jsoneditor" ref={elem => (this.container = elem)} />;
  }
}

const mapStateToProps = state => {
  return {
    metaData: state.dbMetaData,
    metaNodes: state.dbMetaNodes,
    codeData: state.dbCodeData,
    codeNodes: state.dbCodeNodes,
    groupData: state.dbGroupList,
    topicRes: state.dbCommonRes.topic,
    companyRes: state.dbCommonRes.company,
    dbJson: state.dbJson,
    selectedPolicy: state.selectedPolicy,
    groupEdit: state.groupEdit
  };
};

const mapDispatchToProbs = dispatch => {
  return {
    setDbNodes: nodes => {
      dispatch(actions.setDbNodes(nodes));
    },
    setCodeNodes: nodes => {
      dispatch(actions.setCodeNodes(nodes));
    },
    isCreateCode: isCreate => {
      dispatch(actions.isCreateCode(isCreate));
    },
    setDbGroupList: list => {
      dispatch(actions.setDbGroupList(list));
    },
    setTopicRes: topic => {
      dispatch(actions.setTopicRes(topic));
    },
    setCompanyRes: company => {
      dispatch(actions.setCompanyRes(company));
    },
    setTagRes: tag => {
      dispatch(actions.setTagRes(tag));
    },
    setDbJson: json => {
      dispatch(actions.setDbJson(json));
    },
    isJsonError: isError => {
      dispatch(actions.isJsonError(isError));
    },
    editGroup: action => {
      dispatch(actions.editGroup(action));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(JsonEditor);
