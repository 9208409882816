import React from "react";
import BaseComponent from "components/common/BaseComponent";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import { Link } from "react-router-dom";
import { USER_DETAIL_PATH } from "../../../constants/RoutePath";
import * as DateFormat from "lib/DateFormat";
import { getTargetUser } from "lib/LocalStorageUtils";
import * as ApiConstants from "constants/ApiConstants";

const UserItemChild = props => {
  const { data, selectedUser, onClickUser } = props;
  const setColor = date => {
    let className = "";
    if (date) {
      const loginDate = new Date(date);
      const now = new Date().getTime();
      if (now < loginDate.setDate(loginDate.getDate() + 3)) {
        // login within 3 days
        className = "within-3-days";
      } else if (now < loginDate.setDate(loginDate.getDate() + 7)) {
        // login within 1 week
        className = "within-1-week";
      } else {
        // login at least a week ago
        className = "over-1-week";
      }
    }
    return className;
  };
  return (
    <div id="lv-admin">
      <ul className="list-group list-group-flush bg-transparent">
        {data.map((child, index) => (
          <Link
            to={`${USER_DETAIL_PATH}/${child.id}/info`}
            className="account-link lv-row-item"
            key={index}
          >
            <li
              className={
                child.id === selectedUser.id
                  ? "list-group-item active"
                  : "list-group-item"
              }
              onClick={() => onClickUser(child.id)}
            >
              <div className="row no-gutters">
                <div className="col-6 p-0">
                  <span className="lv-admin-name text-wrap break-text">{child.name}</span>
                </div>
                {child.date && (
                  <div className="col pr-0 lv-admin-date">
                    <span className={setColor(child.date)}>
                      {DateFormat.toString(
                        new Date(child.date),
                        "yyyy/MM/dd hh:mm:ss"
                      )}
                    </span>
                  </div>
                )}
              </div>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

class UserItem extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true
    };
  }

  onToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  setToogleIcon = () => {
    return this.state.isOpen ? "lv-group-arrow opened" : "lv-group-arrow";
  };

  onClickUser = async userId => {
    if (userId) {
      const user = getTargetUser(userId);
      const requestParam = {
        user_ids: userId
      };
      const response = await this.executeApi(
        ApiConstants.GET_USER,
        null,
        null,
        requestParam
      );
      user.policy_list = response.success ? response.data.user_list[0].policy_list : [];
      user.group_list = response.success ? response.data.user_list[0].group_list : [];
      this.props.selectUser(user);
      this.props.setUserGroup(user.group_list);
      this.props.setUserPolicy(user.policy_list);
      this.props.setUserRoute(`${USER_DETAIL_PATH}/${userId}/info`);
    }
  };

  render() {
    const { data, category, selectedUser } = this.props;
    return (
      <div>
        <div
          onClick={() => {
            this.onToggle();
          }}
        >
          <div className="lv-instance">
            <div className={this.setToogleIcon()}>
              <div className="account-link">
                <span className="lv-acc-name">{this.props.category}</span>
              </div>
            </div>
          </div>
        </div>
        {this.state.isOpen && (
          <UserItemChild
            data={data}
            category={category}
            selectedUser={selectedUser}
            onClickUser={this.onClickUser}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedUser: state.selectedUser
  };
};

const mapDispatchToProbs = dispatch => {
  return {
    setUserRoute: route => {
      dispatch(actions.setUserRoute(route));
    },
    selectUser: user => {
      dispatch(actions.selectUser(user));
    },
    setUserGroup: group => {
      dispatch(actions.setUserGroup(group));
    },
    setUserPolicy: policy => {
      dispatch(actions.setUserPolicy(policy));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(UserItem);
