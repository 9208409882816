import { combineReducers } from "redux";
import listViewType from "reducers/listviewType";
import listViewExpand from "reducers/listviewExpand";
import userState from "reducers/userState";
import loginState from "reducers/loginState";
import currentTheme from "reducers/currentTheme";
import isLoginExpire from "reducers/isLoginExpire";
import policyRoute from "reducers/policyRoute";
import groupRoute from "reducers/groupRoute";
import userRoute from "reducers/userRoute";
import settingRoute from "reducers/settingRoute";
import groupList from "reducers/groupList";
import groupSelection from "reducers/groupSelection";
import groupEdit from "reducers/groupEdit";
import groupEditUsers from "reducers/groupEditUsers";
import groupEditPolicy from "reducers/groupEditPolicy";
import selectModal from "reducers/selectModal";
import userList from "reducers/userList";
import userSelection from "reducers/userSelection";
import userEditGroup from "reducers/userEditGroup";
import userEditPolicy from "reducers/userEditPolicy";
import policyList from "reducers/policyList";
import policySelection from "reducers/policySelection";
import policyEditUser from "reducers/policyEditUser";
import dbGroupList from "reducers/dbGroupList";
import dbMetaData from "reducers/dbMetaData";
import dbMetaNodes from "reducers/dbMetaNodes";
import dbCodeData from "reducers/dbCodeData";
import dbCodeNodes from "reducers/dbCodeNodes";
import dbCodeRes from "reducers/dbCodeRes";
import dbCommonRes from "reducers/dbCommonRes";
import dbJson from "reducers/dbJson";
import systemRes from "reducers/systemRes";

const appReducer = combineReducers({
  listView: listViewType,
  lvExpandState: listViewExpand,
  userState: userState,
  loginState: loginState,
  currentTheme: currentTheme,
  policyRoute: policyRoute,
  groupRoute: groupRoute,
  userRoute: userRoute,
  settingRoute: settingRoute,
  isLoginExpire: isLoginExpire,
  policyList: policyList,
  selectedPolicy: policySelection,
  policyEditUser: policyEditUser,
  groupList: groupList,
  selectedGroup: groupSelection,
  groupEdit: groupEdit,
  groupEditUsers: groupEditUsers,
  groupEditPolicy: groupEditPolicy,
  selectModal: selectModal,
  userList: userList,
  selectedUser: userSelection,
  userEditGroup: userEditGroup,
  userEditPolicy: userEditPolicy,
  dbGroupList: dbGroupList,
  dbMetaData: dbMetaData,
  dbMetaNodes: dbMetaNodes,
  dbCodeData: dbCodeData,
  dbCodeNodes: dbCodeNodes,
  dbCodeRes: dbCodeRes,
  dbCommonRes: dbCommonRes,
  dbJson: dbJson,
  systemRes: systemRes,
});

export default appReducer;
