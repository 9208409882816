export const API_CONST = {
  NAME: "onigiri-am-web",
  GET: "GET",
  POST: "POST",
  SUCCESS: "OK"
};

// policy
export const GET_POLICIES = {
  method: API_CONST.GET,
  name: API_CONST.NAME,
  path: "/policies"
};

export const GET_POLICY = {
  method: API_CONST.GET,
  name: API_CONST.NAME,
  path: "/policy"
};

export const POST_POLICY = {
  method: API_CONST.POST,
  name: API_CONST.NAME,
  path: "/policy"
};

export const GET_USER_POLICIES = {
  method: API_CONST.GET,
  name: API_CONST.NAME,
  path: "/policy/overview"
}

// group
export const GET_GROUPS = {
  method: API_CONST.GET,
  name: API_CONST.NAME,
  path: "/groups"
};

export const GET_GROUP = {
  method: API_CONST.GET,
  name: API_CONST.NAME,
  path: "/group"
};

export const POST_GROUP = {
  method: API_CONST.POST,
  name: API_CONST.NAME,
  path: "/group"
};

// user
// backlog: KDB-73 ONIGIRI-AMにおける、アカウント一覧画面の表示不備-開発
export const GET_USER = {
  method: API_CONST.POST,
  name: API_CONST.NAME,
  path: "/users_info"
};

export const POST_USER = {
  method: API_CONST.POST,
  name: API_CONST.NAME,
  path: "/user"
};

/**ONIGIRI-DB API */
export const DB_API_CONST = {
  NAME: "kdb-cm-web",
  GET: "GET",
  POST: "POST",
  SUCCESS: "OK"
};

export const GET_COMPANY = {
  method: DB_API_CONST.GET,
  name: DB_API_CONST.NAME,
  path: "/info/company"
};

export const POST_COMPANY = {
  method: DB_API_CONST.POST,
  name: DB_API_CONST.NAME,
  path: "/info/company"
};

export const GET_DB_STRUCTURE = {
  method: DB_API_CONST.GET,
  name: DB_API_CONST.NAME,
  path: "/info/structure/all"
};

export const GET_DB_GROUP = {
  method: DB_API_CONST.GET,
  name: DB_API_CONST.NAME,
  path: "/group"
}

export const GET_CODE = {
  method: DB_API_CONST.GET,
  name: DB_API_CONST.NAME,
  path: "/code/all"
};
