export const holdingPeriod = ["日", "週", "月", "年"];
export const DB_TYPE_ORACLE = "02001";
export const DB_TYPE_MSSQL = "02002";
export const GROUP_NON_AFF = "未所属";

export const SEARCH_CODE_TYPE = {
  CODE_NAME: "コード定義名",
  CODE_VALUE: "コード値"
};

export const MODAL_TYPE = {
  USER: "USER",
  GROUP: "GROUP",
  POLICY_GROUP: "POLICY_GROUP",
  POLICY_USER: "POLICY_USER",
  USER_GROUP: "USER_GROUP"
};

export const SERVICE_LIST = {
  ONIGIRIAM: {
    code: "00000",
    name: "ONIGIRI-AM"
  },
  ONIGIRIDB: {
    code: "00001",
    name: "ONIGIRI-DB"
  }
};

export const DB_RESOURCE_TYPE = {
  META: "META",
  CODE: "CODE",
  GROUP: "GROUP",
  TOPIC: "TOPIC",
  COMPANY: "COMPANY",
  TAG: "TAG"
};
