import * as types from "constants/ActionTypes";

var initState = false;

var isLoginExpire = (state = initState, action) => {
  if (action.type === types.SHOW_LOGIN_EXPIRE) {
    state = action.isShowExp;
  }
  return state;
};

export default isLoginExpire;
