import React from "react";
import BaseComponent from "components/common/BaseComponent";
import * as CognitoUserUtils from "lib/CognitoUserUtils";
import validator from "validator";
import * as MsgConstants from "constants/MessageConstants";
import * as CognitoConstants from "constants/CognitoConstants";
import * as LocalStorageUtils from "lib/LocalStorageUtils";
import {ROOT_PATH} from "constants/RoutePath";
import { hist as history } from "App";
import CustomCheckbox from "components/common/CustomCheckbox";

class ChangePassword extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      newPassword: "",
      newPasswordConfirm: "",
      inputType: "password"
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
       [name]: value
    });
  }

  toggleInputType = () => {
    this.setState({
      inputType: this.state.inputType === "password" ? "text" : "password"
    });
  }

  change = () => {
    let alertParam;
    if (validator.isEmpty(this.state.password) || validator.isEmpty(this.state.newPassword) || validator.isEmpty(this.state.newPasswordConfirm)) {
      alertParam = {
        message: MsgConstants.USER_EDIT_REQUIRED_ERROR
      }
    } else if (this.state.newPassword !== this.state.newPasswordConfirm) {
       alertParam = {
        message: MsgConstants.PASSWORD_DIFFERENT_ERROR
      }
    } else {
      const callback = (err, data) => {
        let errMsg = "";
        if (err) {
          if (err.name === CognitoConstants.NOT_AUTHORIZED_EXCEPTION) {
            if (err.message === CognitoConstants.TOKEN_EXPIRED) {
              // アクセストークン期限切れ
              CognitoUserUtils.refreshToken((err, data) => {
                if (err) {
                  errMsg = MsgConstants.AUTH_ERROR;
                } else {
                  LocalStorageUtils.updateToken(data.AuthenticationResult.IdToken, data.AuthenticationResult.AccessToken);
                  CognitoUserUtils.changePassword(this.state.password, this.state.newPassword, (err, data) => {
                    // アクセストークン期限切れ且つ入力された現在パスワードが間違っていた場合
                    if (err) errMsg = MsgConstants.AUTH_ERROR;
                  })
                }
              });
            } else {
              // 入力された現在パスワードが間違っていた場合
              errMsg = MsgConstants.AUTH_ERROR;
            }
          } else if (err.name === CognitoConstants.INVALID_PASSWORD_EXCEPTION) {
            // 新しいパスワードがポリシーに違反した場合
            errMsg = MsgConstants.PASSWORD_POLICY_ERROR;
          } else {
            // 認証エラー以外のエラーが発生した場合
            errMsg = MsgConstants.PASSWORD_CHANGE_ERROR;
          }
        }
        if (errMsg) {
          this.showDialog({
            message: errMsg
          });
        } else {
          this.showToast(MsgConstants.PASSWORD_CHANGE_SUCCESS);
          history.push(ROOT_PATH);
        }
      }
      alertParam = {
        message: MsgConstants.PASSWORD_CHANGE_CONFIRM,
        confirm: true,
        positive: () => CognitoUserUtils.changePassword(this.state.password, this.state.newPassword, callback)
      }
    }
    this.showDialog(alertParam);
  }

  render () {
    return (
      <div className="container-fluid m-0 p-0 w-100">
        <div id="detail" className="mt-5 acc-detail lv-closed m-0 flex-nowrap fixed-layout">
          <div className="d-flex mb-3 pr-3">
            <div className="d-flex">
              <span className="align-self-center adm-detail-main-title">
                パスワード変更
              </span>
            </div>
          </div>
          <div className="mb-3">
            <div className="px-3">
              <div className="mb-2 detail-sub-title">
                {MsgConstants.PASSWORD_POLICY_INFO}
              </div>
              <div className="row no-gutters">
                <div className="col-2 custom-border-bottom">
                  <span className="key">現在のパスワード</span>
                </div>
                <div className="col-6 pl-5 custom-border-bottom no-gutters d-flex flex-inline">
                  <div className="col">
                    <input className="custom-input" type={this.state.inputType} name="password" onChange={this.handleChange}/>
                  </div>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-2 custom-border-bottom">
                  <span className="key">新しいパスワード</span>
                </div>
                <div className="col-6 pl-5 custom-border-bottom no-gutters d-flex flex-inline">
                  <div className="col">
                    <input className="custom-input" type={this.state.inputType} name="newPassword" onChange={this.handleChange}/>
                  </div>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-2 custom-border-bottom">
                  <span className="key">新しいパスワード(確認)</span>
                </div>
                <div className="col-6 pl-5 custom-border-bottom no-gutters d-flex flex-inline">
                  <div className="col">
                    <input className="custom-input" type={this.state.inputType} name="newPasswordConfirm" onChange={this.handleChange}/>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <CustomCheckbox onChange={this.toggleInputType} label="パスワードを表示する" />
              </div>
              <div className="mt-4">
                <button
                  type="button"
                  className="btn btn-save btn-positive rounded-1"
                  onClick={this.change}
                >
                  変更
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChangePassword;