import * as types from "constants/ActionTypes";

const initState = {}

const groupSelection = (state = initState, action) => {
  if (action.type === types.SELECT_GROUP) {
    state = action.group;
  }
  return state;
};

export default groupSelection;