import _ from "lodash";

export const createDbNode = (
  pathValue,
  nameValue,
  typeValue,
  levelValue,
  childrenValue,
  parentValue
) => {
  const node = {
    path: pathValue,
    name: nameValue,
    type: typeValue,
    level: levelValue,
    children: childrenValue,
    parent: parentValue
  };
  return node;
};

const parseInstance = instance_list => {
  let instanceNodes = {};
  instance_list.forEach(instance => {
    let children = [];
    let child_list = null;

    const path = instance.instance_name.replace("\\", "|");
    const name = instance.instance_name;
    const type = "instance";
    const level = 1;
    const parent = "/";
    try {
      child_list = instance.database_list;
      for (let i = 0; i < child_list.length; i++) {
        let id = path + "/" + child_list[i].database_name;
        children[i] = id;
      }
    } catch (e) {
      children = [];
    }
    let obj = createDbNode(path, name, type, level, children, parent);
    instanceNodes[path] = obj;
  });
  return instanceNodes;
};

const parseDatabase = (instance, database_list) => {
  let databaseNodes = {};
  database_list.forEach(database => {
    let children = [];
    let child_list = null;

    const path = instance + "/" + database.database_name;
    const name = database.database_name;
    const type = "database";
    const level = 2;
    const parent = instance;
    try {
      child_list = database.schema_list;
      for (let i = 0; i < child_list.length; i++) {
        let id = path + "/" + child_list[i].schema_name;
        children[i] = id;
      }
    } catch (e) {
      children = [];
    }
    let obj = createDbNode(path, name, type, level, children, parent);
    obj["database_type"] = database.database_type;
    databaseNodes[path] = obj;
  });
  return databaseNodes;
};

const parseSchema = (instance, database, schema_list) => {
  let schemaNodes = {};
  schema_list.forEach(schema => {
    let children = [];
    let child_list = null;

    const path = instance + "/" + database + "/" + schema.schema_name;
    const name = schema.schema_name;
    const type = "schema";
    const level = 3;
    const parent = instance + "/" + database;
    try {
      child_list = schema.table_list;
      for (let i = 0; i < child_list.length; i++) {
        let id = path + "/" + child_list[i].table_name;
        children[i] = id;
      }
    } catch (e) {
      children = [];
    }
    let obj = createDbNode(path, name, type, level, children, parent);
    schemaNodes[path] = obj;
  });
  return schemaNodes;
};

const parseTable = (instance, database, schema, table_list) => {
  let tableNodes = {};
  table_list.forEach(table => {
    let children = [];

    const path =
      instance + "/" + database + "/" + schema + "/" + table.table_name;
    const name = table.table_name;
    const type = "table";
    const level = 4;
    const parent = instance + "/" + database + "/" + schema;
    let obj = createDbNode(path, name, type, level, children, parent);
    tableNodes[path] = obj;
  });
  return tableNodes;
};

const extendNodes = (obj, src) => {
  for (let key in src) {
    if (src.hasOwnProperty(key)) obj[key] = src[key];
  }
  return obj;
};

export const parseDbNodes = json => {
  let dbNodes = {};
  if (!_.isEmpty(json)) {
    let instance_list = json["instance_list"];
    let databaseNodes = {};
    let schemaNodes = {};
    let tableNodes = {};
    let instanceNodes = {};
    instanceNodes = parseInstance(instance_list);
    dbNodes = extendNodes(dbNodes, instanceNodes);
    for (let i = 0; i < instance_list.length; i++) {
      let database_list = null;
      try {
        database_list = instance_list[i].database_list;
        let instance_name = instance_list[i].instance_name.replace("\\", "|");
        databaseNodes = parseDatabase(instance_name, database_list);
        dbNodes = extendNodes(dbNodes, databaseNodes);
        for (let j = 0; j < database_list.length; j++) {
          let schema_list = null;
          try {
            schema_list = database_list[j].schema_list;
            let database_name = database_list[j].database_name;
            schemaNodes = parseSchema(
              instance_name,
              database_name,
              schema_list
            );
            dbNodes = extendNodes(dbNodes, schemaNodes);
            for (let k = 0; k < schema_list.length; k++) {
              let table_list = null;
              try {
                table_list = schema_list[k].table_list;
                let schema_name = schema_list[k].schema_name;
                tableNodes = parseTable(
                  instance_name,
                  database_name,
                  schema_name,
                  table_list
                );
                dbNodes = extendNodes(dbNodes, tableNodes);
              } catch (e) {}
            }
          } catch (e) {}
        }
      } catch (e) {}
    }
  }
  return dbNodes;
};

export default parseDbNodes;
