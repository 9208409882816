import React from "react";
import BaseComponent from "components/common/BaseComponent";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import validator from "validator";
import * as CognitoConstants from "constants/CognitoConstants";
import * as MsgConstants from "constants/MessageConstants";
import * as StringFormat from "lib/StringFormat";
import * as CognitoUserUtils from "lib/CognitoUserUtils";
import * as ApiConstants from "constants/ApiConstants";
import * as LocalStorage from "lib/LocalStorageUtils";
import { LIST_USERS, LIST_POLICY } from "constants/LocalStorage";
import { MODAL_TYPE } from "constants/CommonConstants";
import UserEditGroup from "components/content/user/UserEditGroup";
import UserEditPolicy from "components/content/user/UserEditPolicy";
import { USER_DETAIL_PATH } from "constants/RoutePath";
import { conversion } from "lib/LocalStorageUtils";
import { hist } from "App";

class UserAdd extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      familyName: "",
      firstName: ""
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.props.selectUser({});
    this.props.setUserPolicy([]);
    this.props.setUserGroup([]);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getUserInfo = async userId => {
    if (this.mounted) {
      const userInfo = LocalStorage.getTargetUser(userId);
      const requestParam = {
        user_ids: userId
      };
      const response = await this.executeApi(
        ApiConstants.GET_USER,
        null,
        null,
        requestParam
      );
      userInfo.policy_list = response.success ? response.data.user_list[0].policy_list : [];
      userInfo.group_list = response.success ? response.data.user_list[0].group_list : [];
      this.props.setUserGroup(userInfo.group_list);
      this.props.setUserPolicy(userInfo.policy_list);
      return userInfo;
    }
  };

  addUser = async () => {
    const { email, familyName, firstName } = this.state;
    const { userEditGroup, userEditPolicy } = this.props;
    const type = CognitoConstants.ADMIN_USER_CODE;
    const editGroups = [];
    const editPolices = [];
    const delPolicy = [];

    let alertParam;
    userEditGroup.forEach(group => {
      editGroups.push(group.group_id);
    });
    userEditPolicy.forEach(policy => {
      const policyList = JSON.parse(localStorage.getItem(LIST_POLICY));
      if (policyList.filter(e => e.policy_id === policy.policy_id).length) {
        editPolices.push(policy.policy_id);
      } else delPolicy.push(policy.policy_name);
    });

    // Check policy exist
    if (delPolicy.length) {
      this.showDialog({
        message: StringFormat.format(MsgConstants.POLICY_NOT_EXIST, [delPolicy])
      });
      return;
    }

    // Check input valid
    if (
      !validator.isEmail(email.trim()) ||
      validator.isEmpty(familyName.trim()) ||
      validator.isEmpty(firstName.trim())
    ) {
      alertParam = {
        message: MsgConstants.USER_EDIT_REQUIRED_ERROR
      };
    } else {
      const callback = async (err, addUserData) => {
        if (err) {
          this.showDialog({
            message:
              err.name === CognitoConstants.USER_NAME_EXISTS_EXCEPTION
                ? MsgConstants.USER_ADD_EXIST_ERROR
                : StringFormat.format(MsgConstants.USER_EDIT_ERROR, ["追加"])
          });
        } else {
          await this.getUsers().then(users => {
            localStorage.setItem(
              LIST_USERS,
              JSON.stringify(LocalStorage.conversion(users))
            );
          });
          const listUsers = JSON.parse(localStorage.getItem(LIST_USERS));
          const addUser = listUsers.filter(
            user => user.Username === email
          )[0];
          const requestParam = {
            user_id: addUser.id,
            valid_flag: true,
            group_list: editGroups,
            policy_list: editPolices
          };
          const response = await this.executeApi(
            ApiConstants.POST_USER,
            null,
            null,
            requestParam
          );
          if (response.success) {
            this.showToast(
              StringFormat.format(MsgConstants.ADD_SUCCESS, ["ユーザー"])
            );
            // update selection user and go to info mode
            await this.getUsers().then(users => {
              const converted = conversion(users);
              localStorage.setItem(LIST_USERS, JSON.stringify(converted));
            });
            const updateInfo = await this.getUserInfo(addUser.id);
            this.props.selectUser(updateInfo);
            const route = `${USER_DETAIL_PATH}/${addUser.id}/info`;
            hist.push(decodeURI(route));
            this.props.setUserRoute(decodeURI(route));
            this.props.editGroup("addUser");
          }
        }
      };
      alertParam = {
        message: MsgConstants.USER_ADD_CONFIRM,
        confirm: true,
        positive: () =>
          CognitoUserUtils.add(
            email.trim(),
            familyName.trim(),
            firstName.trim(),
            type,
            callback
          )
      };
    }
    this.showDialog(alertParam);
  };

  openModal = params => {
    this.props.openModal(params[0], params[1]);
  };

  onEmailChange = e => {
    const { value } = e.target;
    this.setState({
      email: value
    });
  };

  onFamilyNameChange = e => {
    const { value } = e.target;
    this.setState({
      familyName: value
    });
  };

  onFirstNameChange = e => {
    const { value } = e.target;
    this.setState({
      firstName: value
    });
  };

  render() {
    const { groupList, policyList } = this.props;
    return (
      <div className="container-fluid m-0 p-0 w-100">
        <div
          id="detail-margin"
          className={this.setDetailStyle(this.props.isLvExpand)}
        >
          <div className="tab-pane" id="definition-add">
            <div className="m-0 flex-nowrap">
              <div className="d-flex mb-3 pr-3">
                <div className="d-flex">
                  <span className="align-self-center adm-detail-main-title">
                    ユーザーを追加
                  </span>
                </div>
              </div>
              <div className="custom-border-bottom">
                <div className="row no-gutters">
                  <div className="col-2 custom-border-bottom">
                    <span className="key">メールアドレス</span>
                  </div>
                  <div className="col pl-2 custom-border-bottom">
                    <input
                      className="custom-input"
                      type="text"
                      ref="emailRef"
                      onChange={this.onEmailChange}
                    />
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-2">
                    <span className="key">氏名</span>
                  </div>
                  <div className="col pl-2 no-gutters d-flex flex-inline">
                    <div className="col-4 mr-3">
                      <input
                        className="custom-input"
                        type="text"
                        ref="familyNameRef"
                        onChange={this.onFamilyNameChange}
                      />
                    </div>
                    <div className="col">
                      <input
                        className="custom-input"
                        type="text"
                        ref="firstNameRef"
                        onChange={this.onFirstNameChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-3">
                <div className="row no-gutters">
                  <div className="key col-2 detail-group-sub-title">
                    所属するグループ
                  </div>
                  <div className="col">
                    <div className="mdl-list-user-header">
                      <div
                        className="btn btn-positive rounded-1 ml-2 mt-1"
                        onClick={this.openModal.bind(this, [
                          MODAL_TYPE.GROUP,
                          []
                        ])}
                      >
                        グループを選択
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-2"></div>
                  <div className="col">
                    <div className="mdl-list-user-header">
                      <div className="mdl-grid mdl-list-user">
                        <span className="value pl-2">グループ名</span>
                        <span className="value">アタッチ済みのポリシー</span>
                      </div>
                    </div>
                  </div>
                </div>
                <UserEditGroup groupList={groupList} />
              </div>
              <div className="row no-gutters">
                <div className="col custom-border-bottom"></div>
              </div>
              <div className="pb-3">
                <div className="row no-gutters">
                  <div className="key col-2 detail-group-sub-title">
                    ポリシーのアタッチ
                  </div>
                  <div className="col">
                    <div
                      className="btn btn-positive rounded-1 ml-2 mt-1"
                      onClick={this.openModal.bind(this, [
                        MODAL_TYPE.POLICY_USER,
                        []
                      ])}
                    >
                      ポリシーを選択
                    </div>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="col-2"></div>
                  <div className="col">
                    <div className="mdl-list-user-header">
                      <div className="mdl-grid mdl-list-user">
                        <span className="value pl-2">ポリシー名</span>
                        <span className="value">説明</span>
                      </div>
                    </div>
                  </div>
                </div>
                <UserEditPolicy initList={policyList} />
              </div>
              <div className="pb-3">
                <input
                  type="button"
                  className="btn btn-positive rounded-1"
                  value="作成"
                  onClick={this.addUser}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLvExpand: state.lvExpandState,
    userList: state.userList,
    selectedUser: state.selectedUser,
    userEditGroup: state.userEditGroup,
    userEditPolicy: state.userEditPolicy
  };
};

const mapDispatchToProbs = dispatch => {
  return {
    setUserRoute: route => {
      dispatch(actions.setUserRoute(route));
    },
    setUserList: list => {
      dispatch(actions.setUserList(list));
    },
    selectUser: user => {
      dispatch(actions.selectUser(user));
    },
    setUserGroup: group => {
      dispatch(actions.setUserGroup(group));
    },
    setUserPolicy: policy => {
      dispatch(actions.setUserPolicy(policy));
    },
    editGroup: action => {
      dispatch(actions.editGroup(action));
    },
    openModal: (modal, data) => {
      dispatch(actions.openModal(modal, data));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(UserAdd);
