import React, { Component } from "react";
import { connect } from "react-redux";
import CustomCheckbox from "components/common/CustomCheckbox";
import * as actions from "actions/Actions";
import { DB_RESOURCE_TYPE } from "constants/CommonConstants";
import _ from "lodash";

class Tag extends Component {
  onSelectCreate = e => {
    const { checked } = e.target;
    const permision = _.cloneDeep(this.props.tagRes);
    permision.isCreate = checked;
    this.props.setTagRes(permision);
    this.props.setJsonData(DB_RESOURCE_TYPE.TAG, false, permision);
  };

  onSelectDelete = e => {
    const { checked } = e.target;
    const permision = _.cloneDeep(this.props.tagRes);
    permision.isDelete = checked;
    this.props.setTagRes(permision);
    this.props.setJsonData(DB_RESOURCE_TYPE.TAG, false, permision);
  };

  render() {
    const { tagRes } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <span className="pr-2">
            <CustomCheckbox
              checked={tagRes.isCreate}
              onChange={this.onSelectCreate}
            />
          </span>
          作成
        </div>
        <div className="row">
          <span className="pr-2">
            <CustomCheckbox
              checked={tagRes.isDelete}
              onChange={this.onSelectDelete}
            />
          </span>
          削除
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    tagRes: state.dbCommonRes.tag
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setTagRes: tag => {
      dispatch(actions.setTagRes(tag));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tag);
