export const COMPANY_LIST = "COMPANY_LIST";
export const LOGIN_USER = "LOGIN_USER";
export const LIST_USERS = "LIST_USERS";
export const LOGIN_STATE = "LOGIN_STATE";
export const CURRENT_THEME = "CURRENT_THEME";
export const LISTVIEW_EXPAND = "LISTVIEW_EXPAND";
export const LISTVIEW_TYPE = "LISTVIEW_TYPE";
export const GROUP_LIST = "GROUP_LIST";
export const USER_CURRENT = "USER_CURRENT";
export const GROUP_FILTER_KEY = "GROUP_FILTER_KEY";
export const POLICY_FILTER_KEY = "POLICY_FILTER_KEY";
export const LIST_POLICY = "LIST_POLICY";
export const USER_FILTER_KEY = "USER_FILTER_KEY";

// ONIGIRI-DB
export const DB_STRUCTURE = "DB_STRUCTURE";
export const DB_GROUP = "DB_GROUP";
export const CODE_LIST = "CODE_LIST";
export const CODE_OPEN_NODE = "CODE_OPEN_NODE";
export const META_SEARCH_KEY = "META_SEARCH_KEY";
export const DB_META_SEARCH_KEY = "DB_META_SEARCH_KEY";
export const DB_CODE_SEARCH_KEY = "DB_CODE_SEARCH_KEY";
export const DB_GROUP_SEARCH_KEY = "DB_GROUP_SEARCH_KEY";
