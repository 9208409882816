import React from "react";
import { DB_TYPE_MSSQL } from "constants/CommonConstants";
import BaseComponent from "components/common/BaseComponent";
import CustomCheckbox from "components/common/CustomCheckbox";
import * as actions from "actions/Actions";
import { connect } from "react-redux";
import { getMetaChildList } from "components/content/policy/oniDbRes/metaData/MetaAM";
import { DB_RESOURCE_TYPE } from "constants/CommonConstants";

const getNodeStyle = node => {
  let styleNode = "";
  switch (node.type) {
    case "instance":
      styleNode = "lv-instance";
      break;
    case "database":
      styleNode = "lv-database";
      break;
    case "schema":
      styleNode = "lv-schema";
      break;
    case "table":
      styleNode = "lv-table";
      break;
    default:
      break;
  }
  return styleNode;
};

const getNodeIcon = node => {
  let iconNode = "";
  switch (node.type) {
    case "instance":
      iconNode = "lv-ic-instance";
      break;
    case "database":
      if (node["database_type"] === DB_TYPE_MSSQL) {
        iconNode = "lv-ic-db-sqlserver";
      } else {
        iconNode = "lv-ic-db-oracle";
      }
      break;
    case "schema":
      iconNode = "lv-ic-schema";
      break;
    case "table":
      iconNode = "lv-ic-table";
      break;
    default:
      break;
  }
  return iconNode;
};

const getActiveStyle = (node, selectedNode) => {
  let styleType = getNodeStyle(node);
  if (selectedNode && selectedNode.path === node.path) {
    styleType = styleType + " active";
  }
  return styleType;
};

const DBNode = props => {
  const {
    node,
    onToggle,
    selectedNode,
    handleSelectRead,
    handleSelectWrite
  } = props;
  return (
    <div className=" mdl-grid mdl-list-policy-meta">
      <div className="database-link">
        <div onClick={() => onToggle(node)}>
          <div className={getActiveStyle(node, selectedNode)}>
            {node.type === "instance" &&
              (node.isOpen ? (
                <div className="lv-ic-arrow parent opened" />
              ) : (
                <div className="lv-ic-arrow parent" />
              ))}
            {node.type === "database" &&
              (node.isOpen ? (
                <div className="lv-ic-arrow parent opened" />
              ) : (
                <div className="lv-ic-arrow parent" />
              ))}
            {node.type === "schema" &&
              (node.isOpen ? (
                <div className="lv-ic-arrow parent opened" />
              ) : (
                <div className="lv-ic-arrow parent" />
              ))}
            <div className={getNodeIcon(node)} />
            <span role="button">{node.name}</span>
          </div>
        </div>
      </div>
      <div className="pl-1">
        <CustomCheckbox
          name={`${node.path}/read`}
          checked={node.isRead}
          onChange={handleSelectRead}
          disabled={node.isWrite}
        />
      </div>
      <div className="pl-1">
        <CustomCheckbox
          name={`${node.path}/write`}
          checked={node.isWrite}
          onChange={handleSelectWrite}
        />
      </div>
    </div>
  );
};

class TreeNode extends BaseComponent {
  handleSelectRead = e => {
    const { node, metaData } = this.props;
    const dbNodes = metaData.dbNodes;
    const { checked } = e.target;
    dbNodes[node.path].isRead = checked;

    // handle child nodes
    const childNodes = getMetaChildList(node, dbNodes);
    childNodes.forEach(node => {
      dbNodes[node.path].isRead = checked;
    });
    // handle parent node
    const handleParent = parent => {
      const parentNode = dbNodes[parent];
      const sameLevelNodes = getMetaChildList(parentNode, dbNodes);
      let isAll = true;
      sameLevelNodes.forEach(node => {
        if (dbNodes[node.path].isRead !== checked) isAll = false;
      });
      if (isAll) {
        dbNodes[parentNode.path].isRead = checked;
      }
      return parentNode;
    };
    let parent = node.parent;
    while (parent !== "/") {
      const nodeParent = handleParent(parent);
      parent = nodeParent.parent;
    }
    // set meta tree
    this.props.setDbNodes(dbNodes);
    this.props.setJsonData(DB_RESOURCE_TYPE.META);
  };

  handleSelectWrite = e => {
    const { node, metaData } = this.props;
    const dbNodes = metaData.dbNodes;
    const { checked } = e.target;
    dbNodes[node.path].isRead = checked;
    dbNodes[node.path].isWrite = checked;

    // handle child nodes
    const childNodes = getMetaChildList(node, dbNodes);
    childNodes.forEach(node => {
      dbNodes[node.path].isRead = checked;
      dbNodes[node.path].isWrite = checked;
    });
    // handle parent node
    const handleParent = parent => {
      const parentNode = dbNodes[parent];
      const sameLevelNodes = getMetaChildList(parentNode, dbNodes);
      let isAll = true;
      sameLevelNodes.forEach(node => {
        if (dbNodes[node.path].isWrite !== checked) isAll = false;
      });
      if (isAll) {
        dbNodes[parentNode.path].isRead = checked;
        dbNodes[parentNode.path].isWrite = checked;
      }
      return parentNode;
    };
    let parent = node.parent;
    while (parent !== "/") {
      const nodeParent = handleParent(parent);
      parent = nodeParent.parent;
    }
    // set meta tree
    this.props.setDbNodes(dbNodes);
    this.props.setJsonData(DB_RESOURCE_TYPE.META);
  };

  render() {
    const { node, getChildNodes, metaData, setJsonData } = this.props;
    return (
      <div>
        <DBNode
          {...this.props}
          handleSelectRead={this.handleSelectRead}
          handleSelectWrite={this.handleSelectWrite}
        />
        {node.isOpen &&
          getChildNodes(node).map((childNode, index) => (
            <TreeNode
              {...this.props}
              key={index}
              node={childNode}
              getChildNodes={getChildNodes}
              selectedNode={metaData.selectedNode}
              setJsonData={setJsonData}
            />
          ))}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    metaData: state.dbMetaData
  };
};

const mapDispatchToProbs = dispatch => {
  return {
    selectDbNode: node => {
      dispatch(actions.selectDbNode(node));
    },
    setDbNodes: nodes => {
      dispatch(actions.setDbNodes(nodes));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(TreeNode);
