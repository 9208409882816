import React, { Component } from "react";
import { connect } from "react-redux";
import CustomCheckbox from "components/common/CustomCheckbox";
import * as actions from "actions/Actions";
import { DB_RESOURCE_TYPE } from "constants/CommonConstants";
import _ from "lodash";

class Topic extends Component {
  onSelectDeleteTopic = e => {
    const { checked } = e.target;
    const permision = _.cloneDeep(this.props.topicRes);
    permision.isDeleteTopic = checked;
    this.props.setTopicRes(permision);
    this.props.setJsonData(DB_RESOURCE_TYPE.TOPIC, false, permision);
  };

  onSelectDeleteCmt = e => {
    const { checked } = e.target;
    const permision = _.cloneDeep(this.props.topicRes);
    permision.isDeleteCmt = checked;
    this.props.setTopicRes(permision);
    this.props.setJsonData(DB_RESOURCE_TYPE.TOPIC, false, permision);
  };

  render() {
    const { topicRes } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <span className="pr-2">
            <CustomCheckbox
              checked={topicRes.isDeleteTopic}
              onChange={this.onSelectDeleteTopic}
            />
          </span>
          トピックの削除
        </div>
        <div className="row">
          <span className="pr-2">
            <CustomCheckbox
              checked={topicRes.isDeleteCmt}
              onChange={this.onSelectDeleteCmt}
            />
          </span>
          全てのコメントの削除
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    topicRes: state.dbCommonRes.topic
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setTopicRes: topic => {
      dispatch(actions.setTopicRes(topic));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topic);
