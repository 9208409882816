const createCodeNode = (
  pathValue,
  nameValue,
  typeValue,
  levelValue,
  childrenValue,
  parentValue
) => {
  const node = {
    path: pathValue,
    name: nameValue,
    type: typeValue,
    level: levelValue,
    children: childrenValue,
    parent: parentValue
  };
  return node;
};

const extendNodes = (obj, src) => {
  for (var key in src) {
    if (src.hasOwnProperty(key)) obj[key] = src[key];
  }
  return obj;
};

const parseCodeNodes = (company, category, codeList) => {
  let codeNodes = {};
  const cate = category === null || category === "" ? "未分類" : category;
  for (let i = 0; i < codeList.length; i++) {
    const path = company + "/" + cate + "/" + codeList[i].code_definition_id;
    const name = codeList[i].code_definition_name;
    const type = "code";
    const level = "3";
    const children = [];
    const parent = company + "/" + cate
    let node = createCodeNode(path, name, type, level, children, parent);
    codeNodes[path] = node;
  }
  return codeNodes;
};

const parseCategoryNodes = (company, codeList) => {
  let categoryList = [];
  let categoryNodes = {};
  let codeNodes = {};
  let nonCatenode = {
    path: company + "/未分類",
    name: "未分類",
    type: "category",
    level: 2,
    children: [],
    parent: company
  };
  for (let i = 0; i < codeList.length; i++) {
    let category = codeList[i].category;
    if (!categoryList.includes(category)) categoryList.push(category);
  }
  categoryList.sort();
  for (let i = 0; i < categoryList.length; i++) {
    const cate =
      categoryList[i] === null
        ? "cateNull"
        : categoryList[i] === ""
        ? "cateEmpty"
        : categoryList[i];
    const path = company + "/" + cate;
    const name = cate;
    const type = "category";
    const level = 2;
    const parent = company;
    let children = [];
    const codeCategorys = codeList.filter(
      code => code.category === categoryList[i]
    );
    codeNodes = parseCodeNodes(company, categoryList[i], codeCategorys, parent);
    children = Object.keys(codeNodes);
    let node = createCodeNode(path, name, type, level, children, parent);
    if (categoryList[i] === null || categoryList[i] === "") {
      nonCatenode.children = nonCatenode.children.concat(children);
    } else {
      categoryNodes[path] = node;
    }
    categoryNodes = extendNodes(categoryNodes, codeNodes);
  }
  if (nonCatenode.children.length > 0) {
    let node = company + "/未分類";
    categoryNodes[node] = nonCatenode;
  }
  return categoryNodes;
};

export const parseCodeTreeNodes = codeList => {
  let companyList = [];
  let codeTreeNodes = {};
  let categoryNodes = {};
  for (let i = 0; i < codeList.length; i++) {
    let company = codeList[i].company_id;
    if (!companyList.includes(company)) companyList.push(company);
  }
  companyList.sort(function(a, b) {
    return parseInt(a) - parseInt(b);
  });
  for (let i = 0; i < companyList.length; i++) {
    const path = companyList[i];
    const name = companyList[i];
    const type = "company";
    const level = 1;
    const parent = "/";
    let children = [];
    const codeCompanys = codeList.filter(
      code => code.company_id === companyList[i]
    );
    categoryNodes = parseCategoryNodes(companyList[i], codeCompanys);
    codeTreeNodes = extendNodes(codeTreeNodes, categoryNodes);
    const categoryKeys = Object.keys(categoryNodes);
    for (let k = 0; k < categoryKeys.length; k++) {
      const child = categoryKeys[k].split("/");
      if (child.length === 2) {
        children.push(categoryKeys[k]);
      }
    }
    let node = createCodeNode(path, name, type, level, children, parent);
    codeTreeNodes[path] = node;
  }
  return codeTreeNodes;
};
