import * as types from "constants/ActionTypes";

const initState = {
  isCreate: false,
  groupList: [],
};

const groupList = (state = initState, action) => {
  switch (action.type) {
    case types.SET_DBGROUP_LIST:
      return {
        ...state,
        groupList: action.list
      };
    case types.CAN_CREATE_GROUP:
      return {
        ...state,
        isCreate: action.isCreate
      };
    default:
      return state;
  }
};

export default groupList;
