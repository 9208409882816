import * as types from "constants/ActionTypes";

const initState = {
  isCreate: false,
  codeNodes: {},
  selectedNode: {},
  openNodes: []
};

const dbCodeData = (state = initState, action) => {
  switch (action.type) {
    case types.SET_DB_CODE:
      return {
        ...state,
        codeNodes: action.nodes
      };
    case types.SELECT_DB_CODE:
      return {
        ...state,
        selectedNode: action.selectedNode
      };
    case types.CAN_CREATE_CODE:
      return {
        ...state,
        isCreate: action.isCreate
      };
    case types.SET_CODE_OPEN_NODES:
      return {
        ...state,
        openNodes: action.nodes
      };
    default:
      return state;
  }
};

export default dbCodeData;
