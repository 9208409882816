import BaseComponent from "components/common/BaseComponent";
import React from "react";
import CustomCheckbox from "components/common/CustomCheckbox";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import _ from "lodash";

class AMPolicy extends BaseComponent {
    onSelectLogin = e => {
        const { checked } = e.target;
        const permision = _.cloneDeep(this.props.systemRes);
        permision.isLogin = checked;
        this.props.setSystemRes(permision);
    }

    render() {
        const { systemRes } = this.props;
        return (
            <div className="pb-3">
                <div className="row no-gutters">
                    <div className="key col-2 detail-policy-sub-title">システム</div>
                    <div className="col">
                        <div className="row">
                            <span className="pr-2">
                                <CustomCheckbox
                                  checked={systemRes.isLogin}
                                  onChange={this.onSelectLogin}
                                />
                            </span>
                            ログイン
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      systemRes: state.systemRes
    };
  };
  const mapDispatchToProbs = dispatch => {
    return {
      setSystemRes: system => {
        dispatch(actions.setSystemRes(system));
      }
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProbs)(AMPolicy);
