import * as types from "constants/ActionTypes";

const initState = [];

const userEditPolicy = (state = initState, action) => {
  if (action.type === types.SET_USER_POLICY) {
    state = action.policy;
  }
  return state;
};

export default userEditPolicy;
