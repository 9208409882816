import * as types from "constants/ActionTypes";

const initState = {}

const policySelection = (state = initState, action) => {
  if (action.type === types.SELECT_POLICY) {
    state = action.policy;
  }
  return state;
};

export default policySelection;