import * as types from "constants/ActionTypes";
import * as LocalStorage from "constants/LocalStorage";

var initState = JSON.parse(localStorage.getItem(LocalStorage.LISTVIEW_EXPAND));

var listViewExpand = (state = initState, action) => {
  var isLogin = JSON.parse(localStorage.getItem(LocalStorage.LOGIN_STATE));
  if(initState === null || isLogin === false)
  {
    state = true;
  }
  if (action.type === types.TOOGLE_LISTVIEW) state = !state;
  localStorage.setItem(LocalStorage.LISTVIEW_EXPAND, JSON.stringify(state))
  return state;
};

export default listViewExpand;
