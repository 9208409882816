import React from "react";
import BaseComponent from "components/common/BaseComponent";
import values from "lodash/values";
import TreeNode from "components/content/policy/oniDbRes/metaData/TreeNode";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import { GROUP_NON_AFF } from "constants/CommonConstants";

class Tree extends BaseComponent {
  getRootNodes = () => {
    const { groupData, metaData } = this.props;
    const { dbNodes } = metaData;
    const rootNodes = values(dbNodes).filter(node => node.type === "instance");
    if (rootNodes.length) {
      rootNodes.forEach(node => {
        const group = this.getInsGroup(node, groupData.groupList);
        node.group = group;
      });
      const sortRootNodes = rootNodes.sort(function(a, b) {
        return a.group.display_position - b.group.display_position;
      });
      sortRootNodes[0].isDisplayGroup = true;
      sortRootNodes.forEach((node, i) => {
        if (
          i > 0 &&
          node.group.group_name !== sortRootNodes[i - 1].group.group_name
        ) {
          node.isDisplayGroup = true;
        }
      });
      return sortRootNodes;
    } else {
      return [];
    }
  };

  getInsGroup = (instance, groupList) => {
    let groupIns = {
      group_name: GROUP_NON_AFF,
      display_position: Number.MAX_SAFE_INTEGER
    };
    groupList.forEach(group => {
      if (group.instance_list.includes(instance.name)) {
        groupIns = group;
      }
    });
    return groupIns;
  };

  getChildNodes = node => {
    const { metaData } = this.props;
    if (!node.children) return [];
    return node.children.map(path => metaData.dbNodes[path]);
  };

  onToggle = node => {
    let { openNodes } = this.props.metaData;
    const nodeId = decodeURIComponent(node.path);
    const { metaData } = this.props;
    if (node.type !== "table") {
      metaData.dbNodes[nodeId].isOpen = !node.isOpen;

      if (node.isOpen) {
        if (!openNodes.includes(nodeId)) {
          openNodes.push(nodeId);
        }
      } else {
        const index = openNodes.indexOf(nodeId);
        if (index >= 0) {
          openNodes.splice(index, 1);
        }
      }
    }
    this.props.setDbNodes(metaData.dbNodes);
    this.props.selectDbNode(node);
    this.props.setDbOpenNodes(openNodes);
  };

  render() {
    const { metaData, setJsonData } = this.props;
    const rootNodes = this.getRootNodes();
    return (
      <div className="pt-2">
        {rootNodes.map((node, index) => (
          <div key={index}>
            <div className=" mdl-grid mdl-list-policy-meta">
              {node.isDisplayGroup ? (
                <div className="lv-db-group">{node.group.group_name}</div>
              ) : (
                <div />
              )}
              <div />
              <div />
            </div>
            <TreeNode
              key={index}
              node={node}
              getChildNodes={this.getChildNodes}
              onToggle={this.onToggle}
              selectedNode={metaData.selectedNode}
              setJsonData={setJsonData}
            />
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    groupData: state.dbGroupList,
    metaData: state.dbMetaData
  };
};

const mapDispatchToProbs = dispatch => {
  return {
    selectDbNode: node => {
      dispatch(actions.selectDbNode(node));
    },
    setDbNodes: nodes => {
      dispatch(actions.setDbNodes(nodes));
    },
    setDbOpenNodes: nodes => {
      dispatch(actions.setDbOpenNodes(nodes));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(Tree);
