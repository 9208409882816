import * as CognitoConstants from "constants/CognitoConstants";
import * as LocalStorageUtils from "lib/LocalStorageUtils";
import AppConfig from "../config";

const aws = require('aws-sdk');
aws.config.region = AppConfig.cognito.REGION;
aws.config.credentials = new aws.CognitoIdentityCredentials({
  IdentityPoolId: AppConfig.cognito.IDENTITY_POOL_ID
});
const provider = new aws.CognitoIdentityServiceProvider({
  apiVersion: AppConfig.cognito.IDENTITY_SERVICE_PROVIDER_API_VERSION,
  region: AppConfig.cognito.REGION
});

export function getUsers(callback, paginationToken) {
  const param = {
    UserPoolId : AppConfig.cognito.USER_POOL_ID,
  }
  if (paginationToken) param.PaginationToken = paginationToken;
  provider.listUsers(param, callback);
}

export function add(email, familyName, firstName, type, callback) {
  provider.adminCreateUser({
    DesiredDeliveryMediums: ["EMAIL"],
    ForceAliasCreation: false,
    UserPoolId: AppConfig.cognito.USER_POOL_ID,
    Username: email,
    UserAttributes: [
      {
        Name: "email",
        Value: email
      },
      {
        Name: "email_verified",
        Value: "true"
      },
      {
        Name: "family_name",
        Value: familyName
      },
      {
        Name: "name",
        Value: firstName
      },
      {
        Name: CognitoConstants.USER_STATUS,
        Value: type
      },
      {
        Name: CognitoConstants.LOGIN_DATE,
        Value: ""
      }
    ]
  }, callback);
}

export function remove(email, callback) {
  provider.adminDeleteUser({
    UserPoolId: AppConfig.cognito.USER_POOL_ID,
    Username: email
  }, callback);
}

export function update(familyName, firstName, type, email, callback) {
  provider.adminUpdateUserAttributes({
    UserAttributes: [
      {
        Name: "family_name",
        Value: familyName
      },
      {
        Name: "name",
        Value: firstName
      },
      {
        Name: CognitoConstants.USER_STATUS,
        Value: type
      }
    ],
    UserPoolId: AppConfig.cognito.USER_POOL_ID,
    Username: email
  }, callback);
}

export function resetPassword(email, callback) {
  provider.adminResetUserPassword({
    UserPoolId: AppConfig.cognito.USER_POOL_ID,
    Username: email
  }, callback);
}

export function changePassword(password, newPassword, callback) {
  provider.changePassword({
    AccessToken: LocalStorageUtils.getToken().accessToken,
    PreviousPassword: password,
    ProposedPassword: newPassword
  }, callback)
}

export function refreshToken(callback) {
  provider.initiateAuth({
    AuthFlow: "REFRESH_TOKEN_AUTH",
    ClientId: AppConfig.cognito.CLIENT_ID,
    AuthParameters: {
      "REFRESH_TOKEN": LocalStorageUtils.getToken().refreshToken
    }
  }, callback)
}

export function confirmForgotPassword(authCode, newPassword, email, callback) {
  provider.confirmForgotPassword({
    ClientId: AppConfig.cognito.CLIENT_ID,
    ConfirmationCode: authCode,
    Password: newPassword,
    Username: email
  }, callback);
}
