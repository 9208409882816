import React from "react";
import BaseComponent from "components/common/BaseComponent";
import styled from "styled-components";
import GroupItem from "components/listview/group/GroupItem";
import { GROUP_DETAIL_PATH, GROUP_ADD_PATH } from "constants/RoutePath";
import ProgressBar from "components/common/ProgressBar";
import { connect } from "react-redux";
import * as ApiConstants from "constants/ApiConstants";
import * as actions from "actions/Actions";
import * as LocalStore from "constants/LocalStorage";

const TreeStyle = styled.div`
  position: inherit;
  height: calc(100vh - 150px);
  width: 296px;
  overflow: hidden;
  &:hover {
    overflow: overlay;
  }
`;

class GroupList extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterKey: localStorage.getItem(LocalStore.GROUP_FILTER_KEY)
    };
  }

  componentDidMount = async () => {
    this.mounted = true;
    const groupListLocal = localStorage.getItem(LocalStore.GROUP_LIST);
    const groupListNew = await this.getGroupList();
    if (groupListLocal !== JSON.stringify(groupListNew)) {
      localStorage.setItem(
        LocalStore.GROUP_LIST,
        JSON.stringify(groupListNew.group_list)
      );
      if (groupListNew.group_list)
        this.props.setGroupList(groupListNew.group_list);
    } else {
      this.props.setGroupList(JSON.parse(groupListLocal));
    }
  };

  componentDidUpdate = async () => {
    // on add, delete group
    if (
      this.props.groupEdit === "addGroup" ||
      this.props.groupEdit === "deleteGroup"
    ) {
      this.props.editGroup("");
      const groupList = await this.getGroupList();
      this.props.setGroupList(groupList.group_list);
      localStorage.setItem(
        LocalStore.GROUP_LIST,
        JSON.stringify(groupList.group_list)
      );
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  // グループ取得
  getGroupList = async () => {
    if (this.mounted) {
      this.runProgress();
      const response = await this.executeApi(ApiConstants.GET_GROUPS);
      this.stopProgress();
      return response.success ? response.data : {};
    }
  };

  onClickAdd = () => {
    this.props.history.push(GROUP_ADD_PATH);
    this.props.setGroupRoute(GROUP_ADD_PATH);
  };

  onClickScrollTo = top => {
    const myDiv = document.getElementById("scroll-tree");
    myDiv.scrollTop = top ? 0 : myDiv.scrollHeight;
  };

  handleFilter = e => {
    const value = e.target.value;
    this.setState({
      filterKey: value
    });
    localStorage.setItem(LocalStore.GROUP_FILTER_KEY, value);
  };

  clearFilter = e => {
    // ESC key
    if (e.keyCode === 27) {
      this.setState({
        filterKey: ""
      });
      localStorage.setItem(LocalStore.GROUP_FILTER_KEY, "");
    }
  };

  render() {
    const { history, groupList } = this.props;
    const { filterKey } = this.state;
    let filterList = groupList;
    if (filterKey)
      filterList = filterList.filter(group =>
        group.group_name.toUpperCase().includes(filterKey.toUpperCase())
      );
    return (
      <div>
        <div
          id="change-to-add-tab"
          className={
            history.location.pathname === GROUP_ADD_PATH
              ? "p-2 definition-add active"
              : "p-2 definition-add"
          }
          data-target="#definition-add"
          onClick={this.onClickAdd}
        >
          <div className="icon-size-24 lv-ic-add mr-2" />
          <span className="definition-add-txt">グループの作成</span>
        </div>
        <ProgressBar ref="progress" />
        <div id="lv-filter-wrapper">
          <input
            id="filterWord"
            type="text"
            className="w-100 form-control lv-filter"
            placeholder="グループ名で絞り込み (Escでクリア)"
            onChange={this.handleFilter}
            onKeyDown={this.clearFilter}
            defaultValue={filterKey}
          />
        </div>
        <div id="lv-summary" className="row no-gutters mt-2 px-1">
          <div className="col-8">
            <span className="lv-table-number">
              {filterList.length} グループを表示中
            </span>
          </div>
          <div className="col float-right">
            <ul className="list-inline float-right">
              <li id="scrollTop" className="list-inline-item">
                <div
                  className="icon-size-20 lv-scroll-top"
                  onClick={() => this.onClickScrollTo(true)}
                ></div>
              </li>
              <li id="scrollBottom" className="list-inline-item">
                <div
                  className="icon-size-20 lv-scroll-bottom"
                  onClick={() => this.onClickScrollTo(false)}
                ></div>
              </li>
            </ul>
          </div>
        </div>
        <div
          id="tree"
          className="row no-gutters list-view scrollbar scrollbar-primary"
        >
          <div id="search-result" className="tree force-overflow">
            <TreeStyle id="scroll-tree">
              {filterList.map((group, index) => (
                <GroupItem
                  key={index}
                  id={group.group_id}
                  name={group.group_name}
                  member={group.affiliation_count}
                  path={`${GROUP_DETAIL_PATH}/${group.group_name}/info`}
                />
              ))}
            </TreeStyle>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    groupList: state.groupList,
    groupEdit: state.groupEdit
  };
};
const mapDispatchToProbs = dispatch => {
  return {
    setGroupList: groupList => {
      dispatch(actions.setGroupList(groupList));
    },
    selectGroup: group => {
      dispatch(actions.selectGroup(group));
    },
    setGroupRoute: route => {
      dispatch(actions.setGroupRoute(route));
    },
    editGroup: action => {
      dispatch(actions.editGroup(action));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(GroupList);
