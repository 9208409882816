export const SHOW_POLICY = "SHOW_POLICY";
export const SHOW_USER = "SHOW_USER";
export const SHOW_SETTING = "SHOW_SETTING";
export const SHOW_GROUP = "SHOW_GROUP";
export const POLICY_ROUTE = "POLICY_ROUTE";
export const GROUP_ROUTE = "GROUP_ROUTE";
export const USER_ROUTE = "USER_ROUTE";
export const SETTING_ROUTE = "SETTING_ROUTE";

export const TOOGLE_LISTVIEW = "TOOGLE_LISTVIEW";
export const VIEW_USER = "VIEW_USER";
export const LOGIN = "LOGIN";
export const LIGHT_MODE = "LIGHT_MODE";
export const DARK_MODE = "DARK_MODE";
export const ALERT_DATA = "ALERT_DATA";
export const SHOW_LOGIN_EXPIRE = "SHOW_LOGIN_EXPIRE";

export const SET_GROUP_LIST = "SET_GROUP_LIST";
export const SELECT_GROUP = "SELECT_GROUP";
export const EDIT_GROUP = "EDIT_GROUP";
export const SET_GROUP_USER = "SET_GROUP_USER";
export const SET_GROUP_POLICY = "SET_GROUP_POLICY";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const SET_USER_LIST = "SET_USER_LIST";
export const SELECT_USER = "SELECT_USER";
export const SET_USER_GROUP = "SET_USER_GROUP";
export const SET_USER_POLICY = "SET_USER_POLICY";

export const SET_POLICY_LIST = "SET_POLICY_LIST";
export const SELECT_POLICY = "SELECT_POLICY";
export const SET_POLICY_USER = "SET_POLICY_USER";

export const SET_DB_CODE = "SET_DB_CODE";
export const SELECT_DB_CODE = "SELECT_DB_CODE";
export const SET_CODE_NODES = "SET_CODE_NODES";
export const CAN_CREATE_CODE = "CAN_CREATE_CODE";
export const SET_CODE_OPEN_NODES = "SET_CODE_OPEN_NODES";

export const SET_DB_META = "SET_DB_META";
export const SELECT_DB_NODE = "SELECT_DB_NODE";
export const STORE_DB_STRUCTURE = "STORE_DB_STRUCTURE";
export const SET_DB_OPEN_NODES = "SET_DB_OPEN_NODES";

export const SET_DBGROUP_LIST = "SET_DBGROUP_LIST";
export const CAN_CREATE_GROUP = "CAN_CREATE_GROUP";

export const SET_GROUP_RES = "SET_GROUP_RES";
export const SET_COMPANY_RES = "SET_COMPANY_RES";
export const SET_TOPIC_RES = "SET_TOPIC_RES";

export const SET_CODE_RES = "SET_CODE_RES";
export const SET_TAG_RES = "SET_TAG_RES";

export const SET_DB_JSON = "SET_DB_JSON";
export const IS_JSON_ERROR = "IS_JSON_ERROR";

export const SET_SYSTEM_RES = "SET_SYSTEM_RES";
