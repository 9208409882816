import React from "react";
import BaseComponent from "components/common/BaseComponent";
import styled from "styled-components";
import UserItem from "components/listview/user/UserItem";
import { LIST_USERS } from "constants/LocalStorage";
import ProgressBar from "components/common/ProgressBar";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import { conversion } from "lib/LocalStorageUtils";
import { USER_ADD_PATH } from "constants/RoutePath";
import { NavLink } from "react-router-dom";
import * as ApiConstants from "constants/ApiConstants";
import * as LocalStorage from "constants/LocalStorage";

const TreeStyle = styled.div`
  position: inherit;
  height: calc(100vh - 150px);
  width: 296px;
  overflow: hidden;
  &:hover {
    overflow: overlay;
  }
`;

class UserList extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterKey: localStorage.getItem(LocalStorage.USER_FILTER_KEY)
    };
  }

  async componentDidMount() {
    this.mounted = true;
    await this.getListUser();
  }

  componentDidUpdate = async () => {
    // on add, delete, enable, disable user
    if (
      this.props.groupEdit === "editUser" ||
      this.props.groupEdit === "deleteUser" ||
      this.props.groupEdit === "addUser" ||
      this.props.groupEdit === "enableUser" ||
      this.props.groupEdit === "disableUser"
    ) {
      let listUsers = JSON.parse(localStorage.getItem(LIST_USERS));
      this.props.editGroup("");
      const userIds = listUsers.map(user => user.id);
      const userInfos = await this.getUserInfos(userIds);
      listUsers.forEach(user => {
        if (this.mounted) {
          const userInfo = userInfos.user_list.filter(info => info.user_id === user.id);
          if (userInfo.length) {
            user.policy_list = userInfo[0].policy_list;
            user.group_list = userInfo[0].group_list;
          }
        }
      });
      // name filter
      if (this.state.filterKey) {
        listUsers = this.nameFiltering(listUsers, this.state.filterKey);
      }
      this.props.setUserList(listUsers);
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  clearFilter = e => {
    // When the esc key is pressed
    if (e.keyCode === 27) {
      e.target.value = "";
      this.setState({
        filterKey: ""
      });
      localStorage.setItem(LocalStorage.USER_FILTER_KEY, "");
      const users = JSON.parse(localStorage.getItem(LIST_USERS));
      this.setState({
        items: users,
      });
      this.props.setUserList(users);
    }
  };

  handleFilter = e => {
    let users = JSON.parse(localStorage.getItem(LIST_USERS));
    const keyword = e.target.value;
    this.setState({
      filterKey: keyword
    });
    localStorage.setItem(LocalStorage.USER_FILTER_KEY, keyword);
    users = this.nameFiltering(users, keyword);
    this.props.setUserList(users);
  };

  nameFiltering = (users, keyword) => {
    return users.filter(user => user.name.indexOf(keyword) !== -1);
  }

  getUserInfos = async userIds => {
    if (this.mounted) {
      const requestParam = {
        user_ids: userIds.join(",")
      };
      const response = await this.executeApi(
        ApiConstants.GET_USER,
        null,
        null,
        requestParam
      );
      return response.success ? response.data : {};
    }
  };

  getListUser = async () => {
    if (this.mounted) {
      this.runProgress();
      let userList = [];
      const listUsers = localStorage.getItem(LIST_USERS);
      await this.getUsers().then(users => {
        if (listUsers) {
          // localStorage has user data
          const converted = conversion(users);
          localStorage.setItem(LIST_USERS, JSON.stringify(converted));
          // difference check
          const difference = JSON.stringify(converted) !== listUsers;
          const parsed = JSON.parse(listUsers);
          userList = difference ? converted : parsed;
        } else {
          // localStorage has no user data
          const converted = conversion(users);
          localStorage.setItem(LIST_USERS, JSON.stringify(converted));
          userList = converted;
        }
      });
      const userIds = userList.map(user => user.id);
      const userInfos = await this.getUserInfos(userIds);
      userList.forEach(user => {
        if (this.mounted) {
          const userInfo = userInfos.user_list.filter(info => info.user_id === user.id);
          if (userInfo.length) {
            user.policy_list = userInfo[0].policy_list;
            user.group_list = userInfo[0].group_list;
          }
        }
      });

      // name filter
      if (this.state.filterKey) {
        userList = this.nameFiltering(userList, this.state.filterKey);
      }

      this.props.setUserList(userList);
      this.stopProgress();
    }
  };

  onClickScrollTo = top => {
    const myDiv = document.getElementById("scroll-tree");
    myDiv.scrollTop = top ? 0 : myDiv.scrollHeight;
  };

  onClickAdd = () => {
    this.props.setUserRoute(USER_ADD_PATH);
  };

  render() {
    return (
      <div>
        <ProgressBar ref="progress" />
        <div id="lv-filter-wrapper">
          <input
            id="filterWord"
            type="text"
            className="w-100 form-control lv-filter"
            placeholder="氏名で絞り込み (Escでクリア)"
            onChange={this.handleFilter}
            onKeyDown={this.clearFilter}
            defaultValue={this.state.filterKey}
          />
        </div>
        <div id="lv-summary" className="row no-gutters mt-2 px-1">
          <div className="col-8">
            <span className="lv-table-number">
              {this.props.userList.length}
              ユーザーを表示中
            </span>
          </div>
          <div className="col float-right">
            <ul className="list-inline float-right">
              <li id="scrollTop" className="list-inline-item">
                <div
                  className="icon-size-20 lv-scroll-top"
                  onClick={() => this.onClickScrollTo(true)}
                ></div>
              </li>
              <li id="scrollBottom" className="list-inline-item">
                <div
                  className="icon-size-20 lv-scroll-bottom"
                  onClick={() => this.onClickScrollTo(false)}
                ></div>
              </li>
            </ul>
          </div>
        </div>
        <div
          id="tree"
          className="row no-gutters list-view scrollbar scrollbar-primary"
        >
          <div id="search-result" className="tree force-overflow">
            <NavLink
              to={USER_ADD_PATH}
              className="account-link row lv-row-item no-gutters"
              activeClassName="active"
              onClick={this.onClickAdd}
            >
              <div className="p-2 d-flex align-items-center">
                <div className="icon-size-24 lv-ic-add mr-2" />
                <span className="lv-acc-name">ユーザー新規追加</span>
              </div>
            </NavLink>
            <TreeStyle id="scroll-tree">
              <UserItem
                data={this.props.userList}
              />
            </TreeStyle>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userList: state.userList,
    groupEdit: state.groupEdit
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setUserRoute: route => {
      dispatch(actions.setUserRoute(route));
    },
    setUserList: list => {
      dispatch(actions.setUserList(list));
    },
    editGroup: action => {
      dispatch(actions.editGroup(action));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserList);
