import React from "react";
import { Router, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { connect } from "react-redux";
import BaseComponent from "components/common/BaseComponent";
import HomePage from "components/home/HomePage";
import LoginPage from "components/login/Login";
import * as RoutePath from "constants/RoutePath";
import { getToken } from "lib/LocalStorageUtils";
import * as useractions from "actions/LoginActions";
import * as actions from "actions/Actions";
export const hist = createBrowserHistory();

let isWindowOpen = false;

class App extends BaseComponent {
  isSessionExpire = () => {
    const jwt = require("jsonwebtoken");
    const loginUser = getToken();
    if (loginUser) {
      const idToken = jwt.decode(loginUser.idToken);
      const nowTime = new Date();
      const expTime = idToken.exp * 1000;
      return nowTime.getTime() >= expTime;
    }
    return false;
  };

  isRedirectToLogin = () => {
    const isLogin = this.props.isLogin;
    const isExpire = this.isSessionExpire();
    let isRedirect = false;
    if (!isLogin) {
      isRedirect = true;
    } else {
      if (!isExpire || (isExpire && isWindowOpen)) {
        isRedirect = false;
      } else {
        localStorage.clear();
        this.props.logout();
        this.props.showExpMsg(true);
        isRedirect = true;
      }
    }
    return isRedirect;
  };

  componentDidMount() {
    isWindowOpen = true;
  }

  render() {
    const isRedirect = this.isRedirectToLogin();
    return (
      <Router history={hist}>
        <div>
          <Route
            path={RoutePath.ROOT_PATH}
            render={() =>
              isRedirect ? (
                hist.location.pathname !== RoutePath.LOGIN_PATH && (
                  <Redirect to={RoutePath.LOGIN_PATH} />
                )
              ) : (
                <HomePage path={hist.location.pathname} />
              )
            }
          />
          <Route path={RoutePath.LOGIN_PATH} exact component={LoginPage} />
        </div>
      </Router>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(useractions.logout());
    },
    showExpMsg: isShow => {
      dispatch(actions.showExpireMsg(isShow));
    },
    setRoute: route => {
      dispatch(actions.setPolicyRoute(route));
    }
  };
};

const mapStateToProps = state => {
  return {
    isLogin: state.loginState
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
