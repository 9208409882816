import _ from "lodash";
import values from "lodash/values";

const getMetaChildNodes = (node, dbNodes) => {
  if (!node.children) return [];
  return node.children.map(path => dbNodes[path]);
};

export const getMetaChildList = (node, dbNodes) => {
  let childNodeList = [];
  let childNodes1 = getMetaChildNodes(node, dbNodes);
  childNodes1.forEach(node1 => {
    childNodeList.push(node1);
    const childNodes2 = getMetaChildNodes(node1, dbNodes);
    childNodes2.forEach(node2 => {
      childNodeList.push(node2);
      const childNodes3 = getMetaChildNodes(node2, dbNodes);
      childNodes3.forEach(node3 => {
        childNodeList.push(node3);
      });
    });
  });
  return childNodeList;
};

export const groupMetaRes = (resourceList, dbNodes) => {
  let groupList = [];
  let list = resourceList.sort((a, b) => a.level - b.level);
  while (list.length) {
    let res = list[0];
    let isAll = true;
    let childList;
    if (res.type !== "table") {
      childList = getMetaChildList(res, dbNodes);
      for (let i = 0; i < childList.length; i++) {
        if (!resourceList.filter(e => e.path === childList[i].path).length) {
          isAll = false;
          break;
        }
      }
    } else {
      isAll = false;
    }
    if (isAll) {
      list = _.differenceBy(list, childList, "path");
      list = list.filter(el => el.path !== res.path);
      const group = `${res.path}/*`;
      groupList.push(group);
    } else {
      list = list.filter(el => el.path !== res.path);
      groupList.push(res.path);
    }
  }
  return groupList;
};

export const getMetaRes = dbNodes => {
  const statements = [];
  const canReadNodes = values(dbNodes).filter(
    node => node.isRead && !node.isWrite
  );
  const canReadWriteNodes = values(dbNodes).filter(
    node => node.isRead && node.isWrite
  );
  const metaRes = {
    readGroup: groupMetaRes(canReadNodes, dbNodes),
    readWriteGroup: groupMetaRes(canReadWriteNodes, dbNodes)
  };
  metaRes.readGroup.forEach(group => {
    const resAccess = {
      Action: ["read"],
      Resource: `orn:onigiri:db:meta/${group.replace("|", "\\")}`
    };
    statements.push(resAccess);
  });
  metaRes.readWriteGroup.forEach(group => {
    const resAccess = {
      Action: ["read", "update"],
      Resource: `orn:onigiri:db:meta/${group.replace("|", "\\")}`
    };
    statements.push(resAccess);
  });
  return statements;
};
