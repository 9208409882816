import React from "react";
import BaseComponent from "components/common/BaseComponent";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import { Link } from "react-router-dom";
import * as ApiConstants from "constants/ApiConstants";
import { getTargetUser, conversion } from "lib/LocalStorageUtils";
import { LIST_USERS } from "constants/LocalStorage";

class GroupItem extends BaseComponent {
  componentDidMount = async () => {
    if (!localStorage.getItem(LIST_USERS))
      this.getUsers().then(users => {
        localStorage.setItem(LIST_USERS, JSON.stringify(conversion(users)));
      });
  };

  // グループ取得
  getGroupInfo = async params => {
    this.runProgress();
    const query = {
      group_id: params[0]
    };
    const response = await this.executeApi(
      ApiConstants.GET_GROUP,
      null,
      query,
      null
    );
    this.stopProgress();
    const group = response.success ? response.data : {};
    if (group.user_list) {
      group.user_list = group.user_list.filter(user => {
        const targetUser = getTargetUser(user.user_id);
        if (targetUser !== undefined && targetUser != null) {
          const userName = targetUser.name;
          user.name = userName;
          user.id = user.user_id;
        } else {
          const requestParam = {
            user_id: user.user_id,
            valid_flag: false
          };
          const response = this.executeApi(
            ApiConstants.POST_USER,
            null,
            null,
            requestParam
          );
        }
        return targetUser !== undefined && targetUser != null;
      });
    }
    group.group_id = params[0];
    this.props.setGroupUser(group.user_list);
    this.props.setGroupPolicy(group.policy_list);
    this.props.selectGroup(group);
    this.props.setGroupRoute(params[1]);
  };

  render() {
    const { path, id, name, member, selectedGroup } = this.props;
    return (
      <div>
        <div
          className={
            name === selectedGroup.group_name
              ? "lv-instance active"
              : "lv-instance"
          }
          onClick={this.getGroupInfo.bind(this, [id, path])}
        >
          <div className="lv-group-item">
            <Link to={path} className="group-link">
              <div className="row no-gutters">
                <div className="col-6 p-0">
                  <span className="lv-no-group">{name}</span>
                </div>
                <div className="col-6 pr-2 lv-group-instance">
                  {member}ユーザー
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedGroup: state.selectedGroup
  };
};

const mapDispatchToProbs = dispatch => {
  return {
    selectGroup: group => {
      dispatch(actions.selectGroup(group));
    },
    setGroupUser: users => {
      dispatch(actions.setGroupUser(users));
    },
    setGroupPolicy: policy => {
      dispatch(actions.setGroupPolicy(policy));
    },
    setGroupRoute: route => {
      dispatch(actions.setGroupRoute(route));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(GroupItem);
