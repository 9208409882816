import * as types from "constants/ActionTypes";

const initState = {
  group: {
    isManage: false
  },
  topic: {
    isDeleteTopic: false,
    isDeleteCmt: false
  },
  company: {
    isCreate: false,
    isDelete: false
  },
  tag: {
    isCreate: false,
    isDelete: false
  }
};

const dbCommonRes = (state = initState, action) => {
  switch (action.type) {
    case types.SET_GROUP_RES:
      return {
        ...state,
        group: action.group
      };
    case types.SET_TOPIC_RES:
      return {
        ...state,
        topic: action.topic
      };
    case types.SET_COMPANY_RES:
      return {
        ...state,
        company: action.company
      };
    case types.SET_TAG_RES:
      return {
        ...state,
        tag: action.tag
      };
    default:
      return state;
  }
};

export default dbCommonRes;
