import * as types from "constants/ActionTypes";

var initState = true;

var currentTheme = (state = initState, action) => {
  if (action.type === types.DARK_MODE) state = "dark";
  if (action.type === types.LIGHT_MODE) state = "light";
  return state;
};

export default currentTheme;
