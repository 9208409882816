import * as types from "constants/ActionTypes";

var initState = "";

var groupEdit = (state = initState, action) => {
  if (action.type === types.EDIT_GROUP) {
    state = action.editAction;
  }
  return state;
};

export default groupEdit;
