import React from "react";
import BaseComponent from "components/common/BaseComponent";
import { Route } from "react-router-dom";
import * as RoutePath from "constants/RoutePath";
import PolicyList from "components/listview/policy/PolicyList";
import GroupList from "components/listview/group/GroupList";
import UserList from "components/listview/user/UserList";
import SettingList from "components/listview/setting/SettingList";

class ListView extends BaseComponent {
  render() {
    return (
      <div id="list-view" className="list-view">
        <Route path={RoutePath.POLICY_PATH} component={PolicyList} />
        <Route path={RoutePath.GROUP_PATH} component={GroupList} />
        <Route path={RoutePath.USER_PATH} component={UserList} />
        <Route path={RoutePath.SETTING_PATH} component={SettingList} />
      </div>
    );
  }
}

export default ListView;
