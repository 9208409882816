import BaseComponent from "components/common/BaseComponent";
import React from "react";
import { connect } from "react-redux";
import MetaList from "components/content/policy/oniDbRes/metaData/MetaList";
import CodeList from "./oniDbRes/dbCode/CodeList";
import JsonEditor from "./json/JsonEditor";
import Group from "./oniDbRes/dbGroup/Group";
import Topic from "./oniDbRes/Topic";
import Company from "./oniDbRes/Company";
import Tag from "./oniDbRes/Tag";
import { DB_RESOURCE_TYPE } from "constants/CommonConstants";
import { getMetaRes } from "components/content/policy/oniDbRes/metaData/MetaAM";
import { getCodeRes } from "components/content/policy/oniDbRes/dbCode/CodeAM";
import * as actions from "actions/Actions";

const GUI_MODE = "GUI";
const JSON_MODE = "JSON";

class DBPolicy extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      mode: GUI_MODE,
      metaState: [],
      codeState: [],
      groupState: [],
      topicState: [],
      companyState: [],
      tagState: []
    };
  }

  componentDidUpdate = previousProps => {
    if (this.props.mode === "editPolicy") {
      if (
        JSON.stringify(previousProps.initStatement) !==
        JSON.stringify(this.props.initStatement)
      ) {
        this.initStatement();
      }
    }
  };

  componentDidMount = async () => {
    // edit mode
    if (this.props.mode === "editPolicy") {
      this.initStatement();
    }
  };

  initStatement = () => {
    const {
      metaState,
      codeState,
      groupState,
      topicState,
      companyState
    } = this.props.initStatement;
    this.setState({
      metaState,
      codeState,
      groupState,
      topicState,
      companyState
    });
  };

  onSelectTab = tab => {
    if (tab === this.state.mode) return;
    this.setState({
      mode: tab
    });
    if (tab === GUI_MODE) {
      this.props.isJsonError(false);
    }
  };

  setJsonData = (type, isCreate = false, permision = {}) => {
    const { metaData, codeData } = this.props;
    const {
      metaState,
      codeState,
      groupState,
      topicState,
      companyState,
      tagState
    } = this.state;

    let statements = [];
    switch (type) {
      case DB_RESOURCE_TYPE.META:
        // get meta resoure access permission
        const meta = getMetaRes(metaData.dbNodes);
        this.setState({
          metaState: meta
        });
        statements = statements.concat(
          meta,
          codeState,
          groupState,
          topicState,
          companyState,
          tagState
        );
        break;
      case DB_RESOURCE_TYPE.CODE:
        // get code resoure access permission
        const code = getCodeRes(codeData.codeNodes, isCreate);
        this.setState({
          codeState: code
        });
        statements = statements.concat(
          metaState,
          code,
          groupState,
          topicState,
          companyState,
          tagState
        );
        break;
      case DB_RESOURCE_TYPE.GROUP:
        // get group resoure access permission
        // TODO
        break;
      case DB_RESOURCE_TYPE.TOPIC:
        // get topic resoure access permission
        const topicAction = [];
        let topic = null;
        if (permision.isDeleteTopic) topicAction.push("deleteTopic");
        if (permision.isDeleteCmt) topicAction.push("deleteCmt");
        if (topicAction.length) {
          topic = {
            Action: topicAction,
            Resource: "orn:onigiri:db:topic"
          };
        }
        this.setState({
          topicState: topic
        });
        statements = statements.concat(
          metaState,
          codeState,
          groupState,
          topic,
          companyState,
          tagState
        );
        break;
      case DB_RESOURCE_TYPE.COMPANY:
        // get company resoure access permission
        const companyAction = [];
        let company = null;
        if (permision.isDelete) companyAction.push("delete");
        if (permision.isCreate) companyAction.push("create");
        if (companyAction.length) {
          company = {
            Action: companyAction,
            Resource: "orn:onigiri:db:company"
          };
        }
        this.setState({
          companyState: company
        });
        statements = statements.concat(
          metaState,
          codeState,
          groupState,
          topicState,
          company,
          tagState
        );
        break;
      case DB_RESOURCE_TYPE.TAG:
        // get tag resoure access permission
        const tagAction = [];
        let tag = null;
        if (permision.isDelete) tagAction.push("delete");
        if (permision.isCreate) tagAction.push("create");
        if (tagAction.length) {
          tag = {
            Action: tagAction,
            Resource: "orn:onigiri:db:tag"
          };
        }
        this.setState({
          tagState: tag
        });
        statements = statements.concat(
          metaState,
          codeState,
          groupState,
          topicState,
          companyState,
          tag
        );
        break;
      default:
        break;
    }
    const jsonObject = {
      Statement: statements.filter(e => e)
    };
    // set json data
    this.props.setDbJson(jsonObject);
  };

  createTab = () => {
    const { mode } = this.state;
    if (mode === JSON_MODE) {
      return (
        <div className="pb-3">
          <JsonEditor initData={this.props.initJson} />
        </div>
      );
    } else if (mode === GUI_MODE) {
      return (
        <React.Fragment>
          <div className="pb-2">
            <div className="row no-gutters">
              <div className="key col-2 detail-policy-sub-title">メタデータ</div>
              <div className="col mt-1">
                <MetaList setJsonData={this.setJsonData} />
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col custom-border-bottom"></div>
          </div>
          <div className="pb-2">
            <div className="row no-gutters">
              <div className="key col-2 detail-policy-sub-title">コード定義</div>
              <div className="col">
                <CodeList setJsonData={this.setJsonData} />
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col custom-border-bottom"></div>
          </div>
          <div className="pb-2">
            <div className="row no-gutters">
              <div className="key col-2 detail-policy-sub-title">
                グループ
              </div>
              <div className="col">
                <Group setJsonData={this.setJsonData} />
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col custom-border-bottom"></div>
          </div>
          <div className="pb-2">
            <div className="row no-gutters">
              <div className="key col-2 detail-policy-sub-title">トピック</div>
              <div className="col">
                <Topic setJsonData={this.setJsonData} />
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col custom-border-bottom"></div>
          </div>
          <div className="pb-2">
            <div className="row no-gutters">
              <div className="key col-2 detail-policy-sub-title">会社管理</div>
              <div className="col">
                <Company setJsonData={this.setJsonData} />
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col custom-border-bottom"></div>
          </div>
          <div className="pb-2">
            <div className="row no-gutters">
              <div className="key col-2 detail-policy-sub-title">タグ</div>
              <div className="col">
                <Tag setJsonData={this.setJsonData} />
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  };

  render() {
    const { mode } = this.state;
    return (
      <div>
        {/* <div
          id="headMenu"
          className="row position-static flex-nowrap m-0 text-white policy-menu"
        >
          <div id="tabMenu" className="mr-2">
            <ul className="nav d-flex flex-nowrap">
              <li
                className="nav-item detail-header"
                onClick={() => this.onSelectTab(GUI_MODE)}
              >
                <a
                  className={mode === GUI_MODE ? "nav-link active" : "nav-link"}
                  href="#definition-dsp"
                  data-target="#definition-dsp"
                  data-toggle="tab"
                  id="sample-btn-dsp"
                >
                  GUI
                </a>
              </li>
              <li
                className="nav-item detail-header"
                onClick={() => this.onSelectTab(JSON_MODE)}
              >
                <a
                  className={
                    mode === JSON_MODE ? "nav-link active" : "nav-link"
                  }
                  href="#definition-edt"
                  data-target="#definition-edt"
                  data-toggle="tab"
                  id="sample-btn-edt"
                >
                  JSON
                </a>
              </li>
            </ul>
          </div>
        </div> */}
        {this.createTab()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    metaData: state.dbMetaData,
    codeData: state.dbCodeData,
    selectedPolicy: state.selectedPolicy
  };
};

const mapDispatchToProbs = dispatch => {
  return {
    setDbJson: json => {
      dispatch(actions.setDbJson(json));
    },
    isJsonError: isError => {
      dispatch(actions.isJsonError(isError));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(DBPolicy);
