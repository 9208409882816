import * as types from "constants/ActionTypes";

var initState = {};

var dbCodeNodes = (state = initState, action) => {
  if (action.type === types.SET_CODE_NODES) {
    state = action.codeNodes;
  }
  return state;
};

export default dbCodeNodes;
