import React from "react";
import BaseComponent from "components/common/BaseComponent";
import * as ApiConstants from "constants/ApiConstants";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import CustomCheckbox from "components/common/CustomCheckbox";
import { MODAL_TYPE } from "constants/CommonConstants";
import { conversion } from "lib/LocalStorageUtils";
import { LIST_USERS, LIST_POLICY, GROUP_LIST } from "constants/LocalStorage";

// user select modal
class UserData extends BaseComponent {
  constructor(props) {
    super(props);
    const { userList, onReceiveUserList, groupEditUsers } = this.props;
    let checkList = {};
    userList.forEach(user => {
      if (groupEditUsers.filter(data => data.id === user.id).length) {
        checkList[user.id] = true;
      } else checkList[user.id] = false;
    });
    onReceiveUserList(checkList);
    this.state = {
      checkList: checkList
    };
  }

  handleSelect = e => {
    const { name, checked } = e.target;
    const { onReceiveUserList } = this.props;
    let checkList = this.state.checkList;
    checkList[name] = checked;
    onReceiveUserList(checkList);
    this.setState({ checkList: checkList });
  };

  handleSelectAll = e => {
    const { checked } = e.target;
    const { onReceiveUserList } = this.props;
    let checkList = this.state.checkList;
    Object.keys(checkList).forEach(key => {
      checkList[key] = checked;
    });
    onReceiveUserList(checkList);
    this.setState({ checkList: checkList });
  };
  render() {
    const { searchKey, userList } = this.props;
    const { checkList } = this.state;
    if (!userList) return null;
    const filterUsers = userList.filter(user =>
      user.name.toUpperCase().includes(searchKey.toUpperCase())
    );
    // sort by name
    filterUsers.sort(function(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return (
      <div>
        <div className=" mdl-grid mdl-list-modal-user">
          <span className="pl-1">
            <CustomCheckbox
              name="user-header"
              defaultChecked={false}
              onChange={this.handleSelectAll}
            />
          </span>
          <span className="modal-text">名前</span>
          <span className="modal-text">メールアドレス</span>
          <span className="modal-text">所属グループ数</span>
        </div>
        {filterUsers.map((user, index) => (
          <div className=" mdl-grid mdl-list-modal-user" key={index}>
            <span className="pl-1">
              <CustomCheckbox
                name={user.id}
                checked={checkList[user.id]}
                onChange={this.handleSelect}
              />
            </span>
            <span className="modal-text">{user.name}</span>
            <span className="modal-text">{user.Username}</span>
            <span className="modal-text">{user.group_list.length}</span>
          </div>
        ))}
      </div>
    );
  }
}

// group select modal
class GroupData extends BaseComponent {
  constructor(props) {
    super(props);
    const { groupList, onReceiveGroupList, userEditGroup } = this.props;
    let checkList = {};
    groupList.forEach(group => {
      if (
        userEditGroup.filter(data => data.group_id === group.group_id).length
      ) {
        checkList[group.group_id] = true;
      } else checkList[group.group_id] = false;
    });
    onReceiveGroupList(checkList);
    this.state = {
      checkList: checkList
    };
  }

  handleSelect = e => {
    const { name, checked } = e.target;
    const { onReceiveGroupList } = this.props;
    let checkList = this.state.checkList;
    checkList[name] = checked;
    onReceiveGroupList(checkList);
    this.setState({ checkList: checkList });
  };

  handleSelectAll = e => {
    const { checked } = e.target;
    const { onReceiveGroupList } = this.props;
    let checkList = this.state.checkList;
    Object.keys(checkList).forEach(key => {
      checkList[key] = checked;
    });
    onReceiveGroupList(checkList);
    this.setState({ checkList: checkList });
  };

  render() {
    const { searchKey, groupList } = this.props;
    const { checkList } = this.state;
    const filterGroups = groupList.filter(group =>
      group.group_name.toUpperCase().includes(searchKey.toUpperCase())
    );
    return (
      <div>
        <div className=" mdl-grid mdl-list-modal-group">
          <span className="pl-1">
            <CustomCheckbox
              name="group-header"
              defaultChecked={false}
              onChange={this.handleSelectAll}
            />
          </span>
          <span className="modal-text">グループ名</span>
          <span className="modal-text">所属ユーザー数</span>
        </div>
        {filterGroups.map((group, index) => (
          <div className=" mdl-grid mdl-list-modal-policy" key={index}>
            <span className="pl-1">
              <CustomCheckbox
                name={group.group_id}
                checked={checkList[group.group_id]}
                onChange={this.handleSelect}
              />
            </span>
            <span className="modal-text">{group.group_name}</span>
            <span className="modal-text">{group.affiliation_count}</span>
          </div>
        ))}
      </div>
    );
  }
}

// policy select modal
class PolicyData extends BaseComponent {
  constructor(props) {
    super(props);
    const { policyList, onReceivePolicyList, editPolicy } = this.props;
    let checkList = {};
    policyList.forEach(policy => {
      if (
        editPolicy.filter(data => data.policy_id === policy.policy_id).length
      ) {
        checkList[policy.policy_id] = true;
      } else checkList[policy.policy_id] = false;
    });
    onReceivePolicyList(checkList);
    this.state = {
      checkList: checkList
    };
  }

  handleSelect = e => {
    const { name, checked } = e.target;
    const { onReceivePolicyList } = this.props;
    let checkList = this.state.checkList;
    checkList[name] = checked;
    onReceivePolicyList(checkList);
    this.setState({ checkList: checkList });
  };

  handleSelectAll = e => {
    const { checked } = e.target;
    const { onReceivePolicyList } = this.props;
    let checkList = this.state.checkList;
    Object.keys(checkList).forEach(key => {
      checkList[key] = checked;
    });
    onReceivePolicyList(checkList);
    this.setState({ checkList: checkList });
  };

  render() {
    const { searchKey, policyList } = this.props;
    const { checkList } = this.state;
    const filterPolicies = policyList.filter(policy =>
      policy.policy_name.toUpperCase().includes(searchKey.toUpperCase())
    );
    return (
      <div>
        <div className=" mdl-grid mdl-list-modal-policy">
          <span className="pl-1">
            <CustomCheckbox
              name="policy-header"
              defaultChecked={false}
              onChange={this.handleSelectAll}
            />
          </span>
          <span className="modal-text">ポリシー名</span>
          <span className="modal-text">説明</span>
        </div>
        {filterPolicies.map((policy, index) => (
          <div className=" mdl-grid mdl-list-modal-policy" key={index}>
            <span className="pl-1">
              <CustomCheckbox
                name={policy.policy_id}
                checked={checkList[policy.policy_id]}
                onChange={this.handleSelect}
              />
            </span>
            <span className="modal-text">{policy.policy_name}</span>
            <span className="modal-text">{policy.description}</span>
          </div>
        ))}
      </div>
    );
  }
}

class UserGroupData extends BaseComponent {
  constructor(props) {
    super(props);
    const {
      groupList,
      userList,
      onReceiveUserGroupList,
      onReceivePolicyUser,
      policyEditUser
    } = this.props;
    let checkList = {};
    let groupUserList = [];
    groupList.forEach(group => {
      if (
        !policyEditUser.filter(
          data => data.type === 1 && data.id === group.group_id.toString(10)
        ).length
      ) {
        checkList[group.group_id] = false;
        const groupItem = {
          type: 1,
          id: group.group_id.toString(10),
          name: group.group_name
        };
        groupUserList.push(groupItem);
      }
    });
    userList.forEach(user => {
      if (
        !policyEditUser.filter(data => data.type === 0 && data.id === user.id)
          .length
      ) {
        checkList[user.id] = false;
        const userItem = {
          type: 0,
          id: user.id,
          name: user.name,
        };
        groupUserList.push(userItem);
      }
    });
    onReceivePolicyUser(groupUserList);
    onReceiveUserGroupList(checkList);
    this.state = {
      checkList: checkList,
      groupUserList: groupUserList
    };
  }

  handleSelect = e => {
    const { name, checked } = e.target;
    const { onReceiveUserGroupList } = this.props;
    let checkList = this.state.checkList;
    checkList[name] = checked;
    onReceiveUserGroupList(checkList);
    this.setState({ checkList: checkList });
  };

  handleSelectAll = e => {
    const { checked } = e.target;
    const { onReceiveUserGroupList } = this.props;
    let checkList = this.state.checkList;
    Object.keys(checkList).forEach(key => {
      checkList[key] = checked;
    });
    onReceiveUserGroupList(checkList);
    this.setState({ checkList: checkList });
  };

  render() {
    const { searchKey } = this.props;
    const { checkList, groupUserList } = this.state;
    const filterGroupUsers = groupUserList.filter(data =>
      data.name.toUpperCase().includes(searchKey.toUpperCase())
    );
    filterGroupUsers.sort(function(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return (
      <div>
        <div className=" mdl-grid mdl-list-modal-user">
          <span className="pl-1">
            <CustomCheckbox
              name="user-group-header"
              defaultChecked={false}
              onChange={this.handleSelectAll}
            />
          </span>
          <span className="modal-text">名前</span>
          <span className="modal-text">タイプ</span>
        </div>
        {filterGroupUsers.map((data, index) => (
          <div className=" mdl-grid mdl-list-modal-user" key={index}>
            <span className="pl-1">
              <CustomCheckbox
                name={data.id}
                checked={checkList[data.id]}
                onChange={this.handleSelect}
              />
            </span>
            <span className="modal-text">{data.name}</span>
            <span className="modal-text">
              {" "}
              {data.type ? "グループ" : "ユーザー"}
            </span>
          </div>
        ))}
      </div>
    );
  }
}

class SelectModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      userCheckList: {},
      groupCheckList: {},
      policyCheckList: {},
      userGroupCheckList: {},
      policyUser: []
    };
  }

  componentWillMount = async () => {
    await this.getUserList();
    await this.getGroupList();
    await this.getPolicyList();
  };

  getUserInfos = async userIds => {
    const requestParam = {
      user_ids: userIds.join(",")
    };
    const response = await this.executeApi(
      ApiConstants.GET_USER,
      null,
      null,
      requestParam
    );
    return response.success ? response.data : {};
  };

  getUserList = async () => {
    let userList = [];
    await this.getUsers().then(users => {
      const converted = conversion(users);
      localStorage.setItem(LIST_USERS, JSON.stringify(converted));
      userList = converted;
    });
    const userIds = userList.map(user => user.id);
    const userInfos = await this.getUserInfos(userIds);
    userList.forEach(user => {
      const userInfo = userInfos.user_list.filter(info => info.user_id === user.id);
      if (userInfo.length) {
        user.policy_list = userInfo[0].policy_list;
        user.group_list = userInfo[0].group_list;
      }
    });
    this.props.setUserList(userList);
    return userList;
  };

  getGroupList = async () => {
    const groupListLocal = localStorage.getItem(GROUP_LIST);
    const response = await this.executeApi(ApiConstants.GET_GROUPS);
    const groupListNew = response.success ? response.data : {};
    if (groupListLocal !== JSON.stringify(groupListNew.group_list)) {
      localStorage.setItem(GROUP_LIST, JSON.stringify(groupListNew.group_list));
      if (groupListNew.group_list)
        this.props.setGroupList(groupListNew.group_list);
    } else {
      this.props.setGroupList(JSON.parse(groupListLocal));
    }
    return groupListNew;
  };

  getPolicyList = async () => {
    const policyListLocal = localStorage.getItem(LIST_POLICY);
    const response = await this.executeApi(ApiConstants.GET_POLICIES);
    const policyListNew = response.success ? response.data : {};
    if (policyListLocal !== JSON.stringify(policyListNew.policy_list)) {
      localStorage.setItem(
        LIST_POLICY,
        JSON.stringify(policyListNew.policy_list)
      );
      if (policyListNew.policy_list)
        this.props.setPolicyList(policyListNew.policy_list);
    } else {
      this.props.setPolicyList(JSON.parse(policyListLocal));
    }
    return policyListNew;
  };

  onKeyChange = e => {
    const { value } = e.target;
    this.setState({
      searchKey: value
    });
  };

  addUserToGroup = () => {
    const { userCheckList } = this.state;
    const { userList } = this.props;
    const groupUsers = userList.filter(
      user => userCheckList[user.id] === true
    );
    this.props.setGroupUser(groupUsers);
    this.closeModal();
  };

  addPolicyToGroup = () => {
    const { policyCheckList } = this.state;
    const { policyList } = this.props;
    const groupPolicy = policyList.filter(
      policy => policyCheckList[policy.policy_id] === true
    );
    this.props.setGroupPolicy(groupPolicy);
    this.closeModal();
  };

  addPolicyToUser = () => {
    const { policyCheckList } = this.state;
    const { policyList } = this.props;
    const userPolicy = policyList.filter(
      policy => policyCheckList[policy.policy_id] === true
    );
    this.props.setUserPolicy(userPolicy);
    this.closeModal();
  };

  addGroupToUser = () => {
    const { groupCheckList } = this.state;
    const { groupList } = this.props;
    const userGroup = groupList.filter(
      group => groupCheckList[group.group_id] === true
    );
    this.props.setUserGroup(userGroup);
    this.closeModal();
  };

  addUserGroupToPolicy = () => {
    const { userGroupCheckList, policyUser } = this.state;
    const { policyEditUser } = this.props;
    let users = policyUser.filter(
      user => userGroupCheckList[user.id] === true
    );
    users = users.concat(policyEditUser);
    this.props.setPolicyUser(users);
    this.closeModal();
  };

  onReceiveUserList = checkList => {
    this.setState({ userCheckList: checkList });
  };

  onReceiveGroupList = checkList => {
    this.setState({ groupCheckList: checkList });
  };

  onReceivePolicyList = checkList => {
    this.setState({ policyCheckList: checkList });
  };

  onReceivePolicyUser = users => {
    this.setState({ policyUser: users });
  };
  onReceiveUserGroupList = checkList => {
    this.setState({ userGroupCheckList: checkList });
  };

  closeModal = () => {
    this.setState({
      searchKey: ""
    });
    this.props.closeModal();
  };
  render() {
    const { searchKey } = this.state;
    const {
      selectModal,
      userList,
      groupEditUsers,
      policyList,
      groupEditPolicy,
      userEditPolicy,
      userEditGroup,
      policyEditUser,
      groupList
    } = this.props;

    const { isOpenModal, modalType } = selectModal;
    let modalTilte = "";
    let placeHolder = "";
    switch (modalType) {
      case MODAL_TYPE.USER:
        modalTilte = "ユーザーの選択";
        placeHolder = "氏名で絞り込み(Escでクリア)";
        break;
      case MODAL_TYPE.GROUP:
        modalTilte = "グループの選択";
        placeHolder = "グループ名で絞り込み(Escでクリア)";
        break;
      case MODAL_TYPE.POLICY_GROUP:
      case MODAL_TYPE.POLICY_USER:
        modalTilte = "ボリシーの選択";
        placeHolder = "ポリシー名で絞り込み(Escでクリア)";
        break;
      case MODAL_TYPE.USER_GROUP:
        modalTilte = "ユーザーまたはグループの選択";
        placeHolder = "氏名またはグループ名で絞り込み(Escでクリア)";
        break;
      default:
        break;
    }
    return (
      <div>
        {/* user modal*/}
        <ReactModal
          isOpen={isOpenModal}
          onRequestClose={event => {
            // Ignore react-modal esc-close handling
            if (event.type === "keydown" && event.keyCode === 27) return;
            this.closeModal();
          }}
          className="code-definition-modal modal-dialog modal-dialog-centered modal-lg mdl-lg"
          overlayClassName="code-definition-overray"
        >
          <div className="modal-content code-definition-modal-bg">
            <div className="modal-header border-bottom-0">
              <div
                className="modal-close mdl-btn-close"
                onClick={() => this.closeModal()}
              >
                ×
              </div>
              <h4 className="mdl-ttl">{modalTilte}</h4>
            </div>
            <div className="modal-body">
              <div className="px-5">
                <div className="mdl-grid mdl-w50">
                  <div id="lv-filter-wrapper">
                    <input
                      type="text"
                      className="w-100 form-control lv-filter"
                      placeholder={placeHolder}
                      onChange={this.onKeyChange}
                    />
                  </div>
                </div>
                <div className="mdl-list-modal-outer">
                  {modalType === MODAL_TYPE.USER && (
                    <UserData
                      searchKey={searchKey}
                      userList={userList}
                      initData={selectModal.initData}
                      groupEditUsers={groupEditUsers}
                      onReceiveUserList={this.onReceiveUserList}
                    />
                  )}
                  {modalType === MODAL_TYPE.GROUP && (
                    <GroupData
                      searchKey={searchKey}
                      groupList={groupList}
                      initData={selectModal.initData}
                      userEditGroup={userEditGroup}
                      onReceiveGroupList={this.onReceiveGroupList}
                    />
                  )}
                  {modalType === MODAL_TYPE.POLICY_GROUP && (
                    <PolicyData
                      searchKey={searchKey}
                      policyList={policyList}
                      initData={selectModal.initData}
                      editPolicy={groupEditPolicy}
                      onReceivePolicyList={this.onReceivePolicyList}
                    />
                  )}
                  {modalType === MODAL_TYPE.POLICY_USER && (
                    <PolicyData
                      searchKey={searchKey}
                      policyList={policyList}
                      initData={selectModal.initData}
                      editPolicy={userEditPolicy}
                      onReceivePolicyList={this.onReceivePolicyList}
                    />
                  )}
                  {modalType === MODAL_TYPE.USER_GROUP && (
                    <UserGroupData
                      searchKey={searchKey}
                      userList={userList}
                      groupList={groupList}
                      onReceiveUserGroupList={this.onReceiveUserGroupList}
                      onReceivePolicyUser={this.onReceivePolicyUser}
                      policyEditUser={policyEditUser}
                    />
                  )}
                </div>
                <div className="modal-bottom-right pt-5">
                  {modalType === MODAL_TYPE.USER && (
                    <input
                      type="button"
                      className="btn btn-primary btn-modal"
                      value="選択したユーザーを追加"
                      onClick={this.addUserToGroup}
                    />
                  )}
                  {modalType === MODAL_TYPE.GROUP && (
                    <input
                      type="button"
                      className="btn btn-primary"
                      value="選択したグループに追加"
                      onClick={this.addGroupToUser}
                    />
                  )}
                  {modalType === MODAL_TYPE.POLICY_GROUP && (
                    <input
                      type="button"
                      className="btn btn-primary btn-modal"
                      value="選択したポリシーをアタッチ"
                      onClick={this.addPolicyToGroup}
                    />
                  )}
                  {modalType === MODAL_TYPE.POLICY_USER && (
                    <input
                      type="button"
                      className="btn btn-primary btn-modal"
                      value="選択したポリシーをアタッチ"
                      onClick={this.addPolicyToUser}
                    />
                  )}
                  {modalType === MODAL_TYPE.USER_GROUP && (
                    <input
                      type="button"
                      className="btn btn-primary btn-modal"
                      value="選択したユーザーまたはグループにアタッチ"
                      onClick={this.addUserGroupToPolicy}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectModal: state.selectModal,
    userList: state.userList,
    groupEditUsers: state.groupEditUsers,
    policyList: state.policyList,
    groupEditPolicy: state.groupEditPolicy,
    userEditPolicy: state.userEditPolicy,
    userEditGroup: state.userEditGroup,
    groupList: state.groupList,
    policyEditUser: state.policyEditUser
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => {
      dispatch(actions.closeModal());
    },
    setUserList: list => {
      dispatch(actions.setUserList(list));
    },
    setPolicyList: list => {
      dispatch(actions.setPolicyList(list));
    },
    setPolicyUser: user => {
      dispatch(actions.setPolicyUser(user));
    },
    setGroupUser: users => {
      dispatch(actions.setGroupUser(users));
    },
    setGroupPolicy: policy => {
      dispatch(actions.setGroupPolicy(policy));
    },
    setUserPolicy: policy => {
      dispatch(actions.setUserPolicy(policy));
    },
    setUserGroup: group => {
      dispatch(actions.setUserGroup(group));
    },
    setGroupList: groupList => {
      dispatch(actions.setGroupList(groupList));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectModal);
