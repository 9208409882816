import React from "react";
import BaseComponent from "components/common/BaseComponent";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import DeleteIcon from "images/ic_delete.svg";
import { GROUP_DETAIL_PATH, USER_DETAIL_PATH } from "constants/RoutePath";
import { LISTVIEW_TYPE, GROUP_LIST } from "constants/LocalStorage";
import * as ListViewTypes from "constants/ListViewTypes";
import { hist } from "App";
import CustomCheckbox from "components/common/CustomCheckbox";
import * as ApiConstants from "constants/ApiConstants";

// User, Group for Policy
class PolicyEditUser extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      checkList: {}
    };
  }

  componentDidMount = async () => {
    await this.getGroupList();
  };

  componentDidUpdate(previousProps) {
    // on edit cancel
    if (this.props.groupEdit === "cancelEdit") {
      this.props.editGroup("");
      const useStatus = this.props.selectedPolicy.use_status;
      useStatus.forEach(user => (user.isChecked = false));
      useStatus.sort(function(a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.setState({
        checkList: {}
      });
      this.props.setPolicyUser(useStatus);
    }
  }

  getGroupList = async () => {
    const groupListLocal = localStorage.getItem(GROUP_LIST);
    const response = await this.executeApi(ApiConstants.GET_GROUPS);
    const groupListNew = response.success ? response.data : {group_list: []};
    if (groupListLocal !== JSON.stringify(groupListNew.group_list)) {
      localStorage.setItem(GROUP_LIST, JSON.stringify(groupListNew.group_list));
      this.props.setGroupList(groupListNew.group_list);
    } else {
      this.props.setGroupList(JSON.parse(groupListLocal));
    }
  };

  removeUserFromGroup = id => {
    const { editUserGroup } = this.props;
    const removeList = editUserGroup.filter(user => user.id !== id);
    this.props.setPolicyUser(removeList);
  };

  goToUserGroup = user => {
    // go to user
    if (user.type === 0) {
      const route = `${USER_DETAIL_PATH}/${user.id}/info`;
      this.props.setUserRoute(decodeURI(route));
      this.props.showUser();
      hist.push(decodeURI(route));
      localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.USER);
    }
    // go to group
    if (user.type === 1) {
      const route = `${GROUP_DETAIL_PATH}/${user.name}/info`;
      this.props.setGroupRoute(decodeURI(route));
      this.props.showGroup();
      hist.push(decodeURI(route));
      localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.GROUP);
    }
  };

  handleSelect = e => {
    const { name, checked } = e.target;
    const { editUserGroup } = this.props;
    const index = editUserGroup.findIndex(e => e.id === name);
    editUserGroup[index].isChecked = checked;
    this.props.setPolicyUser(editUserGroup);
    this.forceUpdate();
    this.props.editGroup("editPolicyUser");
  };

  render() {
    const { mode, editUserGroup } = this.props;
    editUserGroup.sort(function(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return (
      <div className="mdl-list-outer-group-user">
        {editUserGroup.map((user, index) => (
          <div className="row no-gutters" key={index}>
            <div className="col-2 d-flex justify-content-end align-items-center">
              {mode === "add" && (
                <img
                  src={DeleteIcon}
                  className="icon-size-15"
                  alt="Delete"
                  onClick={this.removeUserFromGroup.bind(this, user.id)}
                />
              )}
              {mode === "edit" && (
                <CustomCheckbox
                  name={user.id}
                  checked={user.isChecked}
                  onChange={this.handleSelect}
                />
              )}
            </div>
            <div className="col">
              <div className=" mdl-grid mdl-list-user">
                <span
                  className="key text-primary text-wrap pointer pl-2"
                  onClick={this.goToUserGroup.bind(this, user)}
                >
                  {user.name}
                </span>
                <span className="value">
                  {user.type ? "グループ" : "ユーザー"}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    editUserGroup: state.policyEditUser,
    groupList: state.groupList,
    userList: state.userList,
    groupEdit: state.groupEdit,
    selectedPolicy: state.selectedPolicy
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setPolicyUser: user => {
      dispatch(actions.setPolicyUser(user));
    },
    editGroup: action => {
      dispatch(actions.editGroup(action));
    },
    setPolicyRoute: route => {
      dispatch(actions.setPolicyRoute(route));
    },
    setGroupList: groupList => {
      dispatch(actions.setGroupList(groupList));
    },
    setCompanyRes: company => {
      dispatch(actions.setCompanyRes(company));
    },
    setUserRoute: route => {
      dispatch(actions.setUserRoute(route));
    },
    setGroupRoute: route => {
      dispatch(actions.setGroupRoute(route));
    },
    showGroup: () => {
      dispatch(actions.showGroup());
    },
    showUser: () => {
      dispatch(actions.showUser());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PolicyEditUser);
