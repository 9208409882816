import _ from "lodash";
import values from "lodash/values";

const getCodeChildNodes = (node, codeNodes) => {
  if (!node.children) return [];
  return node.children.map(path => codeNodes[path]);
};

export const getCodeChildList = (node, codeNodes) => {
  let childNodeList = [];
  let childNodes1 = getCodeChildNodes(node, codeNodes);
  childNodes1.forEach(node1 => {
    childNodeList.push(node1);
    const childNodes2 = getCodeChildNodes(node1, codeNodes);
    childNodes2.forEach(node2 => {
      childNodeList.push(node2);
    });
  });
  return childNodeList;
};

export const groupCodeRes = (resourceList, codeNodes) => {
  let groupList = [];
  let list = resourceList.sort((a, b) => a.level - b.level);
  while (list.length) {
    let res = list[0];
    let isAll = true;
    let childList;
    if (res.type !== "code") {
      childList = getCodeChildList(res, codeNodes);
      for (let i = 0; i < childList.length; i++) {
        if (!resourceList.filter(e => e.path === childList[i].path).length) {
          isAll = false;
          break;
        }
      }
    } else {
      isAll = false;
    }
    if (isAll) {
      list = _.differenceBy(list, childList, "path");
      list = list.filter(el => el.path !== res.path);
      const group = `${res.path}/*`;
      groupList.push(group);
    } else {
      list = list.filter(el => el.path !== res.path);
      groupList.push(res.path);
    }
  }
  return groupList;
};

export const getCodeRes = (codeNodes, isCreate) => {
  const statements = [];
  if (isCreate) {
    const resAccess = {
      Action: ["create"],
      Resource: "orn:onigiri:db:code"
    };
    statements.push(resAccess);
  }
  const canRnodes = values(codeNodes).filter(
    node => node.isRead && !node.isWrite && !node.isDelete
  );
  const canWnodes = values(codeNodes).filter(
    node => !node.isRead && node.isWrite && !node.isDelete
  );
  const canDnodes = values(codeNodes).filter(
    node => node.isDelete && !node.isRead && !node.isWrite
  );
  const canRWnodes = values(codeNodes).filter(
    node => node.isRead && node.isWrite && !node.isDelete
  );
  const canRDnodes = values(codeNodes).filter(
    node => node.isRead && node.isDelete && !node.isWrite
  );
  const canWDnodes = values(codeNodes).filter(
    node => node.isWrite && node.isDelete && !node.isRead
  );
  const canWRDnodes = values(codeNodes).filter(
    node => node.isWrite && node.isRead && node.isDelete
  );
  const codeRes = {
    Rgroup: groupCodeRes(canRnodes, codeNodes),
    Wgroup: groupCodeRes(canWnodes, codeNodes),
    Dgroup: groupCodeRes(canDnodes, codeNodes),
    RWgroup: groupCodeRes(canRWnodes, codeNodes),
    RDgroup: groupCodeRes(canRDnodes, codeNodes),
    WDgroup: groupCodeRes(canWDnodes, codeNodes),
    RWDgroup: groupCodeRes(canWRDnodes, codeNodes)
  };
  codeRes.Rgroup.forEach(group => {
    const resAccess = {
      Action: ["read"],
      Resource: `orn:onigiri:db:code/${group}`
    };
    statements.push(resAccess);
  });
  codeRes.Wgroup.forEach(group => {
    const resAccess = {
      Action: ["update"],
      Resource: `orn:onigiri:db:code/${group}`
    };
    statements.push(resAccess);
  });
  codeRes.Dgroup.forEach(group => {
    const resAccess = {
      Action: ["delete"],
      Resource: `orn:onigiri:db:code/${group}`
    };
    statements.push(resAccess);
  });
  codeRes.RWgroup.forEach(group => {
    const resAccess = {
      Action: ["read", "update"],
      Resource: `orn:onigiri:db:code/${group}`
    };
    statements.push(resAccess);
  });
  codeRes.RDgroup.forEach(group => {
    const resAccess = {
      Action: ["read", "delete"],
      Resource: `orn:onigiri:db:code/${group}`
    };
    statements.push(resAccess);
  });
  codeRes.WDgroup.forEach(group => {
    const resAccess = {
      Action: ["update", "delete"],
      Resource: `orn:onigiri:db:code/${group}`
    };
    statements.push(resAccess);
  });
  codeRes.RWDgroup.forEach(group => {
    const resAccess = {
      Action: ["read", "update", "delete"],
      Resource: `orn:onigiri:db:code/${group}`
    };
    statements.push(resAccess);
  });
  return statements;
};
