import * as types from "constants/ActionTypes";

const initState = [];

const groupEditPolicy = (state = initState, action) => {
  if (action.type === types.SET_GROUP_POLICY) {
    state = action.policy;
  }
  return state;
};

export default groupEditPolicy;
