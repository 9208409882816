import BaseComponent from "components/common/BaseComponent";
import React from "react";
import * as StringFormat from "lib/StringFormat";
import EditAction from "components/common/EditAction";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import * as ApiConstants from "constants/ApiConstants";
import { getLoginUserName } from "lib/LocalStorageUtils";
import * as MsgConstants from "constants/MessageConstants";
import {
  GROUP_PATH,
  GROUP_DETAIL_PATH,
  GROUP_ADD_PATH,
  USER_DETAIL_PATH,
  POLICY_DETAIL_PATH
} from "constants/RoutePath";
import * as LocalStore from "constants/LocalStorage";
import { hist } from "App";
import { getTargetUser } from "lib/LocalStorageUtils";
import GroupEditUser from "components/content/group/GroupEditUser";
import GroupEditPolicy from "components/content/group/GroupEditPolicy";
import { MODAL_TYPE } from "constants/CommonConstants";
import { LISTVIEW_TYPE } from "constants/LocalStorage";
import * as ListViewTypes from "constants/ListViewTypes";

const INFO_MODE = "info";
const EDIT_MODE = "edit";

class GroupDetail extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      mode: INFO_MODE,
      groupNameEdit: ""
    };
  }

  componentDidMount = async () => {
    this.mounted = true;
    if (!this.props.selectedGroup.group_id) {
      await this.getGroupInfos();
    }
  };

  componentDidUpdate(previousProps) {
    // browser back/forward
    window.onpopstate = e => {
      if (window.location.pathname !== GROUP_ADD_PATH) {
        this.getGroupInfos();
      } else {
        this.props.selectGroup({});
      }
    };

    // on click group
    if (
      previousProps.selectedGroup.group_id &&
      this.props.selectedGroup.group_id !== previousProps.selectedGroup.group_id
    ) {
      this.setState({
        mode: INFO_MODE,
        groupNameEdit: this.props.selectedGroup.group_name
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    this.props.selectGroup({});
    this.props.setGroupPolicy([]);
    this.props.setGroupUser([]);
  }

  getGroupInfos = async () => {
    // get group list
    if (this.mounted) {
      const groupListLocal = localStorage.getItem(LocalStore.GROUP_LIST);
      const groupListNew = await this.getGroupList();
      if (groupListLocal !== JSON.stringify(groupListNew)) {
        localStorage.setItem(
          LocalStore.GROUP_LIST,
          JSON.stringify(groupListNew.group_list)
        );
        this.props.setGroupList(groupListNew.group_list);
      } else {
        this.props.setGroupList(JSON.parse(groupListLocal));
      }
      // get selected group
      const selectedName = this.getSelectedGroup();
      let groupId = 0;
      const group = groupListNew.group_list.filter(
        group => group.group_name === selectedName
      )[0];
      if (group) groupId = group.group_id;
      if (groupId) {
        this.getGroupInfo(groupId);
      }
      return groupListNew;
    }
  };

  getSelectedGroup = () => {
    if (this.mounted) {
      const splitPath = window.location.pathname.split("/");
      if (splitPath[4] === EDIT_MODE) {
        this.setState({
          mode: EDIT_MODE,
          groupNameEdit: decodeURI(splitPath[3])
        });
      } else
        this.setState({
          mode: INFO_MODE,
          groupNameEdit: decodeURI(splitPath[3])
        });
      return decodeURI(splitPath[3]);
    }
  };

  // get group list
  getGroupList = async () => {
    if (this.mounted) {
      this.runProgress();
      const response = await this.executeApi(ApiConstants.GET_GROUPS);
      this.stopProgress();
      return response.success ? response.data : {};
    }
  };

  getGroupInfo = async id => {
    if (this.mounted) {
      const query = {
        group_id: id
      };
      const response = await this.executeApi(
        ApiConstants.GET_GROUP,
        null,
        query,
        null
      );
      const group = response.success ? response.data : {};
      if (group.user_list) {
        group.user_list = group.user_list.filter(user => {
          const targetUser = getTargetUser(user.user_id);
          if (targetUser !== undefined && targetUser != null) {
            const userName = targetUser.name;
            user.name = userName;
            user.id = user.user_id;
          } else {
            const requestParam = {
              user_id: user.user_id,
              valid_flag: false
            };
            const response = this.executeApi(
              ApiConstants.POST_USER,
              null,
              null,
              requestParam
            );
          }
          return targetUser !== undefined && targetUser != null;
        });
      }
      group.group_id = id;
      this.props.setGroupUser(group.user_list);
      this.props.setGroupPolicy(group.policy_list);
      this.props.selectGroup(group);
    }
  };

  onClickInfo = groupName => {
    const mode = this.state.mode;
    if (mode !== INFO_MODE) {
      this.setState({
        mode: INFO_MODE
      });
      const route = `${GROUP_DETAIL_PATH}/${groupName}/info`;
      hist.push(decodeURI(route));
      this.props.setGroupRoute(decodeURI(route));
    }
  };

  onClickEdit = () => {
    const mode = this.state.mode;
    if (mode !== EDIT_MODE) {
      this.setState({
        mode: EDIT_MODE,
        groupNameEdit: this.props.selectedGroup.group_name
      });
      const route = window.location.pathname.replace(INFO_MODE, EDIT_MODE);
      this.props.setGroupRoute(decodeURI(route));
      hist.push(decodeURI(route));
    }
  };

  onCancel = () => {
    const origName = this.props.selectedGroup.group_name;
    this.setState({
      groupNameEdit: origName
    });
    if (this.refs.groupNameRef) this.refs.groupNameRef.value = origName;
    this.props.editGroup("cancelEdit");
  };

  onSave = () => {
    const { groupEditUser, groupEditPolicy, selectedGroup } = this.props;
    let { groupNameEdit } = this.state;
    const origUsers = [];
    const editUsers = [];
    const origPolices = [];
    const editPolices = [];
    const delPolicy = [];

    groupNameEdit = groupNameEdit.trim();
    selectedGroup.user_list.forEach(user => {
      origUsers.push(user.id);
    });
    groupEditUser.forEach(user => {
      editUsers.push(user.id);
    });
    selectedGroup.policy_list.forEach(policy => {
      origPolices.push(policy.policy_id);
    });
    groupEditPolicy.forEach(policy => {
      const policyList = JSON.parse(
        localStorage.getItem(LocalStore.LIST_POLICY)
      );
      if (policyList.filter(e => e.policy_id === policy.policy_id).length) {
        editPolices.push(policy.policy_id);
      } else delPolicy.push(policy.policy_name);
    });

    // Check edit group info
    if (!groupNameEdit) {
      this.showDialog({ message: MsgConstants.GROUP_NAME_NOT_DEFINE });
      return;
    }
    if (!StringFormat.isValidName(groupNameEdit)) {
      this.showDialog({ message: MsgConstants.GROUP_NAME_INVALID });
      return;
    }
    if (delPolicy.length) {
      this.showDialog({
        message: StringFormat.format(MsgConstants.POLICY_NOT_EXIST, [delPolicy])
      });
      return;
    }

    if (
      groupNameEdit === selectedGroup.group_name &&
      JSON.stringify(origPolices) === JSON.stringify(editPolices) &&
      JSON.stringify(origUsers) === JSON.stringify(editUsers)
    ) {
      this.showDialog({ message: MsgConstants.INPUT_NO_CHANGE });
      return;
    }
    // Perform edit group
    const requestParam = {
      group_id: selectedGroup.group_id,
      group_name: groupNameEdit,
      valid_flag: true,
      user_list: editUsers,
      policy_list: editPolices,
      update_user: getLoginUserName(),
      update_time: selectedGroup.update_time
    };

    this.showDialog({
      message: StringFormat.format(MsgConstants.UPDATE_CONFIRM, [
        selectedGroup.group_name
      ]),
      confirm: true,
      positive: async () => {
        const response = await this.executeApi(
          ApiConstants.POST_GROUP,
          null,
          null,
          requestParam
        );
        if (response.success) {
          this.showToast(
            StringFormat.format(MsgConstants.EDIT_SUCCESS, ["グループ"])
          );
          // update group list, selection group and go to info mode
          await this.getGroupInfos();
          this.getGroupInfo(selectedGroup.group_id);
          this.onClickInfo(groupNameEdit);
        }
      }
    });
  };

  onDeleteGroup = group => {
    const requestParam = {
      group_id: group.groupId,
      valid_flag: false,
      update_user: getLoginUserName()
    };
    this.showDialog({
      message: StringFormat.format(MsgConstants.DELETE_CONFIRM, [
        group.groupName
      ]),
      confirm: true,
      positive: async () => {
        const response = await this.executeApi(
          ApiConstants.POST_GROUP,
          null,
          null,
          requestParam
        );
        if (response.success) {
          this.showToast(MsgConstants.GROUP_DELETE_SUCCESS);
          //update group list
          this.props.selectGroup({});
          hist.push(GROUP_PATH);
          this.props.setGroupRoute(GROUP_PATH);
          this.props.editGroup("deleteGroup");
        }
      }
    });
  };

  onNameChange = e => {
    const { value } = e.target;
    this.setState({
      groupNameEdit: value
    });
  };

  getUserPolicy = policyList => {
    return policyList.map((policy, index) => (
      <span
        className="pointer"
        onClick={this.goToPolicy.bind(this, policy.policy_id)}
      >
        {index === policyList.length - 1
          ? policy.policy_name
          : policy.policy_name + ", "}
      </span>
    ));
  };

  openModal = params => {
    this.props.openModal(params[0], params[1]);
  };

  goToUser = UserId => {
    const route = `${USER_DETAIL_PATH}/${UserId}/info`;
    this.props.setUserRoute(decodeURI(route));
    this.props.showUser();
    hist.push(decodeURI(route));
    localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.USER);
  };

  goToPolicy = policyId => {
    const route = `${POLICY_DETAIL_PATH}/${policyId}/info`;
    this.props.setPolicyRoute(decodeURI(route));
    this.props.showPolicy();
    this.props.editGroup("goToPolicy");
    hist.push(decodeURI(route));
    localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.POLICY);
  };

  render() {
    const { mode } = this.state;
    const { selectedGroup } = this.props;
    if (!selectedGroup.group_name) return null;
    const { user_list, policy_list } = selectedGroup;
    return (
      <div className="container-fluid m-0 p-0 w-100">
        <div
          id="headMenu"
          className="row position-static flex-nowrap m-0 p-2 text-white "
        >
          <div
            id="tabMenu"
            className="mr-2"
          >
            <ul className="nav d-flex flex-nowrap">
              <li
                className="nav-item detail-header"
                onClick={this.onClickInfo.bind(this, selectedGroup.group_name)}
              >
                <a
                  className={
                    mode === INFO_MODE ? "nav-link active" : "nav-link"
                  }
                  href="#definition-dsp"
                  data-target="#definition-dsp"
                  data-toggle="tab"
                  id="sample-btn-dsp"
                >
                  通常
                </a>
              </li>
              <li className="nav-item detail-header" onClick={this.onClickEdit}>
                <a
                  className={
                    mode === EDIT_MODE ? "nav-link active" : "nav-link"
                  }
                  href="#definition-edt"
                  data-target="#definition-edt"
                  data-toggle="tab"
                  id="sample-btn-edt"
                >
                  編集
                </a>
              </li>
            </ul>
          </div>
          {mode === EDIT_MODE && (
            <input
              type="button"
              className="topic-delete btn btn-warning rounded-1"
              value="グループの削除"
              onClick={this.onDeleteGroup.bind(this, {
                groupId: selectedGroup.group_id,
                groupName: selectedGroup.group_name
              })}
            />
          )}
        </div>
        <div
          id="detail-margin"
          className={this.setDetailStyle(this.props.isLvExpand)}
        >
          <div className="m-0 flex-nowrap">
            <div className="d-flex mb-3" style={{ paddingRight: "200px" }}>
              <div className="d-flex w-100">
                <span
                  className="align-self-center adm-detail-main-title w-100"
                  style={{ minWidth: "400px" }}
                >
                  {mode === EDIT_MODE ? "グループ編集" : "グループ情報"}
                </span>
              </div>
            </div>
            <div className="custom-border-bottom">
              <div className="row no-gutters">
                <div className="col-2 d-flex ">
                  <span className="key detail-group-sub-title">グループ名</span>
                </div>
                <div className="col pl-2">
                  {mode === EDIT_MODE ? (
                    <input
                      type=""
                      className="custom-input"
                      defaultValue={selectedGroup.group_name}
                      ref="groupNameRef"
                      onChange={this.onNameChange}
                    />
                  ) : (
                    selectedGroup.group_name
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="pb-2">
            <div className="row no-gutters">
              <div className="key col-2 detail-group-sub-title">
                所属するユーザー
              </div>
              <div className="col">
                <div className="mdl-list-user-header">
                  {mode === EDIT_MODE ? (
                    <div
                      className="btn btn-positive rounded-1 ml-2 mt-1"
                      onClick={this.openModal.bind(this, [
                        MODAL_TYPE.USER,
                        user_list
                      ])}
                    >
                      ユーザーを選択
                    </div>
                  ) : (
                    <div className="mdl-grid mdl-list-user">
                      <span className="value pl-2">ユーザー名</span>
                      <span className="value">アタッチ済みのポリシー</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {mode === EDIT_MODE && (
              <div className="row no-gutters">
                <div className="col-2" />
                <div className="col">
                  <div className="mdl-list-user-header">
                    <div className="mdl-grid mdl-list-user">
                      <span className="value pl-2">ユーザー名</span>
                      <span className="value">アタッチ済みのポリシー</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {mode === INFO_MODE ? (
              <div className="mdl-list-outer-group-user">
                {user_list.map((user, index) => (
                  <div className="row no-gutters" key={index}>
                    <div className="col-2" />
                    <div className="col">
                      <div className="mdl-grid mdl-list-user">
                        <span
                          className="key text-primary text-wrap pointer pl-2"
                          onClick={this.goToUser.bind(this, user.id)}
                        >
                          {user.name}
                        </span>
                        {user.policy_list.length ? (
                          <span className="value text-primary text-wrap">
                            {this.getUserPolicy(user.policy_list)}
                          </span>
                        ) : (
                          <span className="value">なし</span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <GroupEditUser initList={user_list} />
            )}
          </div>
          <div className="row no-gutters">
            <div className="col custom-border-bottom"></div>
          </div>
          <div>
            <div className="row no-gutters">
              <div className="key col-2 detail-group-sub-title">
                アタッチ済みのポリシー
              </div>
              <div className="col">
                {mode === EDIT_MODE ? (
                  <div
                    className="btn btn-positive rounded-1 ml-2 mt-1"
                    onClick={this.openModal.bind(this, [
                      MODAL_TYPE.POLICY_GROUP,
                      policy_list
                    ])}
                  >
                    ポリシーを選択
                  </div>
                ) : (
                  <div className=" mdl-list-user-header">
                    <div className="mdl-grid mdl-list-user">
                      <span className="value pl-2">ポリシー名</span>
                      <span className="value">説明</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {mode === EDIT_MODE && (
              <div className="row no-gutters">
                <div className="col-2"></div>
                <div className="col">
                  <div className="mdl-list-user-header">
                    <div className="mdl-grid mdl-list-user">
                      <span className="value pl-2">ポリシー名</span>
                      <span className="value">説明</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {mode === INFO_MODE ? (
              <div className="mdl-list-outer-group-user">
                {policy_list.map((policy, index) => (
                  <div className="row no-gutters" key={index}>
                    <div className="col-2" />
                    <div className="col">
                      <div className="mdl-grid mdl-list-user">
                        <span
                          className="key text-primary text-wrap pointer pl-2"
                          onClick={this.goToPolicy.bind(this, policy.policy_id)}
                        >
                          {policy.policy_name}
                        </span>
                        <span className="value"> {policy.description}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <GroupEditPolicy initList={policy_list}></GroupEditPolicy>
            )}
          </div>
          <div className="pb-3">
          <div
            className="py-3"
            style={{
              display: mode === EDIT_MODE ? "block" : "none"
            }}
          >
            <EditAction
              onCancelHandler={this.onCancel}
              onSaveHandler={this.onSave}
            />
          </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLvExpand: state.lvExpandState,
    groupList: state.groupList,
    userList: state.userList,
    selectedGroup: state.selectedGroup,
    groupEditUser: state.groupEditUsers,
    groupEditPolicy: state.groupEditPolicy,
    groupEdit: state.groupEdit
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setGroupList: groupList => {
      dispatch(actions.setGroupList(groupList));
    },
    selectGroup: group => {
      dispatch(actions.selectGroup(group));
    },
    setGroupRoute: route => {
      dispatch(actions.setGroupRoute(route));
    },
    setGroupUser: users => {
      dispatch(actions.setGroupUser(users));
    },
    setGroupPolicy: policy => {
      dispatch(actions.setGroupPolicy(policy));
    },
    editGroup: action => {
      dispatch(actions.editGroup(action));
    },
    openModal: (modal, data) => {
      dispatch(actions.openModal(modal, data));
    },
    setUserList: list => {
      dispatch(actions.setUserList(list));
    },
    setUserRoute: route => {
      dispatch(actions.setUserRoute(route));
    },
    setPolicyRoute: route => {
      dispatch(actions.setPolicyRoute(route));
    },
    showPolicy: () => {
      dispatch(actions.showPolicy());
    },
    showUser: () => {
      dispatch(actions.showUser());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetail);
