import * as types from "constants/ActionTypes";
import * as RoutePath from "constants/RoutePath";

var initState = RoutePath.USER_PATH;

var userRoute = (state = initState, action) => {
  if (action.type === types.USER_ROUTE) {
    state = action.route;
  }
  return state;
};

export default userRoute;
