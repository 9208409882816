import React from "react";
import FaceIcon from "images/ic_face_white.svg";
import LockIcon from "images/ic_lock_open.svg";
import TitleImage from "images/login_title.svg";
import BaseComponent from "components/common/BaseComponent";
import ActvityBar from "components/activity-bar/ActivityBar";
import TopBar from "components/top-bar/TopBar";
import validator from "validator";
import { connect } from "react-redux";
import { LoginConstants } from "constants/LoginConstants";
import * as MessageConstants from "constants/MessageConstants";
import * as CognitoConstants from "constants/CognitoConstants";
import * as CognitoUserUtils from "lib/CognitoUserUtils";
import AppConfig from "../../config";
import * as LocalStorageUtils from "lib/LocalStorageUtils";
import { LISTVIEW_TYPE } from "constants/LocalStorage";
import { POLICY_PATH } from "constants/RoutePath";
import * as ListViewTypes from "constants/ListViewTypes";
import * as actions from "actions/Actions";
import "css/style-login.css";
import * as ApiConstants from "constants/ApiConstants";

let isFirstLoad = true;
let cognitoUser;
const AmazonCognitoIdentity = require("amazon-cognito-identity-js");

class Login extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLogin: true,
      forgotPassword: false,
      username: "",
      password: "",
      isFail: false,
      errorMsg: "",
      newPassword: "",
      confirmPassword: "",
      infoMsg: "",
      authCode: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handlePasswordSetting = this.handlePasswordSetting.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleForgotPasswordSetting = this.handleForgotPasswordSetting.bind(
      this
    );
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleLogin = e => {
    e.preventDefault();
    const { username, password } = this.state;
    const self = this;
    if (this.validate()) {
      cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: username,
        Pool: new AmazonCognitoIdentity.CognitoUserPool({
          UserPoolId: AppConfig.cognito.USER_POOL_ID,
          ClientId: AppConfig.cognito.CLIENT_ID
        })
      });
      const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
        {
          Username: username,
          Password: password
        }
      );
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async function(result) {
          const payload = result.idToken.payload;
          if (!payload[CognitoConstants.USER_STATUS]) {
            self.setState({
              errorMsg: MessageConstants.LOGIN_AUTHORITY_ERROR
            });
          } else if (
            payload[CognitoConstants.USER_STATUS] ===
            CognitoConstants.DISABLE_USER_CODE
          ) {
            // Disabled users are not allowed to login
            self.setState({
              errorMsg: MessageConstants.ACCOUNT_DISABLED_LOGIN_ERROR
            });
            return;
          } else {
            LocalStorageUtils.setLoginUser(result);
            // check login permission
            const response = await self.executeApi(
              ApiConstants.GET_USER_POLICIES,
              null,
              {user_id: payload.sub},
              null
            );
            if (response.success) {
              const policyList = response.data.policy_list;
              const isLogin = policyList.filter(policy => {
                const overview = JSON.parse(policy.overview);
                return overview.Statement[0].Resource === "orn:onigiri:am:login" && overview.Statement[0].Action.includes("allow");
              });
              if (isLogin.length) {
                // login success
                self.props.setPolicyRoute(POLICY_PATH);
                localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.POLICY);
                self.props.login(LoginConstants.LOGIN_SUCCESS);
                self.props.showExpMsg(false);
              } 
            }
            self.setState({
              errorMsg: MessageConstants.AUTH_ERROR
            });
          }
        },
        onFailure: function(err) {
          if (err.name === CognitoConstants.PASSWORD_RESET_REQUIRED_EXCEPTION) {
            self.setState({
              showLogin: false,
              forgotPassword: true,
              infoMsg: MessageConstants.FORGOT_PASSWORD_SETTING_INFO
            });
          } else {
            self.setState({
              errorMsg: MessageConstants.INPUT_ERROR
            });
          }
        },
        newPasswordRequired: function(user_attributes, required_attributes) {
          self.setState({
            showLogin: false,
            infoMsg: MessageConstants.PASSWORD_SETTING_INFO
          });
        }
      });
    } else {
      this.setState({ isFail: true });
    }
  };

  handlePasswordSetting = e => {
    if (e) e.preventDefault();
    const self = this;
    const { username, newPassword, confirmPassword } = this.state;
    if (validator.isEmpty(newPassword) || validator.isEmpty(confirmPassword)) {
      this.setState({
        infoMsg: MessageConstants.INPUT_ERROR
      });
    } else {
      if (newPassword === confirmPassword) {
        cognitoUser.completeNewPasswordChallenge(
          newPassword,
          {},
          {
            onSuccess(result) {
              const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
                {
                  Username: username,
                  Password: newPassword
                }
              );
              cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function(result) {
                  self.setState({
                    password: "",
                    showLogin: true,
                    errorMsg: MessageConstants.PASSWORD_SETTING_COMPLETE
                  });
                },
                onFailure: function(err) {
                  self.setState({
                    errorMsg: MessageConstants.AUTH_ERROR
                  });
                }
              });
            },
            onFailure(err) {
              self.setState({
                infoMsg:
                  err.name === CognitoConstants.INVALID_PASSWORD_EXCEPTION
                    ? MessageConstants.PASSWORD_POLICY_ERROR
                    : MessageConstants.PASSWORD_SETTING_ERROR
              });
            }
          }
        );
      } else {
        this.setState({
          infoMsg: MessageConstants.PASSWORD_DIFFERENT_ERROR
        });
      }
    }
  };

  handleForgotPasswordSetting = e => {
    if (e) e.preventDefault();
    const self = this;
    const { username, authCode, newPassword, confirmPassword } = this.state;
    if (
      validator.isEmpty(authCode) ||
      validator.isEmpty(newPassword) ||
      validator.isEmpty(confirmPassword)
    ) {
      this.setState({
        infoMsg: MessageConstants.INPUT_ERROR
      });
    } else {
      if (newPassword === confirmPassword) {
        const callback = (err, data) => {
          if (err) {
            this.setState({
              infoMsg:
                err.name === CognitoConstants.INVALID_PASSWORD_EXCEPTION
                  ? MessageConstants.PASSWORD_POLICY_ERROR
                  : MessageConstants.PASSWORD_SETTING_ERROR
            });
          } else {
            const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
              {
                Username: username,
                Password: newPassword
              }
            );
            cognitoUser.authenticateUser(authenticationDetails, {
              onSuccess: function(result) {
                self.setState({
                  showLogin: true,
                  forgotPassword: false,
                  errorMsg: MessageConstants.PASSWORD_SETTING_COMPLETE,
                  authCode: "",
                  newPassword: "",
                  confirmPassword: ""
                });
              },
              onFailure: function(err) {
                self.setState({
                  errorMsg: MessageConstants.AUTH_ERROR
                });
              }
            });
          }
        };
        CognitoUserUtils.confirmForgotPassword(
          authCode,
          newPassword,
          username,
          callback
        );
      } else {
        this.setState({
          infoMsg: MessageConstants.PASSWORD_DIFFERENT_ERROR
        });
      }
    }
  };

  handleClose = e => {
    e.preventDefault();
    this.setState({
      showLogin: true,
      forgotPassword: false,
      username: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
      authCode: ""
    });
  };

  validate() {
    if (validator.isEmail(this.state.username)) {
      this.setState({
        errorMsg: ""
      });
      return true;
    }
    this.setState({
      errorMsg: MessageConstants.INPUT_ERROR
    });
    return false;
  }

  componentWillReceiveProps(nextProps) {
    if (isFirstLoad === false) {
      if (nextProps.isLogin === false) {
        this.setState({ isFail: true });
      } else {
        this.setState({ isFail: false });
      }
    }
    if (nextProps.isExpire !== this.props.isExpire) {
      this.setState({
        errorMsg: nextProps.isExpire ? MessageConstants.MSG_LOGIN_EXPIRE : ""
      });
    }
  }

  componentWillMount() {
    isFirstLoad = true;
    this.setState({ isFail: false });
  }

  render() {
    const {
      username,
      password,
      newPassword,
      confirmPassword,
      authCode
    } = this.state;
    return (
      <div>
        <div className="wrapper">
          {/* activity-bar */}
          <ActvityBar />
          <div className="container-fluid">
            {/* top-bar */}
            <TopBar />
            <div>
              <div className="content" />
            </div>
          </div>
        </div>
        <div id="dialog-wrapper">
          <div className="container py-5 h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-md-12">
                <div className="d-flex">
                  <div className="mx-auto my-auto">
                    <div id="login-form" className="card login-bg">
                      <div className="card-header mx-auto py-0">
                        <img src={TitleImage} alt="" id="login_title" />
                      </div>
                      <div
                        className="card-body px-0 mx-auto"
                        style={{
                          display: this.state.showLogin ? "block" : "none"
                        }}
                      >
                        <form
                          className="form form-horizontal w-100"
                          autoComplete="off"
                          noValidate
                          onSubmit={this.handleLogin}
                        >
                          <div className="form-group err-message-wrapper">
                            <span className="login-message">
                              {this.state.errorMsg}
                            </span>
                          </div>
                          <div className="form-group d-flex w-100 login-id-wrapper">
                            <img className="login-icon" src={FaceIcon} alt="" />
                            <div className="input-wrapper">
                              <input
                                className="login-input h-100 login-id"
                                type="email"
                                placeholder="メールアドレス"
                                name="username"
                                value={username}
                                onChange={this.handleChange}
                                autoFocus
                              />
                            </div>
                          </div>
                          <div className="form-group d-flex w-100 login-pass-wrapper">
                            <img className="login-icon" src={LockIcon} alt="" />
                            <div className="input-wrapper">
                              <input
                                className="login-input h-100 login-id"
                                type="password"
                                placeholder="パスワード"
                                name="password"
                                value={password}
                                onChange={this.handleChange}
                              />
                            </div>
                          </div>
                          <div className="row no-gutters flex-nowrap">
                            <div className="mx-auto d-flex flex-nowrap flex-row">
                              <button
                                type="submit"
                                className="btn rounded-0 btn-login"
                                // disabled={!this.validate()}
                              >
                                ログイン
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div
                        className="card-body px-0 mx-auto"
                        style={{
                          display:
                            !this.state.showLogin && !this.state.forgotPassword
                              ? "block"
                              : "none"
                        }}
                      >
                        <div className="form-group err-message-wrapper">
                          <span className="login-message">
                            {this.state.infoMsg}
                          </span>
                        </div>
                        <div className="form-group d-flex w-100 login-id-wrapper">
                          <img className="login-icon" src={LockIcon} alt="" />
                          <div className="input-wrapper">
                            <input
                              className="login-input h-100 login-id"
                              type="password"
                              placeholder="パスワード"
                              name="newPassword"
                              value={newPassword}
                              onChange={this.handleChange}
                              onKeyDown={e =>
                                e.keyCode === 13
                                  ? this.handlePasswordSetting()
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group d-flex w-100 login-pass-wrapper">
                          <img className="login-icon" src={LockIcon} alt="" />
                          <div className="input-wrapper">
                            <input
                              className="login-input h-100 login-id"
                              type="password"
                              placeholder="もう一度"
                              name="confirmPassword"
                              value={confirmPassword}
                              onChange={this.handleChange}
                              onKeyDown={e =>
                                e.keyCode === 13
                                  ? this.handlePasswordSetting()
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="row no-gutters flex-nowrap">
                          <div className="mx-auto d-flex flex-nowrap flex-row">
                            <button
                              className="rounded-0 btn-close"
                              onClick={this.handleClose}
                            >
                              閉じる
                            </button>
                            <button
                              className="btn rounded-0 btn-login"
                              onClick={this.handlePasswordSetting}
                            >
                              設定
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="password-setting card-body px-0 mx-auto"
                        style={{
                          display:
                            !this.state.showLogin && this.state.forgotPassword
                              ? "block"
                              : "none"
                        }}
                      >
                        <div className="form-group err-message-wrapper login-message">
                          {this.state.infoMsg}
                        </div>
                        <div className="form-group d-flex w-100 login-id-wrapper">
                          <img className="login-icon" src={LockIcon} alt="" />
                          <div className="input-wrapper">
                            <input
                              className="login-input h-100 login-id"
                              type="text"
                              placeholder="認証コード"
                              name="authCode"
                              value={authCode}
                              onChange={this.handleChange}
                              onKeyDown={e =>
                                e.keyCode === 13
                                  ? this.handleForgotPasswordSetting()
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group d-flex w-100 login-id-wrapper">
                          <img className="login-icon" src={LockIcon} alt="" />
                          <div className="input-wrapper">
                            <input
                              className="login-input h-100 login-id"
                              type="password"
                              placeholder="パスワード"
                              name="newPassword"
                              value={newPassword}
                              onChange={this.handleChange}
                              onKeyDown={e =>
                                e.keyCode === 13
                                  ? this.handleForgotPasswordSetting()
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group d-flex w-100 login-pass-wrapper">
                          <img className="login-icon" src={LockIcon} alt="" />
                          <div className="input-wrapper">
                            <input
                              className="login-input h-100 login-id"
                              type="password"
                              placeholder="もう一度"
                              name="confirmPassword"
                              value={confirmPassword}
                              onChange={this.handleChange}
                              onKeyDown={e =>
                                e.keyCode === 13
                                  ? this.handleForgotPasswordSetting()
                                  : ""
                              }
                            />
                          </div>
                        </div>
                        <div className="row no-gutters flex-nowrap">
                          <div className="mx-auto d-flex flex-nowrap flex-row">
                            <button
                              className="rounded-0 btn-close"
                              onClick={this.handleClose}
                            >
                              閉じる
                            </button>
                            <button
                              className="btn rounded-0 btn-login"
                              onClick={this.handleForgotPasswordSetting}
                            >
                              設定
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {};

const mapStateToProps = state => {
  return {
    isLogin: state.loginState,
    isExpire: state.isLoginExpire
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: loginsState => {
      dispatch({ type: loginsState });
    },
    showExpMsg: isShow => {
      dispatch(actions.showExpireMsg(isShow));
    },
    setPolicyRoute: route => {
      dispatch(actions.setPolicyRoute(route));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
