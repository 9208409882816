import * as types from "constants/ActionTypes";
import * as RoutePath from "constants/RoutePath";

var initState =  RoutePath.SETTING_PATH;

var settingRoute = (state = initState, action) => {
  if (action.type === types.SETTING_ROUTE) {
    state = action.route;
  }
  return state;
};

export default settingRoute;
