import React from "react";
import BaseComponent from "components/common/BaseComponent";
import { connect } from "react-redux";
import * as actions from "actions/LoginActions";
import $ from "jquery";

class AlertMessage extends BaseComponent {
  constructor(props) {
    super(props);
    window.Alert = this;
  }

  componentWillMount() {
    this.saveState();
  }

  saveState(info) {
    info = info ? info : {};
    this.setState({
      message: info.message,
      confirm: info.confirm,
      positive: info.positive,
      negative: info.negative,
      reflash: info.reflash
    });
  }

  showAlert(info) {
    const isAlertVisible = $("#confirmModal").is(":visible");
    if (!isAlertVisible) {
      this.saveState(info);
      const showModal = document.getElementById("showModal");
      if(showModal) showModal.click();
    } else {
      setTimeout(() => {
        this.showAlert(info);
      }, 200);
    }
  }

  doNegative() {
    if (this.state.negative) this.state.negative();
  }

  doPositive() {
    if (this.state.positive) this.state.positive();
    if (this.state.reflash) {
      localStorage.clear();
      this.props.logout();
    }
  }

  render() {
    return (
      <div
        id="confirmModal"
        ref="abcd"
        className="modal fade"
        aria-labelledby="confirmModal"
        data-backdrop={this.state.reflash ? false : true}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal-bg">
            <div className="modal-body mt-4 pb-0">
              <div className="text-center">
                <span className="dialog-message">{this.state.message}</span>
              </div>
            </div>
            <div className="modal-body text-center">
              {this.state.confirm === true ? (
                <button
                  type="button"
                  className="btn rounded-1 btn-cancel"
                  data-dismiss="modal"
                  onClick={() => this.doNegative()}
                >
                  キャンセル
                </button>
              ) : null}
              <button
                type="button"
                className="btn rounded-1 btn-positive"
                data-dismiss="modal"
                onClick={() => this.doPositive()}
              >
                OK
              </button>
            </div>
          </div>
        </div>
        <button
          id="showModal"
          style={{ display: "none" }}
          data-toggle="modal"
          data-target="#confirmModal"
        ></button>
      </div>
    );
  }
}

const mapDispatchToProbs = dispatch => {
  return {
    logout: () => {
      dispatch(actions.logout());
    }
  };
};

AlertMessage.propTypes = {};

export default connect(null, mapDispatchToProbs)(AlertMessage);
