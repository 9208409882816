export const INPUT_ERROR = "入力された情報に誤りがあります。";
export const INPUT_NO_CHANGE = "変更された項目がありません。";
export const PASSWORD_SETTING_ERROR = "パスワード設定に失敗しました。";
export const PASSWORD_POLICY_INFO = "パスワードは大文字と小文字を含めた8文字以上の英数字を設定してください。";
export const PASSWORD_POLICY_ERROR = "パスワードは大文字と小文字を含めた\n8文字以上の英数字を設定してください。";
export const PASSWORD_DIFFERENT_ERROR = "同じパスワードを入力してください。";
export const PASSWORD_SETTING_INFO = "パスワードを設定してください。";
export const PASSWORD_SETTING_COMPLETE = "パスワードの設定が完了しました。";
export const PASSWORD_CHANGE_CONFIRM = "パスワードを変更します。\nよろしいですか？";
export const PASSWORD_CHANGE_ERROR = "パスワード変更に失敗しました";
export const AUTH_ERROR = "認証に失敗しました。";
export const DELETE_CONFIRM = "「?」を削除します。\nよろしいですか？";
export const ADD_CONFIRM = "「?」を追加します。\nよろしいですか？";
export const INVALID_COMPANY_NAME = "会社名を入力してください。";
export const INVALID_KEY_LENGTH = "キーワードは３文字以上で入力してください。";
export const INVALID_USER_TYPE = "ユーザー種別が不正です。";
export const USER_EDIT_CONFIRM = "「?」さんを?します。\nよろしいですか？";
export const USER_RESET_PASSWORD_CONFIRM = "「?」さんのパスワードを初期化します。\nよろしいですか？";
export const USER_NOT_FOUND_ERROR = "ユーザーが見つかりませんでした。";
export const USER_EDIT_ERROR = "ユーザーの?に失敗しました。"
export const FORGOT_PASSWORD_SETTING_INFO = "送付された認証コードと新しいパスワードを\n入力してください。"
export const USER_EDIT_REQUIRED_ERROR = "未入力の項目があります。";
export const USER_ADD_CONFIRM = "ユーザーを追加します。\nよろしいですか？";
export const USER_ADD_EXIST_ERROR = "入力されたメールアドレスは既に登録されています。";
export const UPDATE_CONFIRM = "「?」を更新します。\nよろしいですか？";
export const LOGOUT_CONFIRM = "ログアウトします。\nよろしいですか？";
export const SEARCHING_DATA = "検索中です...";
export const HISTORY_NOT_DATA = "履歴はありません。";
export const HISTORY_HIT_DATA = "? 件の履歴を表示中";
export const SEARCH_NOT_DATA = "検索結果はありません";
export const SEARCH_HIT_DATA = "? 件の検索結果を表示中";
export const ERR_UNEXPECTED = "サーバとの通信に失敗しました。\n通信環境を確認して再度お試しください。";
export const ERR_UNAUTHORIZED = "不正なリクエストです。\nログインし直してください。";
export const ERR_ACCESS_DENIED = "不正なリクエストです。\nログインし直してください。";
export const ERR_TOKEN_EXRIRED = "セッションの有効期限が切れました。\nログインし直してください。";
export const MSG_TOKEN_EXPIRED = "The incoming token has expired";
export const DATABASE_FETCHING_DATA = "データを更新中...";
export const DATABASE_NOT_DATA = "データはありません";
export const DATABASE_HIT_DATA = "? テーブルを表示中";
export const MULTI_INPUT_CHECK_ERROR = "?は?を全て入力してください";
export const NUMBER_INPUT_CHECK_ERROR = "?には数値を入力してください"
export const DATE_CHECK_ERROR = "作成日 <= 更新日 <= 本日日付となるように入力してください";
export const ACCOUNT_DISABLED_LOGIN_ERROR = "ユーザー停止中の為、ログインできません";
export const LOGIN_AUTHORITY_ERROR = "ログイン権限がありません。";
export const NO_DISPLAY_DATA = "データがありません";
export const MSG_LOGIN_EXPIRE = "セッションの有効期限が切れました";
export const INVALID_SEARCH_KEY_LENGTH = "キーワードは２文字以上で入力してください。";
export const TOPIC_ADD_CONFIRM = "トピックを追加します。\nよろしいですか？";
export const TOPIC_ADD_SUCCESS = "トピックを追加しました。"
export const TOPIC_EDIT_CONFIRM = "トピックを編集します。\nよろしいですか？";
export const TOPIC_EDIT_SUCCESS = "トピックを編集しました。";
export const TOPIC_DELETE_CONFIRM = "トピックを削除します。\nよろしいですか？";
export const TOPIC_DELETE_SUCCESS = "トピックを削除しました。";
export const TOPIC_COMMENT_INPUT_ERROR = "コメントを入力してください。";
export const TOPIC_COMMENT_ADD_CONFIRM = "コメントを登録します。\nコメント内容は変更できませんがよろしいですか？";
export const TOPIC_COMMENT_ADD_SUCCESS = "コメントを登録しました。";
export const TOPIC_COMMENT_DELETE_CONFIRM = "コメントを削除します。\nよろしいですか？";
export const TOPIC_COMMENT_DELETE_SUCCESS = "コメントを削除しました。";
export const ADD_SUCCESS = "?を追加しました。";
export const EDIT_SUCCESS = "?を更新しました。";
export const PASSWORD_CHANGE_SUCCESS = "パスワードを変更しました。";
export const PASSWORD_RESET_SUCCESS = "パスワードを初期化しました。";
export const USER_DELETE_SUCCESS = "ユーザーを削除しました。";
export const USER_DISABLE_SUCCESS = "ユーザーを停止しました。";
export const USER_ENABLE_SUCCESS = "ユーザーを有効にしました。";
export const CODE_NOT_SELECTED = "コード定義を選択してください。";
export const CODE_SAME = "既に紐付けられています。\n他のコード定義を選択してください。"
export const CODE_TIED = "「?」を紐付けます。\nよろしいですか？";
export const CODE_TIED_SUCCESS = "コード定義を紐付けました。";
export const CODE_UNTIED = "「?」の紐付けを解除します。\nよろしいですか？";
export const CODE_UNTIED_SUCCESS = "コード定義の紐付けを解除しました。";
export const CODE_NAME_NOT_DEFINE = "定義名を入力してください。";
export const CODE_VALUE_NOT_DEFINE = "コード値を入力してください。";
export const CODE_VALUE_NOT_FULL = "コード値はKEYとVALUEの両方を入力してください。";
export const CODE_COMP_NOT_DEFINE = "管理会社を選択してください。";
export const CODE_DELETE_SUCCESS = "コード定義を削除しました。";
export const NO_TIED_DATA = "紐付けられていません。";
export const DND_COMPARE = "他のオブジェクトと比較する場合はリストビューからドラッグ&ドロップしてください";
export const GROUP_DELETE_SUCCESS = "グループを削除しました。";
export const GROUP_NAME_NOT_DEFINE = "グループ名を入力してください。";
export const GROUP_NAME_INVALID = "グループ名に無効な文字が含まれています。";
export const POLICY_DELETE_SUCCESS = "ポリシーを削除しました。";
export const POLICY_NAME_NOT_DEFINE = "ポリシー名を入力してください。";
export const POLICY_NAME_INVALID = "ポリシー名に無効な文字が含まれています。";
export const POLICY_NOT_EXIST = "「?」は既に削除されています。";
export const POLICY_ATTACH_NOT_EXIST = "「?」は既に削除されています。";
export const POLICY_STATEMENT_ERROR = "１つ以上の権限を設定してください。";
export const JSON_HAS_ERROR = "JSONエラーが存在します";
export const ONIGIRI_DB_DISABLE_CONFIRM = "「?」さんのONIGIRI-DB利用権限を無効にします。\nよろしいですか？";
export const ONIGIRI_DB_ENABLE_CONFIRM = "「?」さんのONIGIRI-DB利用権限を有効にします。\nよろしいですか？";
export const ONIGIRI_DB_DISABLE_ERROR = "ONIGIRI-DBの利用停止に失敗しました。";
export const ONIGIRI_DB_ENABLE_ERROR = "ONIGIRI-DBの利用再開に失敗しました。";
