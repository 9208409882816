import * as types from "constants/ActionTypes";

const initState = [];

const policyEditUser = (state = initState, action) => {
  if (action.type === types.SET_POLICY_USER) {
    state = action.users;
  }
  return state;
};

export default policyEditUser;
