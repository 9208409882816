import * as types from "constants/ActionTypes";
import * as RoutePath from "constants/RoutePath";

var initState = RoutePath.GROUP_PATH;

var groupRoute = (state = initState, action) => {
  if (action.type === types.GROUP_ROUTE) {
    state = action.route;
  }
  return state;
};

export default groupRoute;
