import { LoginConstants } from "constants/LoginConstants";
import * as LocalStorage from "constants/LocalStorage";
import {POLICY_PATH} from "constants/RoutePath";
import { hist } from "App";

const initState = JSON.parse(localStorage.getItem(LocalStorage.LOGIN_STATE));

function loginState(state = initState, action) {
  switch (action.type) {
    case LoginConstants.LOGIN_SUCCESS:
      state = true;
      hist.push(POLICY_PATH);
      break;
    case LoginConstants.LOGIN_FAILURE:
      state = false;
      break;
    case LoginConstants.LOGOUT_SUCCESS:
      state = false;
      break;
    case LoginConstants.LOGOUT_FAILURE:
      state = true;
      break;
    default:
      break;
  }
  localStorage.setItem(LocalStorage.LOGIN_STATE, JSON.stringify(state));
  return state;
}

export default loginState;
