import * as types from "constants/ActionTypes";

const initState = [];

const policyList = (state = initState, action) => {
  if (action.type === types.SET_POLICY_LIST) {
    state = action.list;
  }
  return state;
};

export default policyList;
