window.ace.define('components/content/policy/json/light-theme', ['require', 'exports', 'module', 'ace/lib/dom'], (acequire, exports, module) => {
    exports.isDark = false
    exports.cssClass = 'ace-jsoneditor'
    exports.cssText = `.ace-jsoneditor .ace_gutter {
  background: #ebebeb;
  color: #333
  }
  
  .ace-jsoneditor.ace_editor {
  font-family: "dejavu sans mono", "droid sans mono", consolas, monaco, "lucida console", "courier new", courier, monospace, sans-serif;
  line-height: 1.3;
  background-color: #fff;
  }
  .ace-jsoneditor .ace_print-margin {
  width: 1px;
  background: #e8e8e8
  }
  .ace-jsoneditor .ace_scroller {
  background-color: #FFFFFF
  }
  .ace-jsoneditor .ace_text-layer {
  color: gray
  }
  .ace-jsoneditor .ace_variable {
  color: #1a1a1a
  }
  .ace-jsoneditor .ace_cursor {
  border-left: 2px solid #000000
  }
  .ace-jsoneditor .ace_overwrite-cursors .ace_cursor {
  border-left: 0px;
  border-bottom: 1px solid #000000
  }
  .ace-jsoneditor .ace_marker-layer .ace_selection {
  background: lightgray
  }
  .ace-jsoneditor.ace_multiselect .ace_selection.ace_start {
  box-shadow: 0 0 3px 0px #FFFFFF;
  border-radius: 2px
  }
  .ace-jsoneditor .ace_marker-layer .ace_step {
  background: rgb(255, 255, 0)
  }
  .ace-jsoneditor .ace_marker-layer .ace_bracket {
  margin: -1px 0 0 -1px;
  border: 1px solid #BFBFBF
  }
  .ace-jsoneditor .ace_marker-layer .ace_active-line {
  background: #FFFBD1
  }
  .ace-jsoneditor .ace_gutter-active-line {
  background-color : #dcdcdc
  }
  .ace-jsoneditor .ace_marker-layer .ace_selected-word {
  border: 1px solid lightgray
  }
  .ace-jsoneditor .ace_invisible {
  color: #BFBFBF
  }
  .ace-jsoneditor .ace_keyword,
  .ace-jsoneditor .ace_meta,
  .ace-jsoneditor .ace_support.ace_constant.ace_property-value {
  color: #AF956F
  }
  .ace-jsoneditor .ace_keyword.ace_operator {
  color: #484848
  }
  .ace-jsoneditor .ace_keyword.ace_other.ace_unit {
  color: #96DC5F
  }
  .ace-jsoneditor .ace_constant.ace_language {
  color: darkorange
  }
  .ace-jsoneditor .ace_constant.ace_numeric {
  color: blue
  }
  .ace-jsoneditor .ace_constant.ace_character.ace_entity {
  color: #BF78CC
  }
  .ace-jsoneditor .ace_invalid {
  color: #FFFFFF;
  background-color: #FF002A;
  }
  .ace-jsoneditor .ace_fold {
  background-color: #AF956F;
  border-color: #000000
  }
  .ace-jsoneditor .ace_storage,
  .ace-jsoneditor .ace_support.ace_class,
  .ace-jsoneditor .ace_support.ace_function,
  .ace-jsoneditor .ace_support.ace_other,
  .ace-jsoneditor .ace_support.ace_type {
  color: #C52727
  }
  .ace-jsoneditor .ace_string {
  color: red
  }
  .ace-jsoneditor .ace_comment {
  color: #BCC8BA
  }
  .ace-jsoneditor .ace_entity.ace_name.ace_tag,
  .ace-jsoneditor .ace_entity.ace_other.ace_attribute-name {
  color: #606060
  }
  .ace-jsoneditor .ace_markup.ace_underline {
  text-decoration: underline
  }
  .ace-jsoneditor .ace_indent-guide {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAYAAACZgbYnAAAAE0lEQVQImWP4////f4bLly//BwAmVgd1/w11/gAAAABJRU5ErkJggg==") right repeat-y
  }`
  
    const dom = acequire('ace/lib/dom')
    dom.importCssString(exports.cssText, exports.cssClass)
  })
  