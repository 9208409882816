import * as types from "constants/ActionTypes";
import * as RoutePath from "constants/RoutePath";

const initState = RoutePath.POLICY_PATH;

const policyRoute = (state = initState, action) => {
  if (action.type === types.POLICY_ROUTE) {
    state = action.route;
  }
  return state;
};

export default policyRoute;
