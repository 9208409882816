import * as types from "constants/ActionTypes";

const initState = [];

const systemRes = (state = initState, action) => {
    if (action.type === types.SET_SYSTEM_RES) {
        state = action.system;
      }
      return state;
};

export default systemRes;
