import * as LocalStorage from "constants/LocalStorage";
import * as CognitoConstants from "constants/CognitoConstants";

// Use Id to get the target user from localstorage. If cache is cleared, return null.
export function getTargetUser(id) {
  const listUsers = JSON.parse(localStorage.getItem(LocalStorage.LIST_USERS));
  return listUsers ? listUsers.filter(
    user => user.Attributes.filter(attr => attr.Name === "sub")[0].Value === id)[0] : null;
}

// Get the value of the specified key from the cognito user attribute.
export function getAttribute(user, key) {
  const attr = user.Attributes.filter(attr => attr.Name === key)[0];
  return attr ? attr.Value : "";
}

// Return full name
export function getLoginUserName() {
  const loginUser = JSON.parse(localStorage.getItem(LocalStorage.LOGIN_USER));
  return loginUser ? loginUser.name : "";
}

// Return id(mail-address)
export function getLoginUserId() {
  const loginUser = JSON.parse(localStorage.getItem(LocalStorage.LOGIN_USER));
  return loginUser ? loginUser.email : "";
}

export function getUserId(mailAddress) {
  const users = JSON.parse(localStorage.getItem(LocalStorage.LIST_USERS));
  const user = users.filter(user => user.Username === mailAddress)[0];
  return user ? user.id : "";
}

export function getUserName(mailAddress) {
  const users = JSON.parse(localStorage.getItem(LocalStorage.LIST_USERS));
  const user = users.filter(user => user.Username === mailAddress)[0];
  return user ? user.name : "";
}

// Return IdToken, AccessToken, RefreshToken
export function getToken() {
  const loginUser = JSON.parse(localStorage.getItem(LocalStorage.LOGIN_USER));
  return loginUser ? loginUser.token : "";
}

// Set new token to LocalStorage
export function updateToken(newIdToken, newAccessToken) {
  const loginUser = JSON.parse(localStorage.getItem(LocalStorage.LOGIN_USER));
  loginUser.token = {
    idToken: newIdToken,
    accessToken: newAccessToken,
    refreshToken: loginUser.token.refreshToken
  };
  localStorage.setItem(LocalStorage.LOGIN_USER, JSON.stringify(loginUser));
}

export function setLoginUser(result) {
  const payload = result.idToken.payload;
  const params = {
    name: payload.family_name + " " + payload.name,
    email: payload.email,
    type: payload[CognitoConstants.USER_STATUS],
    token: {
      idToken: result.idToken.jwtToken,
      accessToken: result.accessToken.jwtToken,
      refreshToken: result.refreshToken.token
    }
  };
  localStorage.setItem(LocalStorage.LOGIN_USER, JSON.stringify(params));
}

// conversion user data from Cognito API
export function conversion(data) {
  const users = [];
  data.forEach(user => {
    // login_date
    // ONIGIRIシリーズで直近にログインしている日付を取得する
    let DBLoginDate = user.Attributes.filter(
      attr => attr.Name === CognitoConstants.LOGIN_DATE)[0];
    let RMLoginDate = user.Attributes.filter(
      attr => attr.Name === CognitoConstants.LOGIN_DATE_RM)[0];
    if (DBLoginDate) DBLoginDate = new Date(DBLoginDate.Value);
    if (RMLoginDate) RMLoginDate = new Date(RMLoginDate.Value);
    if (DBLoginDate && RMLoginDate) {
      // DB, RM共にログイン履歴あり
      if (DBLoginDate.getTime() < RMLoginDate.getTime()) {
        user.date = RMLoginDate;
      } else {
        user.date = DBLoginDate;
      }
    } else {
      // DB, RMどちらか一方にログイン履歴あり
      user.date = DBLoginDate ? DBLoginDate : RMLoginDate;
    }
    // name
    let familyName, firstName;
    user.Attributes.forEach(attr => {
      switch (attr.Name) {
        case "family_name":
          familyName = attr.Value;
          break;
        case "name":
          firstName = attr.Value;
          break;
        case "sub":
          user.id = attr.Value;
          break;
        default:
          break;
      }
    });
    user.name = familyName + " " + firstName;
    users.push(user);
  });
  return users;
}
