module.exports.format = (value, params) => {
    if (!value || !params || params.length < 1) {
        return value;
    }

    params.forEach(param => {
        value = value.replace('?', param);
    });
    return value;
}

module.exports.numberFormat = (value) => {
    return Number(value).toLocaleString();
}

module.exports.joinFormat = (list, separator) => {
    if (!list) {
        return list;
    }
    let data = [];
    for (let i = 0; i < list.length; i++) {
        if (!list[i]) {
            continue;
        }
        data.push(list[i]);
    }
    return data.join(separator);
}

module.exports.urlPathFormat = (list) => {
    if (!list) {
        return list;
    }
    let data = [];
    for (let i = 0; i < list.length; i++) {
        let value = list[i];
        if (!value) {
            continue;
        }
        value = value.replace('\\', '|').replace(/\//g, '').replace(/:/g, '').replace(' ', '');
        data.push(value);
    }
    return data.join('@');
}

module.exports.choiceUpInfo = (commits, uptime) => {
    let info = {
        updatetime: null,
        commitid: null
    };

    if (!commits || !uptime) {
        return info;
    }

    uptime = uptime.replace(/\//g, '').replace(/:/g, '').replace(' ', '');

    commits.forEach(d => {
        const ut = d.updatetime.replace(/\//g, '').replace(/:/g, '').replace(' ', '');
        if (ut <= uptime) {
            info.updatetime = uptime;
            info.commitid = d.commitid;
            return;
        }
    });
    return info;
}

module.exports.choicePrevUptime = (commits, uptime) => {
    if (!commits || !uptime) {
        return null;
    }

    uptime = uptime.replace(/\//g, '').replace(/:/g, '').replace(' ', '');
    for (let i = commits.length - 1; i >= 0; i--) {
        const d = commits[i];
        const ut = d.updatetime.replace(/\//g, '').replace(/:/g, '').replace(' ', '');
        if (ut > uptime) {
            return ut;
        }
    }
    return null;
}

module.exports.fullDateFormat = (value) => {
    if (!value) {
        return null;
    }

    let result = value;
    if (value.length > 3)  {
        result = value.substr(0, 4);
    }
    if (value.length > 5) {
        result += '/' + value.substr(4, 2);
    }
    if (value.length > 7) {
        result += '/' + value.substr(6, 2);
    }
    if (value.length > 11) {
        result += ' ' + value.substr(8, 2) + ':' + value.substr(10, 2);
    }
    if (value.length > 13) {
        result += ':' + value.substr(12, 2);
    }
    return result;
}

module.exports.getEmojiChars = (string) => {
    return string.match(/\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]/g)
}

module.exports.getSpecialChars = (string) => {
    return string.match(/[!@#$%^&*(),.?":{}|<>]/g)
}

module.exports.isValidName = (string) => {
    const emoji = this.getEmojiChars(string);
    const special = this.getSpecialChars(string);
    return !emoji && !special;
}