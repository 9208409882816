import React from "react";
import BaseComponent from "components/common/BaseComponent";
import { connect } from "react-redux";
import * as actions from "actions/Actions";
import CustomCheckbox from "components/common/CustomCheckbox";
import _ from "lodash";

class Group extends BaseComponent {
  onSelectManage = e => {
    const { checked } = e.target;
    const permision = _.cloneDeep(this.props.groupRes);
    permision.isManage = checked;
    this.props.setGroupRes(permision);
    // this.props.setJsonData(DB_RESOURCE_TYPE.TOPIC, false, permision);
  };

  render() {
    const { groupRes } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <span className="pr-2">
            <CustomCheckbox
              checked={groupRes.isManage}
              onChange={this.onSelectManage}
            />
          </span>
          管理
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    groupRes: state.dbCommonRes.group
  };
};
const mapDispatchToProbs = dispatch => {
  return {
    setGroupRes: group => {
      dispatch(actions.setGroupRes(group));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(Group);
