import React from "react";
import * as CognitoConstants from "constants/CognitoConstants";
import SettingGeneral from "components/content/setting/SettingGeneral";
import UserAdd from "components/content/user/UserAdd";
import UserDetail from "components/content/user/UserDetail";
import ChangePassword from "components/content/ChangePassword";
import GroupDetail from "components/content/group/GroupDetail";
import GroupAdd from "components/content/group/GroupAdd";
import PolicyAdd from "components/content/policy/PolicyAdd";
import PolicyDetail from "components/content/policy/PolicyDetail";

export const detail_routes = [
  // setting
  {
    path: "/setting/general",
    exact: false,
    main: ({ match, history }) => (
      <SettingGeneral match={match} history={history} />
    )
  },

  // user
  {
    path: "/user/add",
    exact: true,
    main: ({ match, history }) => <UserAdd match={match} history={history} />,
  },
  {
    path: "/user/detail",
    exact: false,
    main: ({ match, history }) => (
      <UserDetail match={match} history={history} />
    ),
  },

  // change password
  {
    path: "/password",
    exact: false,
    main: ({ match, history }) => (
      <ChangePassword match={match} history={history} />
    )
  },

  // group
  {
    path: "/group/detail", // access control in GroupList and GroupDetail
    exact: false,
    main: ({ match, history }) => (
      <GroupDetail match={match} history={history} />
    )
  },
  {
    path: "/group/add",
    exact: true,
    main: ({ match, history }) => <GroupAdd match={match} history={history} />
  },

  // policy
  {
    path: "/policy/add",
    exact: true,
    main: ({ match, history }) => <PolicyAdd match={match} history={history} />
  },
  {
    path: "/policy/detail",
    exact: false,
    main: ({ match, history }) => <PolicyDetail match={match} history={history} />
  }
];
