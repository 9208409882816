import React from "react";
import BaseComponent from "components/common/BaseComponent";
import { connect } from "react-redux";
import EditAction from "components/common/EditAction";
import * as actions from "actions/Actions";
import * as DateFormat from "lib/DateFormat";
import * as LocalStorage from "lib/LocalStorageUtils";
import * as CognitoConstants from "constants/CognitoConstants";
import * as ApiConstants from "constants/ApiConstants";
import { MODAL_TYPE } from "constants/CommonConstants";
import { hist } from "App";
import {
  USER_DETAIL_PATH,
  USER_ADD_PATH,
  GROUP_DETAIL_PATH,
  POLICY_DETAIL_PATH
} from "constants/RoutePath";
import UserEditGroup from "components/content/user/UserEditGroup";
import UserEditPolicy from "components/content/user/UserEditPolicy";
import * as MsgConstants from "constants/MessageConstants";
import {
  LIST_USERS,
  LOGIN_USER,
  LISTVIEW_TYPE,
  LIST_POLICY
} from "constants/LocalStorage";
import * as CognitoUserUtils from "lib/CognitoUserUtils";
import * as StringFormat from "lib/StringFormat";
import validator from "validator";
import { conversion } from "lib/LocalStorageUtils";
import * as ListViewTypes from "constants/ListViewTypes";
import * as LocalStorageUtils from "lib/LocalStorageUtils";

const INFO_MODE = "info";
const EDIT_MODE = "edit";

class UserDetail extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      mode: INFO_MODE,
      firstNameEdit: "",
      familyNameEdit: "",
      isMe: false
    };
  }

  componentDidUpdate(previousProps) {
    // browser back/forward
    window.onpopstate = async e => {
      if (window.location.pathname !== USER_ADD_PATH) {
        const userId = this.getSelectedUser();
        if (this.mounted && userId !== "undefined") {
          const userInfo = await this.getUserInfo(userId);
          if (userInfo) {
            this.props.selectUser(userInfo);
            this.setState({
              familyNameEdit: LocalStorage.getAttribute(
                userInfo,
                "family_name"
              ),
              firstNameEdit: LocalStorage.getAttribute(userInfo, "name"),
              isMe:
                JSON.parse(localStorage.getItem(LOGIN_USER)).email ===
                LocalStorage.getAttribute(userInfo, "email")
            });
          }
        }
      } else {
        this.props.selectUser({});
      }
    };

    // on click user
    if (
      this.props.selectedUser.id &&
      this.props.selectedUser.id !== previousProps.selectedUser.id
    ) {
      this.setState({
        mode: INFO_MODE,
        isMe:
          JSON.parse(localStorage.getItem(LOGIN_USER)).email ===
          LocalStorage.getAttribute(this.props.selectedUser, "email")
      });
    }
  }

  componentDidMount = async () => {
    this.mounted = true;
    const userId = this.getSelectedUser();
    if (userId !== "undefined") {
      const userInfo = await this.getUserInfo(userId);
      if (userInfo) {
        this.props.selectUser(userInfo);
        this.setState({
          familyNameEdit: LocalStorage.getAttribute(userInfo, "family_name"),
          firstNameEdit: LocalStorage.getAttribute(userInfo, "name"),
          isMe:
            JSON.parse(localStorage.getItem(LOGIN_USER)).email ===
            LocalStorage.getAttribute(userInfo, "email")
        });
      }
    }
  };

  componentWillUnmount() {
    this.mounted = false;
  }

  getSelectedUser = () => {
    if (this.mounted) {
      const splitPath = window.location.pathname.split("/");
      if (splitPath[4] === EDIT_MODE) {
        this.setState({
          mode: EDIT_MODE
        });
      } else
        this.setState({
          mode: INFO_MODE
        });
      this.props.setUserRoute(window.location.pathname);
      return decodeURI(splitPath[3]);
    }
  };

  getUserInfo = async userId => {
    if (this.mounted) {
      const userInfo = LocalStorage.getTargetUser(userId);
      if (userInfo) {
        const requestParam = {
          user_ids: userId
        };
        const response = await this.executeApi(
          ApiConstants.GET_USER,
          null,
          null,
          requestParam
        );
        userInfo.policy_list = response.success
          ? response.data.user_list[0].policy_list
          : [];
        userInfo.group_list = response.success ? response.data.user_list[0].group_list : [];
        this.props.setUserGroup(userInfo.group_list);
        this.props.setUserPolicy(userInfo.policy_list);
      }
      this.setState({
        isMe:
          userInfo &&
          JSON.parse(localStorage.getItem(LOGIN_USER)).email ===
            LocalStorage.getAttribute(userInfo, "email")
      });
      return userInfo;
    }
  };

  onClickInfo = userId => {
    const mode = this.state.mode;
    if (mode !== INFO_MODE) {
      this.setState({
        mode: INFO_MODE
      });
      const route = `${USER_DETAIL_PATH}/${userId}/info`;
      hist.push(decodeURI(route));
      this.props.setUserRoute(decodeURI(route));
    }
  };

  onClickEdit = () => {
    const mode = this.state.mode;
    const { selectedUser } = this.props;
    if (mode !== EDIT_MODE) {
      this.setState({
        mode: EDIT_MODE,
        familyNameEdit: LocalStorage.getAttribute(selectedUser, "family_name"),
        firstNameEdit: LocalStorage.getAttribute(selectedUser, "name")
      });
      const route = window.location.pathname.replace(INFO_MODE, EDIT_MODE);
      this.props.setUserRoute(decodeURI(route));
      hist.push(decodeURI(route));
    }
  };

  getGroupPolicy = policyList => {
    return policyList.map((policy, index) => (
      <span
        className="pointer"
        onClick={this.goToPolicy.bind(this, policy.policy_id)}
      >
        {index === policyList.length - 1
          ? policy.policy_name
          : policy.policy_name + ", "}
      </span>
    ));
  };

  openModal = params => {
    this.props.openModal(params[0], params[1]);
  };

  onCancel = () => {
    const { selectedUser } = this.props;
    if (this.refs.familyNameRef)
      this.refs.familyNameRef.value = LocalStorage.getAttribute(
        selectedUser,
        "family_name"
      );
    if (this.refs.firstNameRef)
      this.refs.firstNameRef.value = LocalStorage.getAttribute(
        selectedUser,
        "name"
      );
    this.props.editGroup("cancelEdit");
  };

  onSave = () => {
    const { firstNameEdit, familyNameEdit, isMe } = this.state;
    const { selectedUser, userEditGroup, userEditPolicy } = this.props;
    const origGroups = [];
    const editGroups = [];
    const origPolices = [];
    const editPolices = [];
    const delPolicy = [];

    selectedUser.group_list.forEach(group => {
      origGroups.push(group.group_id);
    });
    userEditGroup.forEach(group => {
      editGroups.push(group.group_id);
    });
    selectedUser.policy_list.forEach(policy => {
      origPolices.push(policy.policy_id);
    });
    userEditPolicy.forEach(policy => {
      const policyList = JSON.parse(localStorage.getItem(LIST_POLICY));
      if (policyList.filter(e => e.policy_id === policy.policy_id).length) {
        editPolices.push(policy.policy_id);
      } else delPolicy.push(policy.policy_name);
    });

    const eEmail = LocalStorage.getAttribute(selectedUser, "email");
    const origFamilyName = LocalStorage.getAttribute(
      selectedUser,
      "family_name"
    );
    const origFirstName = LocalStorage.getAttribute(selectedUser, "name");
    const type = LocalStorage.getAttribute(
      selectedUser,
      CognitoConstants.USER_STATUS
    );
    const editType = "変更";
    let alertParam;

    // Check policy exist
    if (delPolicy.length) {
      this.showDialog({
        message: StringFormat.format(MsgConstants.POLICY_NOT_EXIST, [delPolicy])
      });
      return;
    }

    // check input no change
    if (
      familyNameEdit.trim() === origFamilyName &&
      firstNameEdit.trim() === origFirstName &&
      JSON.stringify(editPolices) === JSON.stringify(origPolices) &&
      JSON.stringify(editGroups) === JSON.stringify(origGroups)
    ) {
      this.showDialog({ message: MsgConstants.INPUT_NO_CHANGE });
      return;
    }

    if (
      validator.isEmpty(familyNameEdit.trim()) ||
      validator.isEmpty(firstNameEdit.trim())
    ) {
      alertParam = {
        message: MsgConstants.USER_EDIT_REQUIRED_ERROR
      };
    } else {
      const callback = async (err, data) => {
        if (err) {
          this.showDialog({
            message:
              err.name === CognitoConstants.USER_NOT_FOUND_EXCEPTION
                ? MsgConstants.USER_NOT_FOUND_ERROR
                : StringFormat.format(MsgConstants.USER_EDIT_ERROR, [editType])
          });
        } else {
          const requestParam = {
            user_id: selectedUser.id,
            valid_flag: true,
            group_list: editGroups,
            policy_list: editPolices
          };
          const response = await this.executeApi(
            ApiConstants.POST_USER,
            null,
            null,
            requestParam
          );
          if (response.success) {
            this.showToast(
              StringFormat.format(MsgConstants.EDIT_SUCCESS, ["ユーザー"])
            );
            // update user list, selection user and go to info mode
            await this.getUsers().then(users => {
              const converted = conversion(users);
              localStorage.setItem(LIST_USERS, JSON.stringify(converted));
            });
            const updateInfo = await this.getUserInfo(selectedUser.id);
            this.props.selectUser(updateInfo);
            this.onClickInfo(selectedUser.id);
            this.props.editGroup("editUser");
          }
        }
      };
      alertParam = {
        message: StringFormat.format(MsgConstants.USER_EDIT_CONFIRM, [
          origFamilyName + " " + origFirstName,
          editType
        ]),
        confirm: true,
        positive: async () => {
          CognitoUserUtils.update(
            familyNameEdit.trim(),
            firstNameEdit.trim(),
            type,
            eEmail,
            callback
          );
          if (isMe) {
            // update localstorage
            const myself = JSON.parse(localStorage.getItem(LOGIN_USER));
            myself.name = familyNameEdit + " " + firstNameEdit;
            localStorage.setItem(LOGIN_USER, JSON.stringify(myself));
          }
        }
      };
    }
    this.showDialog(alertParam);
  };

  onDelete = () => {
    const { selectedUser } = this.props;
    const editType = "削除";
    const eEmail = LocalStorage.getAttribute(selectedUser, "email");
    const familyName = LocalStorage.getAttribute(selectedUser, "family_name");
    const firstName = LocalStorage.getAttribute(selectedUser, "name");

    const callback = async (err, result) => {
      if (err) {
        this.showDialog({
          message:
            err.name === CognitoConstants.USER_NOT_FOUND_EXCEPTION
              ? MsgConstants.USER_NOT_FOUND_ERROR
              : StringFormat.format(MsgConstants.USER_EDIT_ERROR, [editType])
        });
      } else {
        const requestParam = {
          user_id: selectedUser.id,
          valid_flag: false
        };
        const response = await this.executeApi(
          ApiConstants.POST_USER,
          null,
          null,
          requestParam
        );
        if (response.success) {
          this.showToast(
            StringFormat.format(MsgConstants.USER_DELETE_SUCCESS, ["ユーザー"])
          );
          this.props.selectUser({});
          await this.getUsers().then(users => {
            const converted = conversion(users);
            localStorage.setItem(LIST_USERS, JSON.stringify(converted));
          });
          this.props.history.push(USER_DETAIL_PATH);
          this.props.editGroup("deleteUser");
        }
      }
    };
    this.showDialog({
      message: StringFormat.format(MsgConstants.USER_EDIT_CONFIRM, [
        familyName + " " + firstName,
        editType
      ]),
      confirm: true,
      positive: () => CognitoUserUtils.remove(eEmail, callback)
    });
  };

  enable = () => {
    const { selectedUser } = this.props;
    const eEmail = LocalStorage.getAttribute(selectedUser, "email");
    const familyName = LocalStorage.getAttribute(selectedUser, "family_name");
    const firstName = LocalStorage.getAttribute(selectedUser, "name");
    const callback = async (err, data) => {
      if (err) {
        this.showDialog({
          message:
            err.name === CognitoConstants.USER_NOT_FOUND_EXCEPTION
              ? MsgConstants.USER_NOT_FOUND_ERROR
              : MsgConstants.ONIGIRI_DB_ENABLE_ERROR
        });
      } else {
        this.showToast(MsgConstants.USER_ENABLE_SUCCESS);
        await this.getUsers().then(users => {
          const converted = conversion(users);
          localStorage.setItem(LIST_USERS, JSON.stringify(converted));
        });
        const updateInfo = await this.getUserInfo(selectedUser.id);
        this.props.selectUser(updateInfo);
        this.onClickInfo(selectedUser.id);
        this.props.editGroup("enableUser");
      }
    };
    this.showDialog({
      message: StringFormat.format(
        MsgConstants.ONIGIRI_DB_ENABLE_CONFIRM, [familyName + " " + firstName]),
      confirm: true,
      positive: () =>
        CognitoUserUtils.update(
          familyName,
          firstName,
          CognitoConstants.ADMIN_USER_CODE,
          eEmail,
          callback
        )
    });
  };

  disable = () => {
    const { selectedUser } = this.props;
    const eEmail = LocalStorage.getAttribute(selectedUser, "email");
    const familyName = LocalStorage.getAttribute(selectedUser, "family_name");
    const firstName = LocalStorage.getAttribute(selectedUser, "name");
    const callback = async (err, data) => {
      if (err) {
        this.showDialog({
          message:
            err.name === CognitoConstants.USER_NOT_FOUND_EXCEPTION
              ? MsgConstants.USER_NOT_FOUND_ERROR
              : MsgConstants.ONIGIRI_DB_DISABLE_ERROR
        });
      } else {
        this.showToast(MsgConstants.USER_DISABLE_SUCCESS);
        await this.getUsers().then(users => {
          const converted = conversion(users);
          localStorage.setItem(LIST_USERS, JSON.stringify(converted));
        });
        const updateInfo = await this.getUserInfo(selectedUser.id);
        this.props.selectUser(updateInfo);
        this.onClickInfo(selectedUser.id);
        this.props.editGroup("disableUser");
      }
    };
    this.showDialog({
      message: StringFormat.format(MsgConstants.ONIGIRI_DB_DISABLE_CONFIRM, [
        familyName + " " + firstName
      ]),
      confirm: true,
      positive: () =>
        CognitoUserUtils.update(
          familyName,
          firstName,
          CognitoConstants.DISABLE_USER_CODE,
          eEmail,
          callback
        )
    });
  };

  resetPassword = () => {
    const { selectedUser } = this.props;
    const eEmail = LocalStorage.getAttribute(selectedUser, "email");
    const familyName = LocalStorage.getAttribute(selectedUser, "family_name");
    const firstName = LocalStorage.getAttribute(selectedUser, "name");
    const callback = err => {
      if (err) {
        this.showDialog({
          message:
            err.name === CognitoConstants.USER_NOT_FOUND_EXCEPTION
              ? MsgConstants.USER_NOT_FOUND_ERROR
              : StringFormat.format(MsgConstants.USER_EDIT_ERROR, [
                  "パスワードの初期化"
                ])
        });
      } else {
        this.showToast(MsgConstants.PASSWORD_RESET_SUCCESS);
      }
    };
    this.showDialog({
      message: StringFormat.format(MsgConstants.USER_RESET_PASSWORD_CONFIRM, [
        familyName + " " + firstName
      ]),
      confirm: true,
      positive: () => CognitoUserUtils.resetPassword(eEmail, callback)
    });
  };

  onFamilyNameChange = e => {
    const { value } = e.target;
    this.setState({
      familyNameEdit: value
    });
  };

  onFirstNameChange = e => {
    const { value } = e.target;
    this.setState({
      firstNameEdit: value
    });
  };

  goToGroup = groupName => {
    const route = `${GROUP_DETAIL_PATH}/${groupName}/info`;
    this.props.setGroupRoute(decodeURI(route));
    this.props.showGroup();
    hist.push(decodeURI(route));
    localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.GROUP);
  };

  goToPolicy = policyId => {
    const route = `${POLICY_DETAIL_PATH}/${policyId}/info`;
    this.props.setPolicyRoute(decodeURI(route));
    this.props.showPolicy();
    this.props.editGroup("goToPolicy");
    hist.push(decodeURI(route));
    localStorage.setItem(LISTVIEW_TYPE, ListViewTypes.POLICY);
  };

  getServiceStatus = user => {
    const getStatus = code => {
      return code.length ? code === CognitoConstants.DISABLE_USER_CODE ? "停止中" : "利用中" : "未利用"
    }
    return [
      {
        service: "DB",
        status: getStatus(LocalStorageUtils.getAttribute(user, CognitoConstants.USER_STATUS)),
      },
      {
        service: "RM",
        status: getStatus(LocalStorageUtils.getAttribute(user, CognitoConstants.USER_STATUS_RM)),
      }
    ];
  }

  render() {
    const { mode, isMe } = this.state;
    const { selectedUser } = this.props;
    if (!selectedUser.id) return null;
    const { group_list, policy_list } = selectedUser;
    const type = LocalStorage.getAttribute(
      selectedUser,
      CognitoConstants.USER_STATUS
    );
    return (
      <div className="container-fluid m-0 p-0 w-100">
        <div
          id="headMenu"
          className="row position-static flex-nowrap m-0 p-2 text-white "
        >
          <div
            id="tabMenu"
            className="mr-2"
          >
            <ul className="nav d-flex flex-nowrap">
              <li
                className="nav-item detail-header"
                onClick={this.onClickInfo.bind(this, selectedUser.id)}
              >
                <a
                  className={
                    mode === INFO_MODE ? "nav-link active" : "nav-link"
                  }
                  href="#definition-dsp"
                  data-target="#definition-dsp"
                  data-toggle="tab"
                  id="sample-btn-dsp"
                >
                  通常
                </a>
              </li>
              <li className="nav-item detail-header" onClick={this.onClickEdit}>
                <a
                  className={
                    mode === EDIT_MODE ? "nav-link active" : "nav-link"
                  }
                  href="#definition-edt"
                  data-target="#definition-edt"
                  data-toggle="tab"
                  id="sample-btn-edt"
                >
                  編集
                </a>
              </li>
            </ul>
          </div>
          {!isMe && (
            <div
              className="ml-auto"
              style={{display: mode === EDIT_MODE ? "block" : "none"}}
            >
              <button
                type="button"
                className="btn btn-warning rounded-1"
                onClick={this.onDelete}
              >
                ユーザーの削除
              </button>
            </div>
          )}
        </div>
        <div
          id="detail-margin"
          className={this.setDetailStyle(this.props.isLvExpand)}
        >
          <div className="d-flex mb-3 pr-3">
            <div className="d-flex">
              <span className="align-self-center adm-detail-main-title">
                {mode === EDIT_MODE ? "ユーザー編集" : "ユーザー情報"}
              </span>
            </div>
          </div>
          <div className="custom-border-bottom">
            <div className="row no-gutters">
              <div className="col-2 custom-border-bottom">
                <span className="key">メールアドレス</span>
              </div>
              <div className="col pl-2 custom-border-bottom">
                <span className="value">
                  {LocalStorage.getAttribute(selectedUser, "email")}
                </span>
              </div>
            </div>
            <div className="row no-gutters">
              <div
                className={
                  mode === INFO_MODE ? "col-2 custom-border-bottom" : "col-2"
                }
              >
                <span className="key">氏名</span>
              </div>
              {mode === INFO_MODE ? (
                <div className="col pl-2 custom-border-bottom">
                  <span className="value text-wrap break-text">
                    {`${LocalStorage.getAttribute(
                      selectedUser,
                      "family_name"
                    )} ${LocalStorage.getAttribute(selectedUser, "name")}`}
                  </span>
                </div>
              ) : (
                <div className="col pl-2 no-gutters d-flex flex-inline">
                  <div className="col-4 mr-3">
                    <input
                      className="custom-input"
                      type="text"
                      ref="familyNameRef"
                      onChange={this.onFamilyNameChange}
                      defaultValue={LocalStorage.getAttribute(
                        selectedUser,
                        "family_name"
                      )}
                    />
                  </div>
                  <div className="col">
                    <input
                      className="custom-input"
                      type="text"
                      ref="firstNameRef"
                      onChange={this.onFirstNameChange}
                      defaultValue={LocalStorage.getAttribute(
                        selectedUser,
                        "name"
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
            {mode === INFO_MODE && (
              <div className="row no-gutters">
                <div className="col-2 custom-border-bottom">
                  <span className="key">作成日時</span>
                </div>
                <div className="col pl-2 custom-border-bottom">
                  <span className="value">
                    {DateFormat.toString(
                      new Date(selectedUser.UserCreateDate),
                      "yyyy/MM/dd hh:mm:ss"
                    )}
                  </span>
                </div>
              </div>
            )}
            {mode === INFO_MODE && (
              <div className="row no-gutters">
                <div className="col-2 custom-border-bottom">
                  <span className="key">最終ログイン日時</span>
                </div>
                <div className="col pl-2 custom-border-bottom">
                  <span className="value">
                    {selectedUser.date
                      ? DateFormat.toString(
                          new Date(selectedUser.date),
                          "yyyy/MM/dd hh:mm:ss"
                        )
                      : "-"}
                  </span>
                </div>
              </div>
            )}
            {mode === INFO_MODE && (
              <div className="pb-3">
                <div className="row no-gutters">
                  <div className="key col-2 detail-group-sub-title">サービスの利用状況</div>
                  <div className="col">
                    <div className="mdl-list-user-header">
                      <div className="row mdl-grid mdl-list-user custom-border-bottom">
                        <span className="value pl-2">サービス</span>
                        <span className="value">ステータス</span>
                      </div>
                    </div>
                    <div className="col px-0">
                    <div className="mdl-list-user-header">
                      {this.getServiceStatus(selectedUser).map((status, index) => (
                        <div key={index} className="row mdl-grid mdl-list-user custom-border-bottom">
                          <span className="key font-weight-normal pl-2">{status.service}</span>
                          <span className="key font-weight-normal">{status.status}</span>
                        </div>
                      ))}
                    </div>
                  </div></div>
                </div>
              </div>
            )}
          </div>
          <div className="pb-3">
            <div className="row no-gutters">
              <div className="key col-2 detail-group-sub-title">
                所属するグループ
              </div>
              <div className="col">
                <div className="mdl-list-user-header">
                  {mode === EDIT_MODE ? (
                    <div
                      className="btn btn-positive rounded-1 ml-2 mt-1"
                      onClick={this.openModal.bind(this, [
                        MODAL_TYPE.GROUP,
                        group_list
                      ])}
                    >
                      グループを選択
                    </div>
                  ) : (
                    <div className="mdl-grid mdl-list-user">
                      <span className="value pl-2">グループ名</span>
                      <span className="value">アタッチ済みのポリシー</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {mode === EDIT_MODE && (
              <div className="row no-gutters">
                <div className="col-2"></div>
                <div className="col">
                  <div className="mdl-list-user-header">
                    <div className="mdl-grid mdl-list-user">
                      <span className="value pl-2 ">グループ名</span>
                      <span className="value">アタッチ済みのポリシー</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {mode === INFO_MODE ? (
              <div className="pl-1 mdl-list-outer-group-user">
                {group_list.map((group, index) => (
                  <div className="row no-gutters" key={index}>
                    <div className="col-2" />
                    <div className="col">
                      <div className="mdl-grid mdl-list-user">
                        <span
                          className="key text-primary text-wrap pointer break-text px-2"
                          onClick={this.goToGroup.bind(this, group.group_name)}
                        >
                          {group.group_name}
                        </span>

                        {group.policy_list.length ? (
                          <span className="value text-primary text-wrap break-text">
                            {this.getGroupPolicy(group.policy_list)}
                          </span>
                        ) : (
                          <span className="value">なし</span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <UserEditGroup initList={group_list} />
            )}
          </div>
          <div className="row no-gutters">
            <div className="col custom-border-bottom"></div>
          </div>
          <div>
            <div className="row no-gutters">
              <div className="key col-2 detail-group-sub-title">
                アタッチ済みのポリシー
              </div>
              <div className="col">
                {mode === EDIT_MODE ? (
                  <div
                    className="btn btn-positive rounded-1 ml-2 mt-1"
                    onClick={this.openModal.bind(this, [
                      MODAL_TYPE.POLICY_USER,
                      policy_list
                    ])}
                  >
                    ポリシーを選択
                  </div>
                ) : (
                  <div className="mdl-list-user-header">
                    <div className="mdl-grid mdl-list-user">
                      <span className="value pl-2">ポリシー名</span>
                      <span className="value">説明</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {mode === EDIT_MODE && (
              <div className="row no-gutters">
                <div className="col-2"></div>
                <div className="col">
                  <div className="mdl-list-user-header">
                    <div className="mdl-grid mdl-list-user">
                      <span className="value pl-2">ポリシー名</span>
                      <span className="value">説明</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {mode === INFO_MODE ? (
            <div className="pl-1 mdl-list-outer-group-user">
              {policy_list.map((policy, index) => (
                <div className="row no-gutters" key={index}>
                  <div className="col-2" />
                  <div className="col">
                    <div className="mdl-grid mdl-list-user">
                      <span
                        className="key text-primary text-wrap pointer break-text pl-2"
                        onClick={this.goToPolicy.bind(this, policy.policy_id)}
                      >
                        {policy.policy_name}
                      </span>
                      <span className="value text-wrap">
                        {policy.description}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <UserEditPolicy initList={policy_list} />
          )}
          {mode === EDIT_MODE && (
            <div className="pb-3">
              <div
                className="py-3"
                style={{display: mode === EDIT_MODE ? "block" : "none"}}
              >
                {type !== CognitoConstants.DISABLE_USER_CODE && (
                  <EditAction
                    onCancelHandler={this.onCancel}
                    onSaveHandler={this.onSave}
                  />
                )}
              </div>
              <div className="d-flex align-items-center">
                <button
                  type="button"
                  className="btn mr-2 btn-action rounded-1"
                  onClick={this.resetPassword}
                >
                  パスワードの初期化
                </button>
                {type !== CognitoConstants.DISABLE_USER_CODE && !isMe && (
                  <button
                    type="button"
                    className="btn btn-action rounded-1"
                    onClick={this.disable}
                  >
                    ONIGIRI-DBの利用停止
                  </button>
                )}
                {type === CognitoConstants.DISABLE_USER_CODE && !isMe && (
                  <button
                    type="button"
                    className="btn btn-action rounded-1"
                    onClick={this.enable}
                  >
                    ONIGIRI-DBの利用再開
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLvExpand: state.lvExpandState,
    userList: state.userList,
    selectedUser: state.selectedUser,
    userEditGroup: state.userEditGroup,
    userEditPolicy: state.userEditPolicy
  };
};

const mapDispatchToProbs = dispatch => {
  return {
    setUserRoute: route => {
      dispatch(actions.setUserRoute(route));
    },
    setUserList: list => {
      dispatch(actions.setUserList(list));
    },
    selectUser: user => {
      dispatch(actions.selectUser(user));
    },
    setUserGroup: group => {
      dispatch(actions.setUserGroup(group));
    },
    setUserPolicy: policy => {
      dispatch(actions.setUserPolicy(policy));
    },
    openModal: (modal, data) => {
      dispatch(actions.openModal(modal, data));
    },
    editGroup: action => {
      dispatch(actions.editGroup(action));
    },
    setGroupRoute: route => {
      dispatch(actions.setGroupRoute(route));
    },
    selectGroup: group => {
      dispatch(actions.selectGroup(group));
    },
    setPolicyRoute: route => {
      dispatch(actions.setPolicyRoute(route));
    },
    showPolicy: () => {
      dispatch(actions.showPolicy());
    },
    showGroup: () => {
      dispatch(actions.showGroup());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProbs)(UserDetail);
